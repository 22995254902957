/**** Responsive ****/
/* Min width */
@media (min-width: 1480px){ 

}        
@media (min-width: 1200px){        
.mobile_hmenu_list{ display: none;}/* Mobile cms menu */
.hmenu_list > li:hover > a > .hdd_arrow{ opacity: 1; visibility: visible;}
}
@media (min-width: 679px){
.cur-ord-rec-mobile{ display: none;}
}   
@media (min-width: 576px){   
.modal-dialog { margin: 1.75rem auto; }
.modal-dialog-centered { min-height: calc(100% - (1.75rem * 2)); min-height: -webkit-calc(100% - (1.75rem * 2));}
}
@media(max-width: 1330px) and (min-width: 991px){
.promation_btn .btn {min-width: auto; width: 48%; float: left; margin: 1%; font-size: 16px; padding: 12px 10px;}
}
/* Max width */
@media (max-width: 1350px){
.container{padding-left: 15px; padding-right: 15px;}
}
@media (max-width: 1310px){
.hmenu_list > li > a{padding: 33px 5px 33px 5px;font-size: 14px;}
}
@media (max-width: 1300px){
.redeem-item-hea-inner{width: 100%;}
.redeem-item-hea .points{position: static; margin: 10px 0; max-width: 100%; padding: 8px 10px;}
.featured-products-main .slick-arrow{display: none !important;}        
.featured-products-full .slick-dots{ margin: 0; padding: 40px 0 0 0; list-style: none; display: block !important}   
.featured-products-full .slick-dots li{display: inline-block}    
.featured-products-full .slick-dots li button{ padding: 0; margin: 0 4px; width: 10px; height: 10px; border-radius: 50%; overflow: hidden; text-indent: -9999px;  }    
.featured-products-full .slick-dots li.slick-active button{ background: #e87b1e }
.featured-products-full{ width: 90%}
.featured-products-single{ max-width: inherit;}    
.featured-products-full .slick-track .slick-slide > div{ padding: 5px }    
.homebanner-content{padding: 0 20px}    
}
@media (max-width: 1240px){
.megaimg_sec.text-right{text-align: center;}
.megaimg_item{margin-bottom: 10px;}
.hme-prt-slidecontent{top: 0; left: 0; right: 0; bottom: 0;}
.header_in::before, .header_in::after{display: none}    
.hand-text { width: calc( 100% - 230px );}   
    .hand-text p{font-size: 16px;}    
    .grid-btn{max-width: 100%;}
    .grid-btn .button{padding: 14px 5px;}
}
@media (max-width: 1199px){
input{ -webkit-appearance: none; -moz-appearance: none; appearance: none;  border-radius:0;}
.sticky .alert_fixed{ top: 95px;}
.header_in{ padding: 0 }
.header_middle:before, header.small_sticky:before, .hordertype_sec{display: none !important}    
.header-bottom{ padding:5px 0; height: 70px;    display: block; }    
.header-bottom:after {  display: table;    clear: both;    content: "";}    
.hmenu_actions{float: right}
.header_bot{display: block; margin: 15px 0 0 0;}    
.logo a{position: relative; top: inherit}    
.common-top-div{ padding-top: 0 !important }    
.common-top-div.productpage-main-div {  padding-top: 0!important;}    
.product-view-more {  margin-top: 15px;}    
.home-ordernow-single-img img { max-height: 60px;}    
.home-ordernow-section{padding-top: 40px}    
.home-badge-icon{ top: -78px }
.home-badge-icon img { width:450px;}    
.hcart_round{top: 10px}
.tbars header{ height: 153px;}
.breadcrumb{ padding: 25px 0 0;}
.Products .breadcrumb{ padding: 69px 0 0 0;}
.Products .bread_space { margin: 69px 0 0; }
.hmenu_actions > li.hsign_sec{ display: none;}
.mobile_accountdel_body, .mobile-order-nowbtn{display: block;}
.htmenu_sec{display: none;}
.hmenu_sec ul{left: 0;}
.mega_menuin [class*='col-']{width: 100%;}
.logo{position: absolute;left: 0;right: 0;width: 100%;margin: auto;padding: 0;max-width: 230px;}
.haction_sec{float:left; width: 50%; text-align: left}
.hmenu_actions li.horder_btn{display: none;}
.hsearch_bx .re_select .Select-control:before{ right: 14px;}
.menu_icon{     display: block;float: left;width: 36px;height: 25px;margin: 3px 0px;padding: 0px 0px;cursor: pointer; }    
.menu_icon.active:after{ display: block; opacity: 1;}    
.menu_icon .icon-bar{ display: block; background-color: #000; height: 2px; -webkit-transition: all 400ms; transition: all 400ms;}
.menu_icon .icon-bar+.icon-bar { margin-top: 8px; }
.menu_icon.active .icon-bar1{top:8px; -webkit-transform: translate(0px, 9px) rotate(45deg) scale(1); transform: translate(0px, 9px) rotate(45deg) scale(1);}
.menu_icon.active .icon-bar2{top:17px ;-webkit-transform:translate(0px, 0px) rotate(0deg) scale(0)  ;transform:translate(0px, 0px) rotate(0deg) scale(0); }
.menu_icon.active .icon-bar3{top:26px ;-webkit-transform:translate(0px, -10px) rotate(-45deg) scale(1); transform: translate(0px, -10px) rotate(-45deg) scale(1);  }        
.desktop_hmenu_list{ display: none;}
.hmenu_list.open{ opacity: 1; visibility: visible; z-index: 11;}
.hmenu_list{ opacity: 0; visibility: hidden; position: absolute; right: 0px; top: 50px; height: calc(100vh - 70px); height: -webkit-calc(100vh - 70px);  background: #fff; max-width: 500px; width: 100%;overflow-x: hidden; overflow-y: auto; margin: 0;}    
.hmenu_list > li{ display: block;z-index: 3}
ul.hmenu_list > li > a{ padding: 12px 15px; text-align: left; border-bottom: 1px solid #e5e5e5; color: #333}
ul.hmenu_list > li:hover > a:after{ display: none;}
ul.hmenu_list > li:hover > a, ul.hmenu_list > li.active > a{ color: #e87b1e;}
i.hdd_arrow{ position: absolute; right: 0; top: 0; bottom: 0; height: 45px; width: 45px; line-height: 45px; font-size: 16px; text-align: center; background: #ecdfcc; color: #181818; margin: auto 0;}

.htico_search> a{ padding: 15.5px 0;}
.htico_cart > a{ padding: 15.5px 0;}
.hcategory_selected_text{ padding: 12px 180px 12px 15px; color: #000; font-size: 18px; text-transform: uppercase; 
display: block; position: relative;} 
.hcategory_trigger{ position: absolute; right: 15px; top: 12px;  -webkit-transition: all 400ms; transition: all 400ms;font-size: 18px; padding-right: 30px}
.hcategory_trigger:after{ position: absolute; background: url(../images/menu_ico.png) no-repeat; background-size: contain;width: 22px;
    height: 20px;
    content: "";
    right: 0;
    top: 2px;}    

.hcategory_trigger.active:after{ background: url(../images/close-block.png) no-repeat; background-size: contain;    width: 16px;
    height: 16px;
    top: 3px;
    right: 5px; }
.hcategory_menu{background: #fff; position: absolute; left: 0; top: 0; right: 0; max-height: 400px; opacity: 0;
visibility: hidden; -webkit-transition: all 400ms; transition: all 400ms; overflow: auto;}
.hcategory_menu .mCSB_scrollTools{ opacity: 1;}
.hcategory_menu .mCSB_scrollTools .mCSB_draggerRail{ background-color: #6e5546;}
.hcategory_menu .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #191919;}
.hcategory_menu.open{ opacity: 1; visibility: visible;}
.hcategory_menulist{ list-style: none; padding: 0; margin: 0;}
.hcategory_menulist > li > a{background: #f8f8f8; color: #000; font-size: 18px; padding: 12px 45px 12px 15px; display: block; text-transform: uppercase;}
.hcategory_menulist > li > a:hover{ color: #d71820;}
.hcategory_submenu{ list-style: none; padding: 0; margin: 0; }
.hcategory_submenu >li >a{ color: #000; font-size: 18px; padding: 12px 15px 12px 35px; display: block;  }
.hcategory_submenu >li >a:hover{ color: #d71820;}
.hsearch_sec.open{ top: 71px;}
.hsearch_trigger:before{ top: 36px; right: 12px;}
.hsearch_bx .re_select .Select-control{ height: 49px;}
.hsearch_bx .re_select .Select-placeholder{ line-height: 49px;}
.hsearch_bx .re_select .Select-input{ height: 49px;}
/* Sticky Header */
.bakery_row_fixed{ top: 162px;}
.tbars .bakery_row_fixed{ top: 202px;}
.banner{ margin-top: 25px;}
.banner .container{ padding: 0 15px;}
.banner-top-text{position: static;left: 0;right: 0;margin: 0 auto 50px auto;width: 100%; padding: 0 50px;}    
.banner-text-box{margin-right: 0;}
.banner-info h2, .featured-products-main h2, .serving-passionately-right h3{font-size: 40px; }
.banner-info p{font-size: 22px}
.home-ordernow-single h3{font-size: 22px;}
.home-ordernow-single-img .button { font-size: 14px; padding: 10px 12px 10px;}
.featured-products-full{ width: 100%}
.delivery_total.delivery_total_number .delivery_total_left h2{font-size: 15px;}
.delivery_total.delivery_total_number .delivery_total_left h4{font-size: 28px;}
.chk_hea{font-size: 16px;}
.checkout-total .cdd-details-lhs{padding: 0px 15px 20px 15px; width: 100%;margin-bottom: 10px;}
.checkout-heading{margin: 0 -15px}    
.cdd-details-lhs .checkout-heading:after{display: none}    
.check_pg_inner{padding: 20px 15px 10px 15px;}
.checkout-total .checkout-control-group-top{padding: 20px 0 20px 0;}
.checkout-total .cdd-details-rhs{padding: 0px 15px 20px 15px;width: 100%;}
.checkout-total .checkout-control-group-middle{padding: 10px 0 0px 0;}
.checkout-control-group-top [class*='col-md']{margin-bottom: 10px;}
.checkout-control-group-middle [class*='col-md']{margin-bottom: 10px;}
.checkout-total .checkout-control-group-top a{margin-top: 0;}
.order-detail-inner-icon{max-width: 100%;}
.order-detail-inner-icon-left.order-detail-inner-center::before{background-repeat: repeat;max-width: 100%;height: 6px;}
.order-detail-inner{padding-top: 27px;}
.main_tabsec_inner> .nav-tabs>li> a{text-align: left;}
.main_tabsec_inner, .rewards-tab-section .main_tabsec_inner, .promation-tab-section .main_tabsec_inner{padding: 20px 15px;}
.title_sec h2{font-size: 34px;}
.filter_tabsec .nav-tabs>li>a{font-size: 17px;}
.hme-product-inner .filter_tabsec .nav-tabs{margin-bottom: 20px;}
.banner-left{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.banner-text-box{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;width: 100%;}
.banner-right{margin-top: 0;}
.banner-right-content{text-align: left;}
.htopinfo{text-align: center;width: 100%;}
.delivery-item h2{font-size: 32px;}
.delivery-item span{font-size: 17px;}
.hme-product-inner{padding: 20px 15px;}
.outlet-list li{ padding-left: 0;}
.footer-inner [class*='col-']{width: 33.33%;}
.footer-inner .news-letter{width: 100%;margin-top: 10px;}
.cart_footer_totrow p{font-size: 20px;}
.cart_footer_totrow span{font-size: 20px;}
.cart_footer p{font-size: 14px;}
.cart_footer{padding-bottom: 10px;}
.delivery_info h4{font-size: 14px;}
.delivery_info p{font-size: 14px;}
.cart_action .btn_grey{font-size: 14px;}
.tnk-you{padding: 70px 0;}
.redeem-col{width: 100%;margin: 0 0 10px 0;padding: 20px 10px;}
.redeem-item-hea{padding-left: 0;}
.redeem-item-hea h4{font-size: 23px;}
.tnk-order h3{font-size: 28px;}
.tnk-order p{ font-size: 14px;}
.tick img{width: 100px;}
.tick h2{font-size: 34px;margin-top: 10px;}
.tick span{font-size: 16px;}
.delivery-inner{padding: 40px 0;position: relative;}
.delivery-inner-comman{position: relative;z-index: 2;}
.delivery-inner::after{content: "";background: rgba(255, 255, 255, 0.6);top: 0;left: 0;bottom: 0;right: 0;position: absolute;z-index: 1;}
.bts .form-group {min-width: auto;width: calc(100% - 200px) !important;}
/*promotion pages*/
.promo-earned-content{padding: 10px 10px; }
.promo-earned-content h2, .promo-earned-content span{ font-size: 30px; }
.promo-earned-content h2 span sup {font-size: 20px; }
.abc_code span{ font-size: 17px; }
.abc_code{ padding: 10px 0; }
.redeem_popsec .promo-earned-content h2 span sup {font-size: 18px; }
.redeem_popsec .promo-earned-content h4{ font-size: 24px; }
.redeem_popsec .promo-earned-content p{ margin: 0; }
.redeem_popsec .abc_code {padding: 6px 0 0px; }
.redeem_popsec .promo-earned-content{ padding: 20px 10px 20px; }
.redeem_popsec .promo-earned-content h2{ font-size: 30px;}
/* Account information page */

.setting_menu_list{ display: block;}
.myacc_dropmenu{ background: #fff; color: #000; }
.myacc_dropmenu::after{ border-right: 14px solid #fff; }
.myacc_dropmenu h3 {
    color: #000;
}
.tgl-light + .tgl-btn{ background: #1b1b1b; }
.setting_menu_list ul.myacc_dropmenu li a:hover {
    color: #000 !important;
}

.catogry_row_fixed .bakery_slider{ top: 118px }        
.pkg_ginfo p{ max-height: 75px; overflow: hidden}    
.header-bottom .logo{display: none}    
.mobile-logo-div{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;text-align: center; position: absolute;left: 0;right: 0; max-width: 210px; margin: auto;top: 5px;bottom: 5px; -webkit-box-align: center; -ms-flex-align: center; align-items: center}       
.mobile-logo-div a{display: block;width: 100%;}    
.mobile-logo-div a img{ width: 100% }    
.bakery .bakery_row{display: none}  



.hcategory_sec {
  display: block
}

.menu-section_full {
    margin: 38px 0 0 0;
}
.hmenu_actions {
    padding: 0 0 0 0;
}   
.mobile_accountdel_body, .mobile-order-nowbtn{ box-shadow: none; }
    .hmenu_actions{z-index: 1;}    
}
@media (max-width: 1180px){
.pkselected_package .buffet-label {padding: 15.5px 15px 15.5px 60px;width: 130px;}
.pkselected_package .form-group {/*width: calc(100% - 135px);*/ min-width: auto;}
.pkselected_filtersec .time-label {width: 171px;}
.pkselected_break .form-group {min-width: auto; /*width: calc(100% - 175px);*/}
.row-pgk-list > li, .pkg_gcol { width: 33.333333333333%;}    
}
@media (max-width: 1100px){
.order_delivery_mobile_tooltip, .selfcollect_tooltip .order_delivery_mobile_tooltip{top: -119px; left:-13px; right:-13px; height: 110px; opacity:1; visibility:visible; pointer-events:auto;}
.order_delivery_mobile_tooltip:before, .selfcollect_tooltip .order_delivery_mobile_tooltip:before{ top: auto; right: auto; left: 50%; bottom: -16px; border-color: #181818 transparent transparent transparent; border-width: 10px 7px 10px 7px; margin: 0 0 0 -7px;}
.order_delivery_mobile_tooltip p{ font-size: 13px; }    
.chekout_cart_bar .cart_extrainfo{padding-left: 0px;}
.ord-amount [class*='col-']{width: 100%;}
.earned_points{float: none;}
.ord-rec-sec{float: none;width: 100%; margin-top: 10px;}
.web_order_typecls li a h5{font-size: 16px}   

.menu-section-left{display: none}    
.menu-section-mobile-inner, .mobile-menu-section-left{display: block}   
}
@media (max-width: 1024px){
.footer{padding: 30px 0;}
.copyright{padding: 15px 0;}    
.delivery{margin-top: 30px;}    
.banner-text-box-right .btn_minwid{min-width: 100%;}
.bakery_row .slick-next{right: 0;}
.prd_chosen_row{padding: 25px 20px 20px 20px;}
.congrats.redeem-promation-body{padding: 24px 0;}    
.prd-sub{max-width: 100%;}
.prd-sub .slick-prev{z-index: 1; background: rgba(255,255,255,0.75); left: 0;}
.prd-sub .slick-next{z-index: 1; background: rgba(255,255,255,0.75); right: 0;}
.product_chosen_hea h6{font-size: 14px;}
.tnk-you{padding:45px 0 30px 0;}
.number-break .back_bx {left: 15px; width: 60px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 25px 30px 25px 45px;}  
.number-break .making-catering {width: 50%; padding: 5px 20px 5px 47px; border-right: 1px solid transparent;}
.award_galimg{ height: 250px }  
.head_left h4{font-size: 19px} 
.head_right h4{font-size: 16px}    
.order_amt h3{font-size: 21px}    
}
@media (max-width: 991px){
    .reservation-summary-button .right-button{
        margin-top: 0px;
}
    .head_left h4 {
        font-size: 13px;
    }
    .head_right h4 {
        font-size: 13px;
    }
.hbslider_sec h2{ font-size: 20px;} 
.container_720 { max-width: 100%;}

/*--category--*/
.pkg_ginfo h4 {font-size: 16px;margin: 0 0 10px;}
.pkgselect_dd .chosen-container {font-size: 14px;}
.pkgselect_dd .chosen-container-single .chosen-single {font-size: 14px;}
.pkg_gbtsec .btn {font-size: 14px; min-width: 170px;height: 42px;}
.pkg_ginfo .btn {font-size: 14px; padding: 9px 20px 7px;height: 34px;}
/*--update your package--*/
.pkg_listsec .bx_in {padding: 15px;}
.pkglist_itemtable > div {display: block;}
.pkglist_img {width: 100%; padding: 0px 0px 10px;}
.pkglist_info {width: 100%; padding: 15px 0px 0px;}
.pkglist_pricebx {width: 100%; border-left: 1px solid transparent; padding: 0px 0px; text-align: left;}
.pkselect_tabin {padding: 15px 0;}
.package_checkbx .bx_in {padding: 15px 15px;}
.package_formtt {font-size: 17px;}
.package_checkitem {padding: 15px 15px;}
.package_checkbx .package_formchecklist .custom_checkbox span {font-size: 14px;padding: 5px 0 0 40px;}
.package_checkbx .package_formchecklist .custom_checkbox span b {font-size: 14px;}
.package_addonbx .bx_in {    padding: 20px 15px;}
.package_setupbx .bx_in { padding: 20px 15px;}
.package_descbx .bx_in { padding: 20px 15px;}
.title4 {  font-size: 16px;}

.form-group [class*="col-md-"]{ margin-bottom: 8px;}
.form-group [class*="col-md-"]:last-child { margin-bottom: 0; }    
.delivery-item span{ font-size: 14px;}
.delivery-item h2{ font-size: 24px;}
.delivery-item p{ font-size: 16px;}
/* Tab sec */
.nav-tabs{ display: none;}
.tab-content>.tab-pane{ display: block; opacity: 1; margin: 0 0 15px;}
.tab_mobtrigger{ display: block; position: relative; padding-right: 45px; margin: 0; cursor: pointer;}
.tab_mobtrigger i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; margin: auto 0; text-align: center;}
.tab_mobtrigger i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.tab_mobtrigger.active i:after{ content: "\f068";}
.tab_mobrow{ display: none; margin: 0px 0 0;}
.tab-content > .tab-pane.active > .tab_mobrow{ display: block; padding-bottom: 0; padding-top: 0px;}
/* Main Tab */
.main_tabsec .nav-tabs{ display: none;}
.main_tabsec .tab_mobtrigger{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; font-size: 16px;  }
.filter_tabsec .tab_mobtrigger{ padding: 5px 45px 8px 10px; font-size: 16px; }
.filter_tabsec .tab_mobtrigger:after{ 
content: ""; background: #ecdfcc; height: 3px; position: absolute; left: 0; right: 0; bottom: 0;  pointer-events: none; -webkit-transition: all 400ms; transition: all 400ms;}
.tab-content > .tab-pane.promation-tab-section.active > .tab_mobrow{padding-top: 0;}
.small_responsive_row .filter_tabsec .tab_mobtrigger.inner_tab_border{display: none;}
.small_responsive_row .tab-content > .tab-pane.active > .tab_mobrow{display: block;}
.main_tabsec .small_responsive_row  .nav-tabs{display: block; } 
.mobiCategory .nav-tabs{ display:none;}
.merchants-tab .nav-tabs{ display: flex; }    
.merchants-tab .filter_tabsec .nav-tabs>li>a { font-size: 14px;height: 100%}    
    
.sub_tab_mobtrigger.active {background: #0b0405; color: #fff;}
.sub_tab_mobtrigger {background:#b69a6b; padding: 15px 45px 15px 15px; font-size: 16px;  color: #fff; display: block; position: relative; margin: 0; cursor: pointer;margin: 0px 15px;}
.sub_tab_mobrow{ display: none; margin: 10px 0px 0px;}
.tab-content > .tab-pane.active > .sub_tab_mobrow {display: block; padding-bottom: 0; padding-top: 10px;}
.sub_tab_mobtrigger i {position: absolute;width: 45px;height: 25px;line-height: 25px;right: 0;top: 0;bottom: 0;margin: auto 0;
text-align: center;}
.sub_tab_mobtrigger i:after {content: "\f067";font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.sub_tab_mobtrigger.active i:after {content: "\f068";}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{font-size: 15px;}    
.main_tabsec .small_responsive_rewards  .nav-tabs{display: block;}
.small_responsive_rewards .filter_tabsec .tab_mobtrigger{display: none;}    
.delivery-inner-comman{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.delivery-item{margin-top: 10px;}    
.delivery_total.delivery_total_number .delivery_total_left h4{font-size: 20px;}
.delivery_total.delivery_total_number .delivery_total_left h2{font-size: 14px;}
.delivery_total_left h3{font-size: 15px;}
.delivery_total_left span{line-height: normal;}
.redeem-item-hea .points{font-size: 15px;}
.pop-whole-lhs{padding: 80px 15px;position: static;-webkit-transform: translateY(-0%); transform: translateY(-0%); }
.pop-whole-lhs-inner{max-width: 100%;margin: 0 auto;}    
.login-popup .mfp-close, .signup-popup .mfp-close, .signup-corparateone-popup .mfp-close, .signup-corparatetwo-popup .mfp-close, .guest-popup .mfp-close{right: 3px;background: #fff;opacity: 1;width: 30px;height: 30px;line-height: 30px;top:3px;}
.signup-corparatetwo-popup .inside-popup-rhs, .guest-popup .inside-popup-rhs{padding: 50px 20px 15px 20px;}
.inside-popup-rhs{width: 100%;max-width: 100%;margin: 0 auto;float: none;}
.popup_equalrw{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.pop_title{text-align: center;padding-left: 0;margin: 0 auto 10px auto;}
.pop_title i{position: static;text-align: center;margin: auto;}
.pop_title h2{margin-top: 10px;}
.self_pop_item_loc p{padding: 0 10px; }
.innerproduct{margin-bottom: 25px;}
.product_chosen_item_left{width: 100%;margin: 10px 0;}
.product_chosen_item_left_second {width: 100%;margin: 0;}
.inn_product_row{margin-top: 20px;}
.inn-product-popup-bg, .inn-product-popup-background{background-image: inherit !important;padding-top: 0;text-align: center;}
.inn-product-popup-inner{max-width: 100%;}
.other-add-row li{width: 100%;}
.account_sec{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.accmenu_sec{width: 100%;}
.accsec_right{width: 100%; padding: 20px 15px;}    
.mainacc_menuout{margin: 0 0 10px; }
.mainacc_menuico{ display: block; position: relative; padding: 9px 0 9px 50px; color: #191919; font-size: 17px; cursor: pointer; overflow: hidden; z-index: 1;  }
.mainacc_menuico b{ font-weight: normal;}
.mainacc_menuico:after{content: ""; position: absolute; left: 0; top: -100%; width: 100%; height: 100%; background: #ecdfcd; -webkit-transition: all 0.5s; transition: all 0.5s; z-index: -1;}
.mainacc_menuico.active:after{ top: 0;}
.mainacc_menuico i{ position: absolute; left: 1px; top: 1px; font-size: 18px; width: 40px; height: 40px; line-height: 40px; background: #ecdfcd; text-align: center; -webkit-box-shadow: 0 0 1px  0 #555; box-shadow: 0 0 1px  0 #555;}
.mainacc_menuico i:before{ -webkit-transition: all 0.5s; transition: all 0.5s;  display: block;}
.mainacc_menuico.active i:before{ -webkit-transform: rotate(90deg); transform: rotate(90deg);}
.mainacc_menulist{ display: none;}
.mainacc_menulist > li{ display: block; width: auto; border-left: 0; text-align: left; border-top: 1px solid #ddd1c0;}
.mainacc_menulist > li >a{ text-transform: none; padding: 10px 15px 10px 50px;    background: #ecdfcd; color: #191919;}
.mainacc_menulist > li >a:after{ display: none;}
.mbacc_mslidersec{ display: block; padding: 0; position: relative;}
.mbacc_mslidersec .slick-track .slick-slide > div, .mobile-catering-slider .slick-track .slick-slide > div{ width: 100%; padding:0 2px}
.mbacc_mslide a, .mobile-catering-slider .slick-track .slick-slide a{ display: block; text-align: center; color: #000; font-size: 17px; padding: 20px 10px; background: #fff; font-weight: 600; text-transform: uppercase; }
.mbacc_mslide.active a, .mobile-catering-slider .slick-track .active a{ background: #e87b1e; color: #fff; }
.mbacc_mslidersec .slick-arrow, .mobile-catering-slider .slick-arrow{ background: rgba(0,0,0,0.6); border: 0; width: 30px; height: 30px; position: absolute; left: 5px; top: 0; bottom: 0; margin: auto 0; text-align: center; overflow: hidden; font-size: 0;color: #fff; z-index: 1;padding: 0}
.mbacc_mslidersec .slick-next{ right: 2px; left: auto;}
.mbacc_mslidersec .slick-arrow:before, .mobile-catering-slider .slick-arrow:before{ content: "\f104"; display: inline-block;
font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale; line-height: 25px; font-size: 24px; }
.mbacc_mslidersec .slick-next:before, .mobile-catering-slider .slick-next:before{ content: "\f105";}
.main_tabsec_inner{ padding: 10px 10px;}
.mainacc_menusec .main_tabsec_inner .nav-tabs{ margin-bottom: 10px;}
.main_tabsec_inner > .tab-content > .tab-pane.active > .tab_mobrow{ padding-top: 0; margin-top: 0;}
.mainacc_moblink{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; font-size: 16px;  
color: #fff; display: block; position: relative; margin: 0 0 10px; cursor: pointer; display: block;}
.mainacc_moblink:hover, .mainacc_moblink:focus{ color: #fff;}
.mainacc_moblink i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; margin: auto 0; text-align: center;}
.mainacc_moblink i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.mainacc_moblink.active i:after{ content: "\f068";}
.main_tabsec .tab_mobtrigger.del-order, .main_tabsec .tab_mobtrigger.take-order, .main_tabsec .tab_mobtrigger.cat-order{
background: #0b0405;}
.main_tabsec .tab_mobtrigger.del-order, .main_tabsec .tab_mobtrigger.take-order, .main_tabsec .tab_mobtrigger.cat-order{
padding-left: 60px;}
.del-order .two-wheller, .take-order .bag, .cat-order .cap{position: absolute;top: 50%;  transform: translateY(-50%);
-webkit-transform: translateY(-50%); margin: 0;}
.del-order .two-wheller{background: url(../images/scotter-hover.png) no-repeat; left: 10px;}
.take-order .bag{background: url(../images/bag-icon-hover.png) no-repeat; left: 21px;}
.cat-order .cap{background:url(../images/cap-hover.png) no-repeat; left: 20px;}
.filter_tabsec .tab_mobtrigger.inner_tab_border{background: inherit;border: 0;color: #000;text-align: center;text-transform: uppercase;}
.myacc_filter{padding-bottom: 0;}
.main_tabsec_inner .nav-tabs{ display: block; }
.main_tabsec_inner .tab_mobtrigger{ display: none;}
.main_tabsec_inner> .nav-tabs>li{ margin: 0; padding: 0 2.5px;}
.main_tabsec_inner> .nav-tabs>li> a{ text-align: center; font-size: 16px; height: auto; padding: 13px 15px; border-radius: 4px;}
.main_tabsec_inner> .nav-tabs>li> a > span > b{ display: none;}
.main_tabsec_inner> .nav-tabs>li > a > i{ display: none;}
.main_tabsec_inner> .nav-tabs>li > em { display: none;}
.acc-inform .form-group [class*="col-md-"]{ margin-bottom: 11px;}
.acc-inform .form-group [class*="col-md-"]:last-child{ margin-bottom: 0;}
.redeem_pro li h2{font-size: 30px;}
.redeem_pro li h3{font-size: 21px;}
.redeem_pro li{margin: 0 15px;}
.promation_btn li{width: 100%;margin-right: 0;margin-bottom: 5px;}
.promo-earned-content h4{font-size: 20px;}
.promo-earned-content h2{font-size: 26px;}
.promo-earned-content p{font-size: 15px;}
.abc_code span{font-size: 18px;}
.bg_top_slider h3{font-size: 17px;}
.banner-inner h5{font-size: 15px;}
.banner-inner span{font-size: 13px;}
.delivery-inner{padding: 20px 0;}
.tnk-delivery{padding: 15px;}
.chk-payment-btn-row .row{display: block;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.chk-payment-btn-row .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.chk-payment-btn-row .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.delevery_popup .btn_sec .row{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.delevery_popup .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.delevery_popup .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.back_click{position: static;margin-bottom: 10px;}
/* Thank You */
.tick h2{ font-size: 24px;}
.tnk-detail h2{ font-size: 16px;}
.tnk-order h3{ font-size: 18px;}

/* Contact us */
.contactus_wrapper .row{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column;}
.contactus_wrapper .contact_left{ -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2;}
.contactus_wrapper .contact_right{ -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; margin: 0 0 20px;}
.cms-page h3{ font-size: 22px;}
.award_galinfo h3{font-size: 17px}
/*promo*/
.promation_btn{ text-align: center; }
.promo-earned-col {width: 100%; display: block; margin: 0 0 15px; }    
.redeem_popsec .promo-earned-content{ width: 65%; }
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ width: 35%; }
.innersection_wrap {  padding: 20px 0 0 0;}
.mainacc_toptext h2{margin: 10px 0 0; font-size: 30px}
.count_num_t{display: none}    
.web_order_typecls li.active a, .web_order_typecls li a:hover{ background: #e87b1e; }
.web_order_typecls li.active a h5, .web_order_typecls li a:hover h5{ color: #fff; }
.promo-form h3 span{font-size: 16px}    
.promo-form h3{font-size: 24px}    
.mypromo-main-div .myacc_order_details{ justify-content: space-between;}    
.mypromo-main-div .myacc_order_details .promo-earned-col{ width: 49%; margin: 0 0 20px 0 }    
    
.mobile-catering-slider{ display: block !important; padding: 0 40px 0; position: relative}  
.row-pgk-list > li, .pkg_gcol {  width: 50%;}    
.order_nav > .nav-tabs {display: none;}    
.mobile-catering-slider button.slick-arrow{ opacity: 1; width: 40px; height: 52px }     
.mobile-catering-slider button.slick-prev { left: -40px;}
.mobile-catering-slider button.slick-next { right: -40px; margin: 0px; left: inherit}    
.mobile-catering-slider .slick-track .slick-slide > div{ padding: 0 }    
.mobile-catering-slider .slick-track .slick-slide a{ padding: 10px }  

.reservation-full{padding: 25px 0px;}
.back_click{display: none}
.back_click_mbl {  position: absolute; display: block; top: 10px; left: 10px;} 
    .back_click_mbl a{color: #fff;}    
.chk-payment .form-group.custom-tarms-div {  padding: 0 5px 20px;}    
.membership_status img{ max-height: 72px }    
.membership_status span{top: 18px}
.accprofile { padding: 26px 15px 10px; }        
    .prd_chosen_sub_row{flex-wrap: wrap;}    
    p.total_price.product_details_price{width: 100%;     text-align: center;}
    .prd_chosen_sub_col{width: 100%}
}

@media (max-width: 900px){ 
.home-ordernow-single{ width: 50% }
.serving-passionately-left{ width: 100%}    
.serving-passionately-right{ width: 100%; padding: 30px 0 0 0}    
img.blue-round, img.green-round{ margin: 0 auto}    
.footer-bottom-left{ -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2; width: 50%;}    
.footer-bottom-middle{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;width: 100%}
.footer-bottom-right{ -webkit-box-ordinal-group: 4; -ms-flex-order: 3; order: 3;width: 50%; }    
.banner-info{ padding: 0 100px}    
.banner-info p br{display: none}    
}       

@media (max-width: 850px){          
.tbars .bakery_row_fixed{ top: 196px;}
.hme-product-inner .title_sec{margin-bottom: 5px;}
.delivery_bg{ background-size: cover; }
.popup_ttsec, .news_postal_popup .popup_ttsec{width: 100%; padding: 30px 15px; height: auto;}
.popup_right{width: 100%; padding: 20px 15px;}
.news_postal_popup .popup_right{width: 100%; height: 300px;}
.awesome_del h2{margin-bottom: 0;}
.title_sec h2{font-size: 29px;}
.title_sec small{font-size: 14px;}
.hme-prt-slidecontent{padding: 10px 15px; top: 0; right: 0; bottom: 0; left: 0;}
.hme-prt-slidecontent-inner p{font-size: 13px;}
.footer-inner [class*='col-']{width: 100%; margin-bottom: 15px;}
.footer-inner .stay-connect ul li a{margin-bottom: 0;}
.footer-inner .news-letter{margin-top: 0;}
.footer-inner h4{font-size: 17px; margin-bottom: 5px;}
.footer-inner .news-letter h4{margin-bottom: 20px;}
.tnk-header{max-width: 100%;}
.tnk-you .container{ padding-left: 0; padding-right: 0;}
.tnk-delivery .cart_footer.tnk_cart_footer{margin-top: 10px;}
.order-cat-table table{max-width: none;}
.ord-amount-item{max-width: 97%;}
.htopinfo p{font-size: 10px;}
.htopinfo p span{ font-size: 8px;}
.htopinfo{width: 100%; text-align: center;}
.htmenu_sec{width: 100%;text-align: center;}
.horder_btn .btn{width: 100%;}
.inn-product-popup{margin-top: 20px;}
.detail-pg-inner{padding: 50px 10px 0;} 
/*upadate package*/
.number-break .back_bx {left: 15px; width: 80px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time { width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
.number-break .break-time .title4 {font-size: 16px; margin: 0 0 10px;}
.number-break .making-catering {width: 100%; padding: 80px 10px 15px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.tbars header{ height: 147px;}
.innerproduct-item-image img{width: 100%}    
.home-order-now .hon-icon {
    width: 90px;
    border: 2px solid #fff;
    height: 90px;
}
.hon-icon img {
    width: 50px !important;
}    
.hon-icon-bg span {
    margin: 10px 0 0;
    font-size: 13px;
}
.promo-folio-content > ul > li, .merchants-info > ul > li {
    width: 49%;
    margin: 0 0 20px 0;
    }    
    .promo-folio-content > ul, .merchants-info > ul{justify-content: space-between;}    
}
@media (max-width: 768px){
    /*.mypromo-main-div .myacc_order_details .promo-earned-col{
            width: 30%;
    }*/
    .promo-earned-info{
            padding: 20px 10px;
    }
    .home-banner .slick-arrow, .notify-row .slick-arrow{
            top: 43%;
    }
.home-banner .container-full {
    padding-left: 0;
    padding-right: 0;
}
/*.banner-info-text::before {
    position: absolute;
    content: '';
    background: #ccc;
    width: 100%;
    height: 100%;
    right: 0;
    left: 35%;
    top: 0;
    bottom: 0;
    background-size: contain;
}*/
.banner-info-text h1 {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 600;
    color: #fff;
}
.banner-info-text {
    
    top: -1px!important;
    padding: 15px!important;
    text-align: center!important;
    position: relative!important;
    right: 0!important;
    max-width: 100%!important;
    width: 100%!important;
    background: #f5f5f5;
}
.hmenu_actions, .htico_cart{position: static}   
.hmenu_actions {
    position: absolute;
}      
.hcart_dropdown{ width: 100%;}     
.date_list {padding: 20px 15px;}
.time_slot {padding: 20px 15px 20px;}
.add_time {margin: 10px 15px 20px 15px;}
.choose_location {padding: 20px 15px 20px; margin-bottom: 20px;}
.cedele-location {padding: 2px 15px 0px 0px;}
.location-list ul li {font-size: 14px; line-height: 1.3; height: auto; padding: 10px 15px;}
.catering_checout .checkout-total .cdd-details-lhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
.catering .ttsplitter {margin: 0 auto 10px;}
.catering_checout .checkout-total .checkout-control-group-top {padding: 20px 0px 20px 0px;}
.checkout-total .checkout-control-group-middle {padding: 20px 0px 0px 0px;}
.catering_checout .checkout-total .cdd-details-rhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
.addon_linfo, .addon_lright, .pkselected_package {width: 100%;} 
/*-------Categories page--------*/
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time { width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
.number-break .break-time .title4 {font-size: 16px; margin: 0 0 10px;}
.number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.event-time .form-control { height: 45px;}
.making-place {font-size: 16px;}
.making-place {font-size: 15px;}
.event-time .title4 {font-size: 16px;}
.pkselected_package .form-group { /*width: calc(100% - 140px); width: calc(100% - 135px); */min-width: auto;}
.banner-info, .home-banner .slick-arrow, .diamond_divider{
    /* display: none !important; */
}    
.home-banner .banner-info p{
    line-height: 0.4;
    font-family: 'Poppins', sans-serif;
    color: #000;
    font-size: 17px;
    line-height: 1.4;
    max-width: 100%;
}
.home-banner .banner-info h4{
    font-size: 22px;
    color: #e67a1e;
    margin-bottom: 20px;
    font-weight: 800;
    padding: 3px;
    line-height: 30px;
}
.footer-bottom-middle .footer-menu{ width: 33% }     
.outletul > li{ width: 100%; margin: 0 0 30px 0;}
.outletul > li:last-child{margin-bottom: 0}
.ourrest_info h4{font-size: 19px}    
.locate-mapleft{ width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2}
.locate-mapright{ width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1}        
.contact_col{ width: 100%; padding: 0;  }    
.contact_form h3{font-size: 22px}   
.inner_banner {  height: 250px;}    
.award_galcol{width: 48%}    
.about_lirow{-ms-flex-wrap: wrap;flex-wrap: wrap; margin: 0 0 20px 0}    
.about_lirow:last-of-type { margin-bottom:0px;}    
.about_liimg, .about_liinfo{ width: 100% }    
.about_liinfo{padding: 30px 20px 20px 20px;}
.brand_note {margin: 0 0 30px;}        
.acc-inform .form-group .row .col-md-6 { width: 100%; margin: 0 ;}    
.myacc_order_details .current_order{width: 100%}    
.order_delivery_item li{width: 50%}    
.web_order_typecls{ margin: 0 0 20px 0 }    
.checkout-heading{font-size: 18px}    
.checkout-total{margin: 0 auto}    
.service-makkan img { margin-top: -85px; width: 300px;}    
}
@media (max-width: 767px){   
.title1{font-size: 25px;}
.title3{ font-size: 18px;}
.htico_search{ position: static;}
.hsearch_sec{ left: 15px; right: 15px; width: auto;}
.banner .container, .delivery .container, .hme-product .container, .outlet .container{ padding-left:0; padding-right: 0; }
.banner-top-text{ padding: 0 36px; margin-bottom: 25px;}
.bg_top_slider:before{ right: -23px; bottom: -12px; width: 46px; height: 37px; background-size: cover;}
.bg_top_slider:after{ left: -23px; bottom: -12px; width: 46px; height: 37px; background-size: cover;}
.bg_top_slider h3{ font-size: 14px;}
.bg_top_slide p{ font-size: 12px;}
    .location-mapsec > div{ height: 350px;}
.main_tabsec .nav-tabs{ margin: 0 0 25px; border-width: 1px;}
.main_tabsec .nav-tabs>li>a{ font-size: 14px; padding: 10px 10px;}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
.small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
.video_popup .youtube_player_iframe{ height: 300px;}
.selfpop_mapouter{ height: 280px !important;}
.chekout_cart_bar .cart_info{width: auto;}
.chekout_cart_bar .order-detail-inner-icon{margin: 0 auto;}
.redeem-col{padding: 15px 10px;}
.redeem-item-hea h4{font-size: 20px;}
.redeem-item-hea span{font-size: 20px;}
.pop-whole-lhs{padding: 40px 15px;}
.inside-popup-rhs, .signup-popup .inside-popup-rhs, .signup-corparateone-popup .inside-popup-rhs{padding: 15px 20px;}
.signup-corparatetwo-popup .inside-popup-rhs{padding:50px 20px 15px 20px;}
.popup-header{padding: 10px 0 10px 0;}
.guest_chk_out{margin-left: 0;margin-right: 0;}
.guest_chk_out .btn{white-space: inherit;height: auto;}
.pop_title h2, .order-body h2{font-size: 30px;}
.pop_title small{font-size: 17px;}
.self_pop_row{ height: auto;}
.self_outlet{margin-top: 15px; height: auto;}   
.self_pop_col{width: 100%;  margin-bottom: 0;}
.self_pop_col.self_pop_col_right{width: 100%;margin-left: 0;max-width: 100%;}
.self_popup_map_col{ height: auto; margin: 0;}
.self_pop_item_map{width: 100%; margin: 0 auto; display: none;}
.self_popup .self-back{ display: none;}
.self_outlet_inner{height: 330px;}
.self_pop_item_loc .or-seperator-row{display: none;}
.pouup_in{padding: 30px 20px 20px;}
.redeem_popsec .pouup_in{ padding: 30px 20px 20px;}
.redeem_popsec .title3{ font-size: 20px;}
.hme-product-inner .ttsplitter{margin-bottom: 0;}
.product_chosen_col{width: 100%;margin: 10px 0;}
.product_chosen_col_inner{margin-bottom: 15px;margin-left: 0;margin-right: 0;}
.accsec_right h1{font-size: 27px;}
.accsec_right p{font-size: 14px;}
.accsec_right .ttsplitter_md{margin-top: 20px;}
.redeem_pro li.pro-subscribe button{font-size: 16px;}
.outlet_inner{padding: 23px 20px 20px 20px;}
.breadcrumb_left{width: 100%;display: inline-block;}
.breadcrumb_right{width: 100%;float: none;display: inline-block;margin: 10px 0;}
.breadcrumb_right .chosen_left{ left: 0; right: auto;}
.breadcrumb_right .re_select{max-width: calc(100% - 73px);}
.bakery_inner h3{font-size: 23px;}
label{font-size: 14px;}
.process_right p{font-size: 15px;}
.product_tag{float: none;}
.product_chosen_col .text-box{margin-left: 0;}
.cms-page .container{ padding: 25px 15px;}

/*----Venue Details page----*/  
.myacc_hea h3 {font-size: 20px;}
.ttsplitter span {font-size: 14px;}
.order_nav .tab_mobtrigger {font-size: 14px;}
.choose_location h4 {font-size: 14px;}
.choose_location .custom_radio span {font-size: 14px; line-height: 30px;}
.choose_location .custom_radio span:before {width: 26px; height: 26px;}
.cedele-location .form-group {margin: 20px 0 20px;}
.date_list h3 {font-size: 14px;}
.res-datetime-picker ul li dl dd, .catering_days_ul li dl dd {font-size: 17px;}  
.res-datetime-picker ul li dl.time_inner dd {font-size: 20px;}
.res-datetime-picker ul li dl dt {font-size: 12px;line-height: 1;}
.res-datetime-picker ul li dl.cl-next-days span {font-size: 14px;}
.res-datetime-picker ul li dl.cl-next-days {line-height: 1;}
.time_slot .time {padding: 0px 0px 10px 0px;}
.add_time {margin: 10px 15px 10px 15px;}
.time_slot .order-btn {padding: 20px 0px 15px;}
.time_slot h4 {font-size: 14px;}
.time_slot .order-btn .no {height: 42px; font-size: 20px; padding: 7px 50px; width: 49%;}
.time_slot .order-btn .yes {height: 42px; font-size: 20px; padding: 7px 50px; width: 49%;}
.time_slot .add_time .label {float: none; font-size: 14px; display: block; width: auto; text-align: center}
.time_slot .time label {font-size: 14px;}
.location-list ul li {font-size: 13px;}
/*----Checkout page----*/
.order_details .panel-title>a {font-size: 14px;}
.order_details .panel-body {padding:15px 10px;}
.package .package_cnt {padding: 0px 0px 15px;}
.package .package-list {padding: 0px 0px 10px 0px; color: #000000;}
.checkout_time {padding: 0px 0px 10px;}
.checkout_time label {font-size: 14px;} 
.package .title {font-size: 14px;}
.package .package-list .one {font-size: 14px;}
.package .package-list .two {font-size: 14px;}
.package .qty_bx {margin-top: 10px;}    
.qty_bx { width: 110px;height: 38px; padding: 0 34px;}    
.qty_bx span { width: 30px;}    
.qty_bx span.qty_minus:after { margin-top: 0px; left: 6px; }    
.qty_bx span.qty_plus:after {     right: 4px;
    margin-top: -6px;}    
   
.qty_bx span:after {
    width: 16px;
    height: 15px;
} 
.package .amt {margin-top: 0px;}
.amt span {font-size: 14px;}
.addon {padding: 15px 0px 15px 0px;}
.addon h4 {font-size: 14px;}
.addon_list h5 {margin: 10px 0px 15px 0px;}
.addon_list h5 .title {font-size: 14px;}
.addon_list p {margin: 0px 0px 0px 0px; font-size: 14px;}
.total_amt {padding: 15px 15px 10px 15px;}
.total_amt .total {font-size: 20px;}
.total_amt .subtotal {font-size: 14px;}
.buffet_setup {padding: 10px 0px 15px;}
.buffet_setup h5 .title {font-size: 14px;}
.buffet_setup p {font-size: 14px; margin: 0px 0px 7px 0px;}
.download-quote .btn {font-size: 14px;height: 30px;}
.catering_checout .redeem-item-hea h4 {font-size: 18px;}
.catering_checout .redeem-item-hea span {font-size: 14px;}
.catering_checout .redeem-item-hea .points {font-size: 13px;}
.catering_checout .redeem_apply .form-control {height: 40px;}
.catering_checout .focus-out .btn_minwid {min-width: 100px; padding-left: 15px; padding-right: 15px;}
.catering_checout .focus-out .btn {font-size: 14px; height: 40px;}
/*-----Thank You page------*/
.catering_thak_you .package .title {padding-left: 0px;}
.catering_thak_you .order_details .qtybxs {font-size: 14px;line-height: 1.6;}
.catering_thak_you .package .amt {margin-top: 0px;}
.catering_thak_you .addon_list h5 .title {padding-left: 0px;}
.catering_thak_you .addon_list h5 {margin: 10px 0px 5px 0px;}
/*-----Categories------*/
.number-break .back_bx {left: 15px; width: 70px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time { width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
.number-break .break-time .title4 {font-size: 15px; margin: 0px 0px 10px;}
.number-break .making-catering {width: 100%; padding: 80px 10px 15px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.event-time .form-control { height: 45px;}
.making-place {font-size: 100%;}
.event-time .title4 {font-size: 15px;margin-bottom: 7px;}
.title4 {font-size: 14px;}
.bts.text-right {text-align: left;}
.pkselected_filtersec label {font-size: 14px;}
.pkg_gimg img{width:100%;}
.pkg_ginfo h4 {font-size: 16px;margin: 0 0 10px;}
.pkgselect_dd .chosen-container {font-size: 14px;}
.pkgselect_dd .chosen-container-single .chosen-single {font-size: 14px;}
.pkg_gbtsec .btn {font-size: 14px; min-width: 170px;height: 42px;}
.pkg_ginfo .btn {font-size: 14px; padding: 9px 20px 7px;height: 34px;}  
/*Addon*/
.pkgaddon_checklist li {width: 100%; padding: 0px 3px;}
.pkgaddon_checklist .custom_checkbox span {padding: 15px 15px 15px 70px; font-size: 16px;}
/*SETUP*/
.package_controltt .custom_radio span {font-size: 14px;}
.pkgsetup_list .package_formchecklist {padding-left: 0px;margin: 20px 0px 0px;}
.pkgsetup_list .package_formchecklist .custom_checkbox span {font-size: 14px;}
.package_form_helptext {font-size: 14px;}
.package_formchecklist .custom_checkbox span b {font-size: 14px;line-height: 1.5;}
.pkselected_package{margin-bottom: 10px;}   
.pkglist_pricebx > b {font-size: 16px;}
.pkselected_package .chosen-container-single .chosen-single {height: 42px;line-height: 42px;}
/*promo*/
.promation_btn .btn {font-size: 14px; height: 35px; padding: 7px 10px; min-width: 100px; }
.prodet_bansec{ display: block;}
.back_arrow{ width: 43px; height: 43px; line-height: 43px; }
.back_arrow img{ max-width: 11px;}
.prodet_banleft{width: auto; padding: 15px 15px 10px 15px;}
.prodet_baninfo{width: auto;padding: 0;}
.product_modifiers_listinner .modifiers_main_list{ width: 100%;}
.package_checkbx .package_formchecklist .custom_checkbox span{ font-size: 16px}    
.pkglist_img img{ width: 100% }
.package_checkrow {margin-bottom: 0px;}    
.pkg-detail-top-row{ -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: wrap column; flex-flow: wrap column; -webkit-box-align: center; -ms-flex-align: center; align-items: center;}    
.pkselected_filtersec .form-control{ padding-right: 46px }    
.datelist_bracktime h3, h3.choose-eventtype { font-size: 20px;margin: 0 0 10px 0;}    
.pkselected_catgrytext {  text-align: center; margin: 10px 0;}    
.checkout-total .checkout-control-group-bottom textarea{height: 110px}    
footer{ margin: 10px 0 0 0 }    
#CartListMdl .package .package_cnt .col-sm-cls .hr-flt-cls{font-size: 16px}    
#CartListMdl .Cart-list-mdlheader h2, #PackageEditMdl .Cart-list-mdlheader h2 {font-size: 26px; margin: 5px 0 10px 0;}    
.locate-lirow{ display: block }    
.mfp-close-btn-in .order_popup .mfp-close, .mfp-close-btn-in .awesome_popup .mfp-close, .mfp-close-btn-in .self_popup .mfp-close, .mfp-close-btn-in .delevery_popup .mfp-close, .mfp-close-btn-in .error_postal_popup .mfp-close, .mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close, .changepw_popup .mfp-close, .redeem_popsec .mfp-close, .pay-conf-popup .mfp-close, .warning_popup .mfp-close, .venue_popup .close-icon-but{ top: 9px; right: 10px; }    
.product-description-tab { padding: 5px 0 15px 0;}    
    .inn_product_row{margin-top: 0}    
}
@media (max-width: 680px){ 
.delivery_bg{ width: 100%;}
.innerproduct-col {
    width: 46% !important;
    margin: 0 6px 25px !important;
}
.cart_body{padding: 10px;}
.cart_left{width:calc(100% - 120px) !important; text-align: left;}    
    .product-details .row-replace .cart_right{flex-wrap: wrap;}    
.tnk-delivery .cart_row{padding-top: 5px;}
.cart_right{width: 120px  !important;     -webkit-box-pack: end !important;     -ms-flex-pack: end !important;     justify-content: flex-end !important; }
.cart_right .cart_price { margin: 0 0 0 10px;}
.pickup-cart-div .cart-header-first .cart_left {
    padding: 0 0 10px 0;
    width: 100% !important;
    text-align: center;
}    
.cart_extrainfo{padding-left: 0;}
.logo{transition: none; -webkit-transition: none;}
.menu_icon{margin-right: 0;}    
.delivery-inner{ padding: 0;}
.delivery-item{ width: 100%; max-width: 100%; padding: 20px 15px 16px;  margin-top: 0; border: 0;border-bottom: 1px solid #f0e8dd;}
.delivery-item:last-of-type{border-bottom: 0;}
.hme-prt-row .slick-slider, .detail-pg-slider .slick-slider{padding: 0 0px;}
.hme-product-inner .slick-prev, .hme-product-inner .slick-next{z-index: 1; background: #f5f5f5;}
.detail-pg-slider-comman .slick-prev, .detail-pg-slider-comman .slick-next{ z-index: 1;background: #f5f5f5; }
.inn-product-popup-inner{max-width: 100%;}
.setting_menu_list{position: static;}
.accpoints_bxin{padding: 0px 13px 0px;}
.accearn_pointtop span{font-size: 35px;}
.accsec_right .ttsplitter span{z-index: 1;}
.ord-amount .row{width: 100%;}
.ord-rec-sec li{width: 100%; max-width: 100%;}
.ord-amount [class*='col-']{padding-right: 0;}
.ord-amount{padding: 10px 0 0 10px;}
.order-cat-table{padding: 0 10px;}   
.order-cat-hea{max-width: 100%; padding: 8px 10px; margin-top: 10px;}
.ord-amount h2{font-size: 22px;}
.order-cat h2{font-size: 18px;}
.ord-amount-item{max-width: 100%;}
.cur-ord-rec{float: none;  padding: 0 10px;   width: 100%;  max-width: 100%; }
.cur-order-del{ max-width: none; padding: 0 10px; }
.cur-ord-rec li{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.click_view{text-align: center; margin: auto; padding: 0 10px; left: 0; right: 0;}
.cur-order-del li{margin-bottom: 10px; width: 100%; padding-left: 0; font-size: 19px;}
.cur-order-del li.cur-order-del-time{ width: 45%; text-align: right; margin-bottom: 0;}
.cur-order-del li.cur-order-del-date{ width: 55%; margin-bottom: 0;}
.cur-order-del li a.btn{ display: block;}
.cur-ord-rec-desktop, .click_view_desktop{ display: none;}
.cur-ord-rec-mobile{ position: relative; padding: 0 0 10px; border-bottom: 1px solid #efefee; margin: 10px 0;}
.myacc_dropmenu{position: absolute; width: 100%;left: 0;top: 100%; padding: 15px;}
.myacc_dropmenu::after{top: -16px; -webkit-transform: translateY(-50%); transform: translateY(-50%); left: 33px; -webkit-transform: rotate(448deg); transform: rotate(448deg);}
.check-pg-slider .slick-slider{padding: 0;}
.check_pg_inner .slick-prev, .check_pg_inner .slick-next{background: #f5f5f5;}
.banner-text-box-left{width: 100%; margin-bottom: 10px;}
.banner-inner h5{ height: auto;}
.banner-inner span{ height: auto;}
.banner-text-box-right{width: 100%;}
.banner-text-box-right .btn_minwid{margin-bottom: 0;}
.banner-text-box{padding: 20px 18px 20px 20px;}
.banner-right-content .btn{min-width: 100%;}    
.checkout-total .checkout-control-group-bottom textarea{height: 100px;}
.hme-product-inner .slick-prev::after, .hme-product-inner .slick-next::after, .prd-sub .slick-prev::after, .prd-sub .slick-next::after,.check_pg_inner .slick-prev::after, .check_pg_inner .slick-next::after,.detail-pg-slider-comman .slick-prev::after, .detail-pg-slider-comman .slick-next::after{width: 24px; height: 24px; top: 17%;}
.hme-product-inner .slick-prev::after, .prd-sub .slick-prev::after,.check_pg_inner .slick-prev::after,.detail-pg-slider-comman .slick-prev::after{background-position: -68px -18px;}
.hme-product-inner .slick-prev:hover::after, .prd-sub .slick-prev:hover::after,.check_pg_inner .slick-prev:hover::after,.detail-pg-slider-comman .slick-prev:hover::after{background-position: -160px -18px;}
.hme-product-inner .slick-prev, .hme-product-inner .slick-next, .prd-sub .slick-next, .prd-sub .slick-prev,.check_pg_inner .slick-next, .check_pg_inner .slick-prev,.detail-pg-slider-comman .slick-next, .detail-pg-slider-comman .slick-prev{width: 30px; height: 35px;}
.hme-product-inner .slick-next::after, .prd-sub .slick-next::after,.check_pg_inner .slick-next::after,.detail-pg-slider-comman .slick-next::after{background-position: -20px -18px;}
.hme-product-inner .slick-next:hover::after, .prd-sub .slick-next:hover::after,.check_pg_inner .slick-next:hover::after,.detail-pg-slider-comman .slick-next:hover::after{background-position: -112px -18px;}
.web_order_typecls li a{  padding: 15px 5px;}    
.promo-earned-info{     -webkit-box-orient: vertical;     -webkit-box-direction: normal;     -ms-flex-flow: column;     flex-flow: column; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;text-align: center}    
.promation_btn .button {margin: 5px 0 0 0;padding: 10px 22px 8px;font-size: 14px;}    
.product-details .row .cart_left{width: 100%; padding: 0}    
.product-details .row .cart_right{width: 100%; padding: 0; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; -webkit-box-align: center; -ms-flex-align: center; align-items: center }    
.product-details .row .cart_right .cart_price{ margin: 0 0 0 10px }    
.function-rooms ul li{ width: 49% }    
.function-rooms ul li:nth-child(3n){ margin-right:2% }    
.function-rooms ul li:nth-child(2n){ margin-right: 0 }    
a.view-rooms img{ min-height: 190px }    

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link, 
.mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link { height: inherit; font-size: 14px; }
.mypromo-main-div .myacc_order_details .promo-earned-col {
    width: 100%;
    margin: 0 0 20px 0;
}    
}
@media (max-width: 620px){ 
.row-pgk-list > li, .pkg_gcol {  width:100%;}    

.cart_row.cart-header-second h3 { font-weight: 600; font-size: 16px; }
.hcart_dropdown .cart_info {
    display: block;
    vertical-align: top;
    color: #3d3d3d;
    width: 100%;
    margin: 10px 0;
}
.cart_row.grant-total-cls p, .cart_row.grant-total-cls span { font-size: 22px; }
}
@media (max-width: 580px){ 
.tbars header{ height: 140px;}
.Products .breadcrumb{ padding: 64px 0 0 0;}
.Products .bread_space { margin: 64px 0 0; }
.htico_cart{ padding-right: 3px;}   
.mobile_account_item > a{ font-size: 15px;padding: 14px 40px 10px 40px;}
.mobile_account_item > a > i{ width: 20px; height: 20px; }  
.mobile_mainacc_menutrigger{ background-size: 16px auto; width: 46px; height: 42px;}
.mobile_mainacc_menulist li a{ padding: 12px 10px 12px 40px; font-size: 15px;}
.mobile_mainacc_menulist .setting_menu_list_icon{ left: 10px;}
.mobile_mainacc_menulist .setting_menu_list_icon.password_icon{left:12px}
  

.mobile_account_menu_click i.arrow_myaccount{ background-size: 16px auto; width: 46px; height: 42px;}
.mobile_account_delivery_item a{  font-size: 15px;}
.mobile_account_delivery_item li.delivery_mob a i{ width: 40px; height: 28px; background-size: auto 28px; }
.mobile_account_delivery_item li.takeaway_mob a i{ width: 40px; height: 28px; background-size: auto 28px; }
.hcategory_selected_text{ font-size: 14px; padding-right: 45px;}
.hcategory_menulist > li > a{ font-size: 14px;}
.hcategory_submenu >li >a{ font-size: 15px; padding-left: 35px;}
.loc-cont-part{ display: none;}
.bakery_row_fixed{ top: 150px;}  
.tbars .bakery_row_fixed{ top: 184px;}
.footer-inner [class*='col-']{width: 100%;margin-bottom: 10px;}
.footer-inner h4{margin-bottom: 10px;}
.chk-payment-col-radio li{margin: 0 0 10px 0;width: 100%;max-width: 95%;justify-content: center;}
.chk-payment-col-radio li:last-child{margin-bottom: 0;justify-content: center;}
.self-back{top: 5px;font-size: 16px;}
.pop-whole-lhs{padding: 40px 15px 20px;}
.pop-whole-lhs h3, .signup-popup .pop-whole-lhs h3{font-size: 30px;}
.pop-whole-lhs p{font-size: 16px;}
.order_delivery_item li{max-width: 95%;}
.order_delivery_item li:last-child{margin-bottom: 0;}
.order-body{padding: 20px 20px;}
.video_popup .order-body{padding: 50px 30px 25px 30px;}
.self_outlet h2{font-size: 21px;}
.self_outlet_inner li a{font-size: 14px;}
.self_pop_item_map{width: 100%;}
.self_pop_item_map img{width: 100%;}
.self_pop_item_loc .loc_btn{height: 52px;font-size: 14px;min-width: 100%;}
.self_pop_item_loc p{font-size: 15px;}
.chosen_adn_left span, .product_chosen_col_right .custom_checkbox span{font-size: 14px;}
.inn_product_hea p{margin-bottom: 10px;font-size: 13px;}
.prd_chosen_row{padding: 20px 10px 15px 10px;}

.prd_chosen_sub_item_right button{max-width: 100%;}
.footer-inner .news-letter button{padding: 15px 12px;font-size: 14px;}
.footer-inner h4{font-size: 17px;}
.footer-inner ul li a{font-size: 14px;}
.earned_points li h2{font-size: 20px;}
.tag span{font-size: 12px;}
.htmenu_sec > li{padding: 0 15px; }
.megaimg_item{width: 100%;  max-width: 364px;}
.pkselect_tabsec > .nav-tabs>li>a {font-size: 15px; padding: 10px 10px;}
.bts .form-group {width: 100% !important;}
.bts label {margin: 5px 0px 10px; display: block;}
.pkselected_filtersec label { display: block;  width: 100% !important; margin:5px 0px 0px;padding: 0; text-align: center}
.pkselected_package .form-group { width: 100%;} 
.pkselected_filtersec .time-label {display: block;  width: 100% !important;text-align: left; margin:5px 0px 5px;}
.pkselected_break .form-group {width: 100%;} 
.chk-payment-btn .btn {padding: 10px 22px; height: 37px;font-size: 14px;}
.chk-payment-btn .btn_download {font-size: 14px; padding: 10px 20px 10px;height: 35px;} 
.inner_banner {    height: 200px;}
.mbacc_mslide a{ padding: 15px 10px;    font-size: 16px}
.web_order_typecls li a h5 { font-size: 14px;}
.chk_hea > span{
    display: block;
    font-size: 14px;
     float: none; 
     clear: both; 
     margin: 5px 0 0 0;
     display: -webkit-flex;
     display: flex;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -webkit-align-items: center;
     align-items: center;
}  
.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a{font-size: 17px}
    span.myaccount_separate{height: 14px}
/*21-5-19*/
.reservation-summary ul li span{font-size: 13px;}
.reservation-summary{padding: 15px;}
.reservation-summary-full{background: none; padding-top: 0px; padding-bottom: 0px;} 
.reservation-full{padding: 20px 0px;}   
.reservation-full h2{margin-bottom: 15px; font-size: 30px;}
.reservation-summary-full h3{font-size: 28px; margin-bottom: 15px;}
.go-back a, .continue a{font-size: 17px;}
.reservation-summary-button{max-width: 425px; margin-left: auto; margin-right: auto;}
/*.reservation-counter, .choose-outlet, .choose-datetime-full, .special-instructions{background-color: transparent; padding-top: 0px;}*/
.reservation-counter-full{display: block;}
.reservation-counter-left, .reservation-counter-right{float: none; width: 100%;}
.reservation-counter-single:first-child{margin-top: 30px; margin-bottom: 30px;}
.goto-chkpage-cls a, .viewmenu-btn-row a{ width: 100%; margin: 0}
.goto-chkpage-cls a:first-child, .viewmenu-btn-row a:first-child{ margin-bottom: 5px }    
.popup_ttsec, .news_postal_popup .popup_ttsec{ min-height: inherit}    
.two-button-row{-ms-flex-wrap: wrap;flex-wrap: wrap}    
.delevery_popup .go_second{margin-top: 5px}  

/*30-5-19*/
.reservation-summary-button .go-back, .reservation-summary-button .continue{width: 100%;} 
.reservation-summary-button .continue{margin-bottom: 10px; margin-top: 5px;}
.reservation-summary-button{display: -webkit-box;display: -ms-flexbox;display: flex; -ms-flex-wrap: wrap-reverse; flex-wrap: wrap-reverse;}
.common-top-div.productpage-main-div {  padding-top: 0!important;}    
.hcategory_trigger{ font-size: 14px;padding-right: 20px;}    
.hcategory_trigger:after {
    width: 16px;
    height: 15px;
}
.hcategory_trigger.active:after {
    width: 12px;
    height: 12px;
    right: 3px;
}    
.hcategory_sec {
    top: 106px; height: 43px;
    z-index:9;
}
.menu-section-mobile-inner figure.small_sticky{ top: 153px; }
.menu-section_full {
    margin: 32px 0 0 0;
}

.res-cntmaindiv { padding: 20px 20px 0;}
    p.total_price.product_details_price{ font-size: 26px; font-weight: 500; padding-bottom: 10px;}

}
@media (max-width: 550px){   

.bg_top_slider h3{ font-size: 12px;}
.bg_top_slide p { font-size: 11px; }
.promo-earned-col-center{ display: block; }
.promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
.promo-earned-content{ display: block; width: 100%; }
.promo-earned-content p {font-size: 13px; }
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
.redeem_popsec .promo-earned-content{ width: 100%; display: block; }
.redeem_popsec .promo-earned-content h4 {font-size: 20px; }
.redeem_popsec .promo-earned-content h2, .redeem_popsec .promo-earned-content h2 span{font-size: 25px; max-height: inherit; overflow: visible;}
.promo-earned-col-center .innerproduct-item-image:before{ background-image: url(../images/promo-tags2.png); height: 90px; width: 100%; top: auto; bottom: -2px; left: 0; }
.promo-earned-col-center .innerproduct-item-image {display: block; width: 100%; height: 250px; }
.award_galcol{width: 100%; padding: 0}    
.award_galcol:last-of-type{ margin-bottom: 0 }    
.award_galinfo{margin: 0;    top: 70px;}
.banner-info h2, .featured-products-main h2, .serving-passionately-right h3{font-size: 28px}
.featured-products-main p, .mainacc_toptext p{font-size: 16px}
.hmenu_list{ width: 200%; max-width: inherit}
/*.mypromo-main-div .myacc_order_details .promo-earned-col { width: 60%;  margin: 0 0 15px 0;}    */
.filter_tabsec .nav-tabs>li>a:after{max-width: 50%}    
.hook{margin-bottom: 30px; } 

.choose-outlet, .special-instructions, .choose-datetime-full{padding-left: 0px; padding-right: 0px;}
    .promo-earned-col-item{ flex-wrap: wrap;}
    .promo-earned-col-image{width: 100%}
    .promo-earned-info{width: 100%;padding: 10px 10px 15px;}
    .promo-earned-info:before{display: none;}
    .promo-earned-col-image .innerproduct-item-image img{ position: inherit; }
    .mainacc_menusec .main_tabsec_inner .nav-tabs {
    margin-bottom: 20px;
}
    .mbacc_mslide a {  font-size: 14px; font-weight: 500;}
}
@media (max-width: 480px){ 
    .pl-0{
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    .custom_checkbox span:before{
        /*left: inherit;*/
    right: 0;
    display: block;
    top: -10px;
    }
.featured-products-full .slick-track .slick-slide > div{ width: 100%}
.menu_icon{ width: 30px;}
.htico_search> a{ padding: 20px 0;}
.htico_search> a > i{ width: 24px; height: 24px; background-size: 24px auto;}
.htico_cart > a{ padding: 2px 0;}
.innerproduct-row{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center; text-align: center;}

.spilter{text-align: center; margin: 1px 10px 0; vertical-align: middle;}
.two-links a{ margin: 0 2px; vertical-align: middle;}
.popup-body .sign_reg a{white-space: inherit; height: auto; font-size: 14px;}
.btn{font-size: 14px; padding-top: 15px; padding-bottom: 15px;}
.btn-sm{ padding-top: 10px; padding-bottom: 10px;}
.news_subscribe p{font-size: 14px;}
.pop_title h2{font-size: 26px;}
.news_postal_popup .pop_title p{font-size: 14px; line-height: normal;}
.title1{font-size: 20px;}
.inn_product_hea_left h3{font-size: 18px;}
.text-box textarea{font-size: 14px;}
.popup_social_icon{width: 100%; padding-left: 0;}
.popup_social_icon li a{padding: 0 8px;}
.popup_social span{color: #fff;}
.order-cat-table h4{font-size: 14px;}
.order-cat-table table td{font-size: 14px;}
.main_tabsec_inner> .nav-tabs>li> a{ font-size: 12px;}
.delivery_time [class*='col-']{width: 100%;}
.delivery_total_left{width: 100%; margin-bottom: 10px;}
.delivery_total_left.delivery_total_right{text-align: left;}
.chosen_adn_left{width: 280px;}
.delivery_total.delivery_total_number{padding-bottom: 0;}
.redeem_apply .btn{ min-width: auto; padding-left: 10px; padding-right: 10px;}
.redeem_apply label{ font-size: 12px; top: 17px;}
.redeem_apply.focused label{ top: -7px;}
.redeem_popsec .btn{ height: 46px; }
.ourrest_infolinks{ -ms-flex-wrap: wrap; flex-wrap: wrap }    
.outletul > li{  margin: 0 0 20px 0;}
.ourrest_info{padding: 20px 20px 110px 20px}    
.ourrest_infolinks li{width: 100%}    
.order_delivery_item li .header-ordernow-single-img img { height: 50px;}    
.order_delivery_item h3{font-size: 14px}    
.footer-bottom-left{ width: 100%; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center;  margin: 10px 0 10px 0;}    
.footer-bottom-right{width: 100%;    display: -webkit-box;    display: -ms-flexbox;    display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;}    
.footer-bottom-right ul{padding: 0}    
.footer-bottom-right h3{margin: 0 5px 0 0}    
.home-ordernow-single-img img {max-height: 40px;}    
.home-ordernow-single h3 { font-size: 18px;}    
.home-ordernow-single-img .button { font-size: 12px; padding: 8px 12px 6px;}
.home-badge-icon img { width:270px;}    
.home-badge-icon { top: -50px;}    
.serving-passionately-main{padding: 15px}    
.featured-products-section{padding-bottom: 20px}    
.web_order_typecls li a h5 { font-size: 13px;}    
.delivery_total_text::before{display: none}    
.catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {font-size: 14px;}    
.catering_days_ul li dl dt{font-size: 10px}    
.catering_days_ul li dl.cl-next-days span{font-size: 11px}    
.event-type-maindiv.two-button-row a {  font-size: 16px; padding: 15px 10px;}
.ownvenue-inner-div{max-width: inherit; margin: 20px auto 0}   
.order-select-brktime ul .time-cls{-ms-flex-wrap: wrap;flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center}    
.order-select-brktime ul .time-cls label{width: 100%; text-align: center; margin: 0 0 5px 0}    
.more-time-slots{display: -webkit-box;display: -ms-flexbox;display: flex}    
.more-time-slots a{ -webkit-box-flex: 1; -ms-flex: 1; flex: 1; padding: 10px 10px 10px; font-size: 16px}    
.checkout-total .form-group.controls-three > div{ width: 100%; margin: 0 0 10px 0 }    
.checkout-total .form-group.controls-three > div:last-of-type{ margin-bottom: 0 }    
.catering_checout .checkout-total .checkout-control-group-top { padding: 10px 0px 20px 0px;}    
.checkout-total .checkout-control-group-middle .form-group .input_area{ width: 100%;margin-bottom: 10px}    
.chk-payment-btn-row.checkout-continue-cls .row-replace, #PackageEditMdl .chk-payment-btn-row .row-replace{-ms-flex-wrap: wrap;flex-wrap: wrap}    
.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls, #PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls{ width: 100% }    
.chk-payment-btn-row.checkout-continue-cls .row-replace .go_second{ margin-top: 5px }    
.chk-payment-btn-row.checkout-continue-cls .row-replace .button{ font-size: 18px; padding: 14px 10px;}
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls:first-of-type{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2; margin-top: 5px}   

.catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
.home-ordernow-section { padding-top: 0px;}    
.home-order-now { display: none;}
.header-bottom .cart_info h4{ font-size: 14px;}    
} 

@media (max-width: 420px){
.hcart_tt{ padding-left: 20px;}
.hcart_tt h3{ font-size: 16px;}
.hclear_cart{ font-size: 12px;}
.location-list ul li {font-size: 12px;}
.cedele-location P {font-size: 12px;}
.catering_checout .checkout-total .cdd-details-rhs { padding: 30px 10px 25px 10px;}
.res-datetime-picker .tag {left: -7px;width: 125%;}
.number-break .back_bx {left: 10px; width: 50px; height: 42px; font-size: 12px; padding: 26px 6px 6px;}
.number-break .back_bx:after {top: 6px;font-size: 16px;}
.number-break .break-time {margin-top: 50px;}
.pkselect_tabsec > .nav-tabs>li>a {font-size: 13px; padding: 7px 7px;}
.title4 {font-size: 14px;}
.pkglist_infott h3 {font-size: 16px;}
.pkglist_infott p{font-size: 13px;}
.package_checkbx .package_formchecklist .custom_checkbox span {font-size: 13px;}
.package_checkbx .package_formchecklist .custom_checkbox span b {font-size: 13px;}
.pkglist_info p{font-size: 13px;}
.web_order_typecls li a h5 { font-size: 8px;}    
.main_tabsec .nav-tabs>li>a { font-size: 13px; padding: 5px 6px;}        
.function-rooms ul li{ width: 100%; margin:0 0 10px 0 }    
.function-rooms ul li:nth-child(3n){ margin-right:0% }    
.function-rooms ul li:nth-child(2n){ margin-right: 0 }        
.function-rooms ul li:last-child{ margin-bottom: 0 }    
.function-rooms ul li a{height: auto}    
a.view-rooms img{min-height: inherit; max-height: inherit; height: auto}    
.catering_days_ul li dl.cl-next-days span {font-size: 10px;}    
.catering_days_ul li dl dt { font-size: 9px;}    
.catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {font-size: 12px;}    
#PackageEditMdl .pkselected_filtersec .row-replace{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-flow: column;flex-flow: column}    

.catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}

.choose-outlet .custom_select {
    max-width: 100%;
    margin: 0 15px !important;
}

}

@media (max-width: 380px){
.tbars header{ height: 154px;}
.mobile_mainacc_menutrigger{ background-size: 12px auto; width: 32px;}
.mobile_account_item > a{padding: 13px 10px;}
.mobile_account_item > a > i{ left: 5px; width: 22px; height: 22px;}    
.mobile_mainacc_menulist li a{ padding: 12px 5px 12px 35px; font-size: 14px;}
.mobile_mainacc_menulist .setting_menu_list_icon{ left: 7px;}
.mobile_account_delivery_item a{font-size: 14px; padding: 10px 10px;}    
.mobile_account_menu_click i.arrow_myaccount{ background-size: 12px auto; width: 32px;}

.hcart_scrollarea{ max-height: 257px;}  
.delivery_total_left{float: none; margin-bottom: 10px;}
.cart_left{width: 100%;}
.cart_right{text-align: left;}
.cart_price{ margin: 5px 0;}
.ttsplitter span{z-index: 1;}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{font-size: 14px;}
.mobile-logo-div{ max-width: 160px }
.addon_poptop{padding: 0}    
.addon_popbot{ margin: 0 }    
.addon_popbot .row-replace{ -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center }    
.addon_linfo, .addon_lright, .pkselected_package {width: 100%;}    
#CartListMdl .total_amt { padding: 15px 15px;}    
.number-break { padding: 15px 5px 15px 5px;}    
.number-break .making-catering { padding: 60px 0 0;}   
.number-break .event-time{padding: 5px 0}    
.container { padding-left: 10px; padding-right: 10px;}    
a.go-bck-icon{top:-4px}    
h3.choose-eventtype { padding-left: 20px; }    
}
@media (max-width: 360px){ 
    .order_btns .button{
        font-size: 16px;
    }
    .description_div p{
        padding: 5px 10px;
    }
/*21-5-19*/
.reservation-summary ul li span{font-size: 11px;}
.reservation-summary{padding: 10px;}
.reservation-full h2{margin-bottom: 10px; font-size: 30px;}
.reservation-summary-full h3{font-size: 26px; margin-bottom: 12px;}
}

.close-icon-but {background: #000;}

@media(max-width:991px){
    .outlets-list-column{
        width: 100%;
    }
    .contactus_wrapper .row .ct-left{
        width: 100%;
    }
    .row {
        margin-right: 0px; 
         margin-left: 0px; 
    }
    .voucher-popup-inner {
        background: #fff;
        padding: 16px 16px 0px 15px;
        position: relative;
        z-index: 9;
        margin: 0 49px 0 0;
        width: 100%;
        transition: 0.4s linear all;
    }
    .full-login-new-header h3{
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        text-transform: capitalize;
    }
    .voucher-popup-inner h2{
        font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    }
    .productpage-main-div .menu-section-mobile-inner button.slick-arrow{
        margin-left: -10px;
    }
    .productpage-main-div button.slick-arrow.slick-next{
        right: -10px;
        
    }
}

@media(max-width:360px){
    .menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{
        font-size: 11px;
    }
}

@media(max-width: 991px){
.phone-show-dskp{ display: none;}
.phone-show-mbl{display: inline-block}
span.birthday-text.phone-show-dskp{display: none;}
.locate-lirow .phone-show-mbl a{ color: #000 }
.locate-lirow.active .phone-show-mbl a{ color: #e87b1e }
.contactus_wrapper .row .ct-right{
    width: 100%;
    float: right;
}
.alert_fixed a{
    right: 10px;
}
}


@media (max-width: 991px){
.form-group1234 input {
    padding: 12px 15px;
    font-size: 13px;
}
.two-boxes li h3, .three-boxes li h3{
    font-size: 30px;
}
.pagesList-main-div .two-boxes li h3, .bottom-three-boxes li h3{
    font-size: 29px;
}

.pagesList-main-div .two-boxes li.left-middle div, .bottom-three-boxes li.left-middle div{
    top: 75%;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
.two-boxes li.left-bottom div, .three-boxes li.left-bottom div{
    bottom: 15px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
.two-boxes li.right-bottom div, .three-boxes li.right-bottom div{
    bottom: 15px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
}
@media (min-width: 1660px){
    .home-banner .slick-initialized .slick-slide > div>div:after{
        width: 49%;
    }
}
@media screen and (min-width: 1500px)and (max-width: 1659px) {
     .home-banner .slick-initialized .slick-slide > div>div:after{
    width: 33%;
}
}   
@media screen and (max-width: 3000px) and (min-width: 1600px) {
    .home-banner .slick-initialized .slick-slide > div>div:after {
        width: 50%;
    }
} 


@media (max-width: 460px){
.myacc_filter {
    padding: 10px 5px;
}
.promo-earned-col-item{
    justify-content: center;
    }
}
@media screen and (min-width: 300px) and (max-width: 400px) {
.promo-bot-left p{
    font-size: 13px;
}
.promo-earned-col .tag{
left: -73px;
    top: 10px;
}
.promo-earned-col-item{
    justify-content: center;
}
.tag span{
    font-size: 11px;
}
}
@media (max-width: 1080px){
.banner-info-text {
    top: 30%;
    margin: 0 auto;
}
.home-banner .slick-list{
        position: relative;
    display: block;
    height: auto!important;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.banner-info-text p { font-size: 15px;}
.hand-img{ display: none;}    
    .d-col-member{width: 100%}    
.hand-text { width: 100%;}    
.kaki-down{width: 50%}   
.kaki-member{width: 50%}
    .kd-top h3, .hand-text h3{font-size: 42px;}    
}

@media(max-width: 1000px) and (min-width: 768px){
    .home-banner .banner-info p{
    line-height: 0.4;
    font-family: 'Poppins', sans-serif;
    color: #000;
    font-size: 17px;
    line-height: 1.4;
    max-width: 100%;
}
.home-banner .banner-info h4{
    font-size: 22px;
    color: #e67a1e;
    margin-bottom: 20px;
    font-weight: 800;
    padding: 3px;
    line-height: 30px;
}
.home-banner .banner-info {
    left: 0px!important;
    padding: 0!important;
    position: relative;
    right: 0!important;
    /* z-index: 91; */
    /* max-width: 1280px; */
    top: -1px;
    -webkit-transform: none;
    transform: none!important;
    margin: 0 auto;
    text-align: none;
        z-index: 91;
    max-width: 1280px;
    top: 52%;
}
.banner-info-text {
    top: 30%;
    margin: 0 auto;
}
.home-banner .slick-initialized .slick-slide > div>div:after{
    content: none;
}
.banner-info-text {
    top: -1px!important;
    padding: 15px!important;
    text-align: center!important;
    position: relative!important;
    right: 0!important;
    max-width: 100%!important;
    width: 100%!important;
    background: #f5f5f5;
}
    .home-banner .slick-arrow, .notify-row .slick-arrow{
            top: 44%;
    }
}

@media (max-width: 991px){
.filter_tabsec .product_voucher.nav-tabs{ border-bottom: 1px solid #ddd;    }
.footer-newletter { width: 47%; order: 2;}
.footer-nav { order: 3; width: 47%;}
.footer-logo { width: 100%; order: 1;margin-bottom: 40px}
.mainacc_menucontent { width: 100%;}    
.mainacc_menuout { width: 100%;}    
}

@media (max-width: 780px){
    .product-voucher-popup{
        margin: 0;
        padding: 0;
    }
    .home-content { padding: 30px 0 0;}
    .highlight-products { padding: 40px 0 0 0;}
    .fp-title h3{font-size: 38px;}
    .fp-title{padding-bottom: 20px}
    .home-content-inner ul li { margin-bottom: 15px;}    
	.home-content-inner ul li a {  font-size: 18px;}  
    .our-promotions-section h2{font-size: 38px; margin: 0px 0px 15px 0px;}
    .promo-product { padding: 40px 0 20px 0;}
    .join-us-section{padding: 20px 0;}
    .home-our-journey{color: #000; padding: 40px 0;}
    .hoj-rhs { width: 100%; order: 1;padding-bottom: 20px}
	.hoj-lhs { width: 100%; order: 2;}
    .home-our-journey:before{display: none;}
    .hoj-lhs h2{font-size: 38px; margin: 0 0 10px 0;color: #000;}
    .hoj-lhs h5{color: #000;}
    .social-media {  margin: 20px 0 0;}
    .banner-text h4{font-size: 30px; margin-bottom: 5px;}
    .banner-text p{font-size: 20px;}
    .hmenu_actions{right: 0}
    .top-info-lhs-txt h3{font-size: 20px;}
    .gallery-list li {  padding: 5px;}
    .gallery-list li .panel-body { padding: 5px;}
    .gallery-list li .panel{margin-bottom: 0}
    .hmenu_actions { right: 0; transform: none; top: 5px;}
    .hcart_dropdown { width: 100%;  position: fixed;}
    .product-details-lhs{width: 100%; padding: 10px;}
    .product-details-rhs{width: 100%; padding: 0px 10px 20px 10px;}
    .outlet-item-img{width: 100%}
    .outlet-item-list{width: 100%; padding: 20px 0 0 0;}
    .outlet-item-img-outlet{width: 100%}
    .outlet-item-list-outlet{width: 100%; padding: 20px 0 0 0;}    
}
@media (max-width: 720px){
    .kaki-member{width: 100%;}
    .kaki-down{width: 100%;}
    .grid-btn{width: 84%}
    .hand-text h4{font-size: 18px;}
}
@media (max-width: 680px){
.home-content-inner ul li a img { margin-right: 0;}
.home-content-inner ul li a{font-size: 17px;min-height: 80px;flex-flow: column;}  
.home-content-inner ul li a div { margin-bottom: 5px;}    
.copyright-section { padding: 10px 10px;}    
.copyright-section p { font-size: 13px;}    
.mobile-logo-div{max-width: 160px}
.top-info{flex-wrap: wrap;padding: 0; background: #fff; box-shadow: none;}
.top-info-lhs { width: 100%;    margin-bottom: 20px;}
.top-info-rhs { width: 100%;}
.top-info-points h2{font-size: 22px; }    
.inner-page { padding: 30px 0 30px;}    
.cart-header-first .row-replace .col-sm-cls { width: 100%;  text-align: center;  padding: 0 0 10px 0;}    
}

@media (max-width: 580px){
.home-banner-universal .slick-slide img {height: 250px; width: 100%; object-fit: cover;}
.home-banner-universal .slick-dots li button { height: 9px;width: 9px; margin: 0 3px;}    
.home-banner-universal .slick-dots{bottom: 10px;}    
.header-bottom{height: 60px;}
.menu_icon .icon-bar+.icon-bar{margin-top: 6px;}
.promo-folio-content ul{justify-content: space-between}
.promo-folio-content > ul > li{ width: 48.5%;margin: 0 0 15px 0; }
.page-banner h2 {   font-size: 22px;}
.promo-folio-tab .filter_tabsec{margin: 0 0 20px; }
.promo-folio-tab-select select{width: 100%; margin: 0 auto;}    
.promo-folio-tab .filter_tabsec .nav-tabs{margin: 0 0 20px 0}
.cart-header-first p{font-size: 14px;}    
.cart-header-first h4{font-size: 16px;}
.cart_price p{font-size: 16px;}
.cart_row.product-details .cart_img{width: 60px;}
.cart_row.product-details .cart_info { width: calc( 100% - 70px );}
.hsearch_sec { top: 60px;position: fixed;}    
.innerproduct .container-one > h3 { font-size: 28px;  margin-bottom: 20px;}   
.innerproducts-list-maindiv{justify-content: space-between;}   
.promo-form .applyBtn { position: relative; font-size: 15px; background: #e36410; padding: 12px 17px; min-width: 128px;height: auto;margin: 10px auto 0}    
.promo-form input[type="text"]{ padding: 10px 20px;}    
    
}
@media (max-width: 480px){
.home-content-inner ul li a { padding: 12px 20px; font-size: 14px; line-height: 1;}
.hci-four img, .hci-one img, .hci-three img, .hci-two img { width: 44px; height: 42px;}
.footer-newletter { width: 100%; text-align: center;}    
.footer-nav { width: 100%;}    
.footer-newletter h5{justify-content: center;margin-bottom:15px}    
.ft-tp-col3 { padding-top: 10px;}
.footer-logo{margin-bottom: 30px;}
.mobile_account_menu_click{padding: 12px 20px 12px 20px}
.promo-folio-tab .filter_tabsec .nav-tabs>li>a { font-size: 15px;}
    .merchants-tab .filter_tabsec .nav-tabs>li>a{font-size: 12px;}    
    .merchant-info h4{font-size: 15px;}    
    .merchant-info .button { padding: 10px 20px; font-size: 13px;}
    .merchant-info { padding: 15px 10px 10px;}
    .gallery-list li{width: 50%;}
    .mobile-logo-div { max-width: 120px;}
    .hcartdd_trigger img{width: 22px; height: 24px;}
    .hsearch_trigger > img { width: 22px;}
    .htico_search { margin-left: 10px !important;}
    .hmenu_actions > li.htico_cart {margin-left: 10px;}
    .htico_search> a{padding: 12px 0;}
    .banner-text{padding: 15px;}
    .kd-top h3, .hand-text h3 { font-size: 36px;}
    .kd-top img{width: 30px;}
    .kd-top { padding: 0 0 0 42px; min-height: 60px;}
    .grid-btn{width: 100%}
    .grid-btn .button{font-weight: 500;}
}







