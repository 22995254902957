
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Teko:wght@500&display=swap');


@font-face {
    font-family: 'Honey Candy';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Honey Candy.woff') format('woff');
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

html {
    -webkit-text-size-adjust: none;
}

:focus {
    outline: 0;
}

ol,
ul {
    margin: 0;
    padding: 0px 0px 10px 40px;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

embed,
iframe,
object {
    max-width: 100%;
}

body {
    color: #000;
    word-wrap: break-word;
    font-size: 15px;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif; 
    background: url(../images/vintage-background.jpg) repeat;
}

body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: #d45d38;
    outline: 0px solid slategrey;
}

cite,
em,
i {
    font-style: italic;
}

input,
textarea {
    -webkit-border-radius: 0px;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
    padding: 12px 25px;
    border: 1px solid #d0cfcb;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius: 0px;
    height: 48px;
    font-size: 15px;
    color: #555555;
    font-family: 'Poppins', sans-serif;
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    color: #fff;
    display: inline-block;
    border-radius: 0px;
    margin: 15px 0px 10px 0px;
    padding: 13px 26px 13px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    background: #000;
    border: 0px;
    font-size: 15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2; font-weight: 400; border-radius: 5px;
}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.button:hover,
button:hover {
    color: #fff;
    background: #e87b1e;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

a {
    color: #e87b1e;
    text-decoration: none;
    cursor: pointer;
}

a:focus,
a:active,
a:hover {
    text-decoration: none;
}

a:hover {
    color: #000
}

figure {
    margin: 0;
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    font-weight: 500;
    line-height: 1.1;
    color: #000;
}

h1 {
    font-size: 50px;
    font-family: 'Poppins', sans-serif; 
}

h2 {
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
}

h3 {
    font-size: 28px;
    font-family: 'Poppins', sans-serif; 
}

h4 {
    font-size: 24px;
    font-family: 'Poppins', sans-serif; 
}

h5 {
    font-size: 18px;
    font-family: 'Poppins', sans-serif; 
}

h6 {
    font-size: 16px;
    font-family: 'Poppins', sans-serif; 
}

.show {
    display: block;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.fl {
    float: left
}

.fr {
    float: right
}

.rel {
    position: relative
}

.textcenter {
    text-align: center
}

.textleft {
    text-align: left
}

.textright {
    text-align: right
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.clear,
.clearfix {
    clear: both;
}

.clearfix:after {
    clear: both;
    display: table;
    content: ""
}

strong,
b {
    font-weight: 700;
}

.required_star {
    color: #f00;
}

.container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
}

.slick-slider,
.slick-slider * {
    outline: none;
}

p {
    margin: 0 0 15px;
}

.title_sec {
    margin: 0 0 45px;
    color: #040507;
}

.title_sec h2 {
    font-size: 44px;
    line-height: 1;
    margin: 0 0 5px;
}

.title_sec small {
    font-size: 16px;
    display: block;
    font-weight: normal;
    margin: 0 0 13px;
}

.title1 {
    font-size: 32px;
    color: #040507;
    line-height: 1;
    margin: 0 0 24px;
}

.title3 {
    font-size: 20px;
    color: #040507;
    line-height: 1;
}

.title4 {
    font-size: 18px;
    color: #010101;
}

.icon-cap {
    background: url(../images/icon-cap.png) no-repeat;
    width: 34px;
    height: 31px;
    display: block;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
    outline: none;
    color: #fff;
}

.btn {
    display: block;
    padding: 10px 20px;
    color: #fff;
    background: #000;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px;
    font-size: 14px;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    border-radius: 5px; text-align: center;
}

.btn:hover {
    color: #fff;
    background: #e26024;
}

.btn_minwid {
    min-width: 150px;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-sm {
    height: 42px;
    padding: 8px 15px;
    font-size: 16px;
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table_overflow {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.table_overflow .table {
    margin-bottom: 0;
}

.dropdown-menu {}

.dropdown-menu>li>a {
    padding: 7px 20px;
    border-top: 1px solid #e9ebf5;
}

.dropdown-menu>li:first-child>a {
    border-top: 0;
}

.form_grouptt {
    font-size: 15px;
    margin: 0 0 20px 10px;
    color: #000;
    font-weight: bold;
}

.form-group {
    margin: 0 0 8px;
}

.form_sec .row {
    margin-left: -4px;
    margin-right: -4px;
}

.form_sec [class*="col-"] {
    padding: 0 4px;
}

label {
    font-weight: 500;
    font-size: 15px;
    color: #615f5f;
}

label span {
    color: #f00;
    margin-left: 3px;
}

.manitatory-select .Select-placeholder:after {
    content: '*';
    color: red;
}

.form-control {
    height: 48px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 6px 18px;
    background: #fff;
    font-size: 15px;
    border-radius: 0;
    color: #615f5f;
    width: 100%;
    display: block
}

textarea.form-control {
    height: 160px;
}

textarea.form-control.sm {
    height: 90px;
}

.form-control.error,
.Select-control.error {
    border-color: #FFBABA;
    color: #686a78;
}

.form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #5d8d87;
}

.focus-out {
    position: relative;
}

.focus-out label {
    position: absolute;
    left: 18px;
    top: 14px;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    height: 24px;
    overflow: hidden;
    color: #595656;
}

.focused label {
    padding: 0 6px;
    font-size: 11px;
    background: #fff;
    top: -7px;
    height: auto;
}

.clock_icon,
.ileft_ico {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 100%;
}

.clock_icon img,
.ileft_ico img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
}

.insta{
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)!important;
}
.profile-info-div {
    margin: 15px 0 0px;
}

.other-add {
    margin: 30px 0 0 0
}

.acc-inform .form-group .row:after,
.filter_tabsec .nav-tabs:after {
    display: table;
    clear: both;
    content: ""
}

.acc-inform .form-group .row .col-md-6 {
    width: 49%;
    float: left;
    margin: 0 2% 0 0;
}

.acc-inform .form-group .row .col-md-6:last-of-type {
    margin-right: 0;
}

.re_select input[type="text"] {
    margin: 0;
    height: 34px;
}

.acc-inform .form-group .row .button {
    width: 100%;
    display: block;
    margin: 0
}

.re_select .Select-control {
    border: 1px solid #e2e2e2;
    border-radius: 0;
    height: 47px;
    outline: 0;
}

.re_select .Select-placeholder,
.re_select .Select--single > .Select-control .Select-value {
    padding: 0 22px;
    line-height: 45px;
    text-align: left;
    color: #72624f;
}

.re_select .Select-input {
    padding: 0 20px;
    height: 45px;
}

.re_select .Select-input > input {
    line-height: 27px;
    padding: 10px 0;
}

.re_select .Select.is-open > .Select-control {
    border-color: #d5d7e0;
}

.re_select .Select-arrow {
    background: url(../images/arrow-down.png) no-repeat center;
    border: 0;
    width: 18px;
    height: 10px;
}

.re_select .is-open .Select-arrow {
    background-image: url(../images/arrow-up.png);
}

.re_select .Select-menu-outer {
    z-index: 4;
    text-align: left;
}

.re_select .Select-menu-outer {
    background: #ecdfcc;
}

.re_select .Select-arrow-zone {
    padding: 0;
    width: 42px;
}

.re_select .Select--multi .Select-value {
    background-color: #ecdfcc;
    border-color: #dcd8d8;
    color: #181818;
    position: relative;
    padding-right: 19px;
    border-radius: 10px;
    overflow: hidden;
}

.re_select .Select--multi .Select-value-icon {
    border-color: #dcd8d8;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.re_select .Select--multi .Select-value-icon:hover,
.re_select .Select--multi .Select-value-icon:focus {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
    color: #fff;
}

.re_select .Select--multi .Select-value-icon:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
    font-size: 0;
}

.re_select .Select--multi .Select-value-label {
    padding: 2px 5px 2px 9px;
}

.custom_select {
    position: relative;
}

.custom_select:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 46px
}

.react-datepicker {
    font-family: 'Poppins', sans-serif !important;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
    display: block;
}

.react_datepicker .react-datepicker {
    font-size: 1rem;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
    font-size: 1.2rem;
    
    font-weight: normal
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
    width: 120px;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #d45d38 !important
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #fb9f0e !important;
}

.react-datepicker__header {
    background-color: #e5dbc9 !important;
}

.react-datepicker__navigation {
    margin: 0
}

.react-datepicker__navigation--previous {
    border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
    border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}

.custom_checkbox {
    position: relative;
    display: inline-block;
    line-height: 25px;
}

.custom_checkbox input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.custom_checkbox input[type=checkbox]:checked + span:before {
    content: "";
    text-rendering: auto;
    text-align: center;
    border-color: transparent;
    line-height: 23px;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -455px -122px;
}

.custom_checkbox .disableactive + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: #ff8400;
    text-align: center;
    text-align: center;
    border-color: #ff8400;
}

.custom_checkbox span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 28px;
    height: 28px;
    border: 1px solid #ecdfcc;
    left: 0;
    top: -3px;
    pointer-events: none;
    line-height: 21px;
    border-radius: 50%;
}

.custom_checkbox span {
    position: relative;
    padding: 0 0 0 40px;
    pointer-events: none;
    color: #3d3d3d;
    display: block;
}

.custom_radio {
    position: relative;
    display: inline-block;
}

.custom_radio input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.custom_radio input[type=radio]:checked + span:before {
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -399px -125px;
    z-index: 1;
    border: 1px solid transparent;
}

.custom_radio input[type=radio]:checked + span:after {
    content: "";
    background: #ff8400;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.custom_radio span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 1px solid #d0cfcb;
    left: 0;
    top: 1px;
    pointer-events: none;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.custom_radio span {
    position: relative;
    padding: 0 0 0 35px;
    pointer-events: none;
    color: #202963;
    display: block;
}

.ckbox_custmze {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ckbox_custmze input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: -12px;
    left: 0;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 1px solid #ecdfcc;
}

.ckbox_custmze:hover input ~ .checkmark {
    background-color: #fff;
}

.ckbox_custmze input:checked ~ .checkmark {
    background-color: #72624f;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.ckbox_custmze input:checked ~ .checkmark:after {
    display: block;
}

.ckbox_custmze .checkmark:after {
    left: 10px;
    top: 5px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.error {
    color: #f00;
    font-weight: normal;
}

span.error {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 25px;
    font-size: 13px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
}

span.error:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

.alert {
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    display: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.alert_fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    max-width: 1260px;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.alert_success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert_danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert_fixed p {
    margin: 0;
}

.alert_fixed a {
    font-size: 24px;
    right: 20px;
    top: 5px;
    position: absolute
}

.display_inblock {
    display: inline-block;
}

.display_block {
    display: block;
}

.relative {
    position: relative;
}

.vtop {
    vertical-align: top;
}

.vmiddle {
    vertical-align: middle;
}

.vbottom {
    vertical-align: bottom;
}

.vmid_sec:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.vmid_in {
    display: inline-block;
    vertical-align: middle;
    width: 98%;
}

.tab_mobtrigger {
    display: none;
}

.main_tabsec {
    margin: 0 0 10px;
}

.main_tabsec > .nav-tabs {
    border: 0;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-radius: 50px;
    margin: 0 0 20px;
}

.main_tabsec > .nav-tabs>li {
    margin: 0;
    display: table-cell;
    float: none;
    text-align: center;
    width: 50%;
    vertical-align: middle;
    border-left: 2px solid transparent;
}

.main_tabsec > .nav-tabs>li:first-child {
    border-left: 0;
}

.main_tabsec > .nav-tabs>li>a {
    background: #b69a6b;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 15px;
}

.main_tabsec > .nav>li>a:focus {
    background: none;
}

.main_tabsec > .nav-tabs>li.active>a,
.main_tabsec .nav-tabs>li.active>a:focus,
.main_tabsec .nav-tabs>li>a:hover {
    border: 0;
    background: #0b0405;
    color: #fff;
}

.main_tabsec > .nav-tabs>li>a:after {
    content: "";
    border: 18px solid #0b0405;
    border-color: #0b0405 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -36px;
    margin-left: -18px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.main_tabsec .nav-tabs>li.active>a:after {
    opacity: 1;
}
.filter_tabsec {
    margin: 0 0 40px;
}

.filter_tabsec .nav-tabs {
    margin: 0 0 30px;
}

.filter_tabsec .nav-tabs>li {
    width: 17%;
    margin: 0;
    display: inline-block;
    float: none;
    text-align: center;
    vertical-align: middle;
}

.filter_tabsec .nav-tabs>li:first-child {
    border-left: 0;
}

.filter_tabsec .nav-tabs>li>a {
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #272626;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 0 5px;
    display: block;
    
}

.filter_tabsec .nav>li>a:focus {
    background: none;
}

.filter_tabsec .nav-tabs>li:hover>a,
.filter_tabsec .nav-tabs>li.active>a,
.filter_tabsec .nav-tabs>li.active>a:focus,
.filter_tabsec .nav-tabs>li.active>a:hover {
    border: 0;
    background: none;
    color: #060d15;
}

.filter_tabsec .nav-tabs>li>a:after {
    content: "";
    background: #5796c9;
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.filter_tabsec .nav-tabs>li:hover>a:after,
.filter_tabsec .nav-tabs>li.active>a:after {
    opacity: 1;
}

.modal-dialog {
    margin: .5rem;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
    min-height: -webkit-calc(100% - (.5rem * 2));
}

.modal-content {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 14px;
}

.poup_wrap {
    width: 660px;
}

.popup_in {
    padding: 28px 60px 54px;
}

.close_popup {
    font-size: 38px;
    font-weight: normal;
    line-height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 32px;
    width: 32px;
    color: #231f20;
    text-align: center;
}

.chosen-container .chosen-results .highlighted {
    background: #ecdfcc !important;
}

button.mfp-close:hover,
button.mfp-arrow:hover {
    background: none;
}

.tgl {
    display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
    background: none;
}

.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 45px;
    height: 22px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.tgl + .tgl-btn:after {
    left: 1px;
}

.tgl + .tgl-btn:before {
    display: none;
}

.tgl:checked + .tgl-btn:after {
    left: 50%;
}

ul.tg-list {
    float: left;
    padding-top: 7px;
    list-style: none;
    padding-left: 0;
}

.tgl-light + .tgl-btn {
    background: #f9f9f9;
    padding: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 2em;
}

.tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #b4b4b4;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 22px;
    height: 22px;
}

.tgl-light:checked + .tgl-btn:after {
    background: #4dc93c;
}

.desktop_hmenu_list .submenu-arow {
    display: none
}

.mobile_hmenu_list .submenu-arow {
    display: block;
    width: 40px;
    background: #e6e6e6;
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
}

.submenu-arow:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: 50%;
    line-height: 1;
    margin-top: -11px;
    font-size: 22px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    display: block !important;
}

.open-submenu .submenu-arow:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block !important;
}

.hsearch_sec {
    width: 480px;
    position: absolute;
    right: -10px;
    top: 100%;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
}

.hsearch_sec.open {
    opacity: 1;
    visibility: visible;
    top: 54px;
    pointer-events: auto;
}

.hsearch_bx {
    position: relative;
}

.hsearch_bx .form-control {
    height: 48px;
    border: 0;
}

.hsearch_bx .btn {
    background: #ecdfcc url(../images/search.png) no-repeat center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65px;
    border-radius: 0;
    margin: 0;
    height: auto;
    padding: 0;
}

.hsearch_bx .btn:hover {
    background-color: #1c1c1c;
}

.hsearch_bx .re_select .Select-control {
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    overflow: visible;
    height: 54px;
}

.hsearch_bx .re_select .Select-arrow {
    opacity: 0;
}

.hsearch_bx .re_select .Select-placeholder {
    line-height: 52px;
    padding: 0 26px;
    color: #abaaa8;
}

.hsearch_bx .re_select .Select-input {
    height: 54px;
    padding: 0 26px;
    float: left;
}

.hsearch_bx .re_select .Select-input > input {
    line-height: 34px;
}

.hsearch_trigger_img img {
    width: 26px
}
.hsearch_trigger > img{ width: 25px;  }
.hsearch_trigger_img {
    margin: 5px 0 0 10px;
}

.hmenu_sec ul {
    padding: 0;
    list-style: none;
    text-align: initial;
}

.hmenu_list {
    margin: 0;
}

.hmenu_list > li {
    display: inline-flex;
    margin-right: 25px;
}

.hmenu_list > li:hover > a,
.hmenu_list > li.active > a {
    color: #e87b1e;
}

.hmenu_list > li:hover > a:after,
.hmenu_list > li.active > a:after {
  transform: scaleX(1);
}

.hmenu_list > li > a > span {
    position: relative;
}

.hmenu_actions > li {
    margin: 0 0 0 10px;
    list-style: none;
    float: left;
}

.hordertype_sec a {
     display: block;
    padding: 15px 22px 15px 22px;
    color: #fff;
    background: #e36410;
    text-transform: uppercase;
    font-family: "Poppins",sans-serif;
    position: relative;
    letter-spacing: .5px;
        margin: 0 15px 0 5px;
    line-height: 1;
    font-weight: 600;
    border-radius: 6px;
    white-space: nowrap;
}

.hordertype_sec a:hover {
    color: #fff;
    background: #000;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list {
    margin: 0;
    padding: 0px;
    background: #e87b1e;
    z-index: 99;
    width: 220px;
    position: absolute;
    left: 0px;
    top: 42px;
    display: none;
}

.hmenu_list.desktop_hmenu_list > li:hover > ul,
.hmenu_list.desktop_hmenu_list > li ul li:hover>ul {
    display: block;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a {
    
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078)
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {
    color: #fff;
    background: #e87b1e;
}

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
    display: none;
    background: #f7f7f7
}

.mobile_hmenu_list ul.submenu_list li a {
    display: block;
    padding: 10px 15px;
    
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #e4e4e4;
}

.mobile_hmenu_list ul.submenu_list li a:hover {
    color: #d45d38;
}

.hsign_sec > a {
    display: inline-block;
    position: relative;
}

.hsign_sec > a:hover {
    color: #000;
}


.hsearch_sec .search_result {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #f7f2ea;
    max-height: 300px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    display: none
}

.search_result li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.090);
}

.search_result li a,
.no-pro-found {
    color: #333;
    display: block;
    padding: 7px 15px
}

.search_result li a:hover {
    background: #e87b1e;
    color: #fff;
}

.hsearch_sec.open .input-sec {
    position: relative
}

.hsearch_sec.open .input-sec input {
    padding-right: 40px;
    margin-bottom: 0
}

.hsearch_sec.open .input-sec:after,
.hsearch_sec.open .input-sec:before {
    bottom: 98%;
    right: 60px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.hsearch_sec.open .input-sec:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}

.hsearch_sec.open .input-sec:before {
    border-color: rgba(208, 207, 203, 0);
    border-bottom-color: #d0cfcb;
    border-width: 9px;
    margin-left: -9px;
}

.search_text_clear {
    position: absolute;
    right: 10px;
    top: 15px;
    display: none
}

.htico_cart > a {
    position: relative;
    display: block;
}
.hmenu_actions > li.htico_cart{ margin-left: 15px; }

.hcartdd_trigger.active:before {
    opacity: 1;
    visibility: visible;
}

.hcart_dropdown {
    background: #fff;
    right: 0;
    width: 680px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 11;
    text-align: left;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.3);
}

.hcart_dropdown.open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.hcart_tt {
    background: #060203;
    padding: 15px 10px 15px 20px;
    color: #fff;
}

.hcart_tt h3 {
    margin: 0;
    font-size: 18px;
    color: #fff
}

.hcart_tt h3 a:hover {
    color: #fff;
}

.hclear_cart {
    color: #fff;
}

.hcart_scrollarea {
    max-height: 400px;
    overflow-y: auto;
}

.cart_body {
    padding: 20px 20px;
}

.cart_row {
    padding: 0px;
    position: relative;
}

.cart_img {
    width: 100px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    overflow: hidden;
    background: #fff;
    padding: 3px
}

.cart_img img {
    width: 100%;
    max-height: 80px;
    -o-object-fit: cover;
    object-fit: cover
}

.cart_info {
    width: calc(100% - 125px);
    width: -webkit-calc(100% - 125px);
    display: inline-block;
    vertical-align: top;
    color: #3d3d3d;
}

.checkout-right-body-section .cart_info p{
    margin-bottom:8px
}

.cart_info h4 {
    font-size: 18px;
    margin: 0 0 3px;
    display:  flex;
    color: #3d3d3d;
    
}

.cart_left .help-block {
    font-style: italic;
    color: #a7a4a4;
    margin: 5px 0 0;
    font-size: 13px;
}

.cart_extrainfo {
    color: #3d3d3d;
}

.cart_extrainfo p b {
    color: #3d3d3d;
    font-style: normal;
    
}

.cart_extrainfo p {
    margin: 0;
    color: #777777;
    font-style: italic;
    font-size: 16px;
}

.cart_price {
    position: relative;
    margin: 0
}

.cart_price p {
    margin: 0;
    color: #3d3d3d;
    font-size: 19px;
    
}

.cart_remove {
    background: #b0b0b0 url(../images/cross1.png) no-repeat center;
    position: absolute;
    top: 19px;
    right: 10px;
    width: 21px;
    height: 21px;
}

.cart_remove:hover {
    background-color: #000;
    background-image: url(../images/cross1.png);
}

.qty_bx {
    width: 126px;
    height: 36px;
    position: relative;
    padding: 0 40px;
    display: inline-block;
}

.qty_bx span {
    position: absolute;
    width: 36px;
    height: 100%;
    display: block;
    top: 0%;
    bottom: 0;
    text-indent: 9999px;
    overflow: hidden;
    cursor: pointer;
    margin: auto;
    background: #fff;
    border-radius: 5px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    border: 2px solid #e87b1e;
}

.qty_bx span.qty_minus {
    left: 0px;
}

.qty_bx span.qty_plus {
    right: 0px;
}

.qty_bx input {
    height: 100%;
    border: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #060203;
    border-radius: 5px;
}

.qty_bx span:after {
    position: absolute;
    width: 15px;
    height: 15px;
    display: block;
    top: 50%;
    margin-top: -7px;
    content: "";
}

.qty_bx span.qty_minus:after {
    left: 9px;
    background: url(../images/minus.png) no-repeat;
    height: 1px;
    margin-top: -1px;
}

.pkglist_pricebx .qty_bx span.qty_minus:after {
    height: 2px;
}

.qty_bx span.qty_plus:after {
    right: 8px;
    background: url(../images/plus.png) no-repeat;
}

.cart_footer .cart_row {
    padding-top: 0;
    border: 0;
}

.cart_footer span {
    font-size: 16px;
    font-weight: 700;
}

.discount_info {
    margin-left: 15px;
}

.discount_info p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #3d3d3d;
}

.promo_delico_parent {
    position: relative;
}

.promo_delico {
    position: absolute;
    top: 0;
    left: -21px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    color: #3d3d3d;
}

.promo_delico:hover {
    background-color: #b69a6b;
    color: #fff;
}

.cart_footer_totrow p {
    font-size: 25px;
}

.cart_footer_totrow p.help-block {
    font-size: 12px;
    font-size: 14px;
    font-weight: 300;
}

.cart_footer_totrow span {
    font-size: 25px;
}

.indication_progress {
    height: 16px;
    overflow: hidden;
    border-radius: 10px;
    background: #424242;
    position: relative
}

.progress_bar {
    content: "";
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    background: #ccc;
    border-radius: 10px;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.indication .help-block {
    color: #e87b1e;
    font-size: 14px;
    font-weight: 300;
    margin: 7px 0 0; font-weight: 600;
}

.delivery_info {
    background: #f5f5f5;
    padding: 24px 35px;
}

.delivery_address {
    margin: 0 0 20px;
}

.delivery_info h4 {
    color: #838181;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
}

.delivery_info p {
    color: #3d3d3d;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}

.delivery_link {
    color: #3d3d3d;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 10px 0 0 0;
    display: inline-block;
    vertical-align: top;
}

.delivery_link:hover {
    border-color: #b69a6b;
}

.delivery_time {
    margin: 0 0 16px;
}

.delivery_handle {
    border-top: 1px solid #d3d1d1;
    padding: 20px 0 0 0
}

.cart_action .btn_grey {
    margin: 0;
    border-color: #e6e3e3;
}

.download_btn i {
    margin-right: 9px;
    vertical-align: top;
    display: inline-block;
}

.ico_download {
    width: 24px;
    height: 22px;
    background: url(../images/download.png) no-repeat;
}

.download_btn:hover .ico_download {
    background-image: url(../images/download1.png);
}

.cartaction_price {
    font-size: 24px;
    width: 30%;
    cursor: default;
}

.cartaction_price sup {
    font-size: 50%;
    left: -4px;
    top: -7px;
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.cart_action {
    padding: 20px
}

.header_top {
    background: #181818;
    position: relative;
}

.htopinfo {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    padding: 10px 0;
}

.htopinfo p {
    margin: 0;
}

.pop-alert-icon {
    padding: 0 0 15px;
}

.header_bot {
    position: relative;
}

.sticky .alert_fixed {
    top: 95px;
}

.scrollToTop {
    background: rgba(0, 0, 0, 0.7);
    width: 40px;
    height: 40px;
    line-height: 36px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    font-size: 24px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    z-index: 1;
}

.scrollToTop:hover,
.scrollToTop:focus {
    background: rgba(0, 0, 0, 1);
    color: #fff;
}

.viewmore_poplink {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.hbslider_sec h2 {
    font-size: 24px;
    color: #363636;
}

.hbslider_sec .slick-dots {
    position: absolute;
    bottom: 15px;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.hbslider_sec .slick-dots li {
    display: inline-block;
    margin: 0 4px;
}

.hbslider_sec .slick-dots li button {
    background: none;
    border: 1px solid #fff;
    padding: 0;
    border-radius: 50%;
    font-size: 0;
    width: 12px;
    height: 12px;
}

.hbslider_sec .slick-dots li.slick-active button {
    background: #ecdfcc;
}

.hbslider_sec .slick-arrow {
    z-index: 1;
}

.hbslider_sec .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hbslider_sec .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -188px -60px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hbslider_sec .slick-next:hover {
    background: #f5f5f5;
}

.hbslider_sec .slick-next:hover::after {
    background-position: -78px -60px;
}

.hbslider_sec .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hbslider_sec .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -136px -61px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hbslider_sec .slick-prev:hover {
    background: #f5f5f5;
}

.hbslider_sec .slick-prev:hover::after {
    background-position: -26px -61px;
}

.white-popup .hbslider_sec .hbslide {
    text-align: center;
}

.bakery_inner {
    padding-bottom: 15px;
}

.hme-product-inner {
    padding: 50px 50px;
    background: #fff;
    margin: 25px 0 0 0;
}

.hme-product-inner .filter_tabsec .nav-tabs {
    margin-bottom: 64px;
    border-bottom: 0;
}

.hme-product-inner .title_sec {
    margin-bottom: 25px;
}

.hme-product-inner .ttsplitter {
    margin-bottom: 25px;
}

.hme-prt-row .slick-slider {
    padding: 0 90px;
}

.hme-prt-column {
    position: relative;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.hme-product-inner .slick-slide {
    padding: 0 10px;
    outline: 0;
}

.hme-product-inner .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hme-product-inner .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -78px -60px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hme-product-inner .slick-next:hover {
    background: #f5f5f5;
}

.hme-product-inner .slick-next:hover::after {
    background-position: -188px -60px;
}

.hme-product-inner .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hme-product-inner .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -26px -61px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hme-product-inner .slick-prev:hover {
    background: #f5f5f5;
}

.hme-product-inner .slick-prev:hover::after {
    background-position: -136px -61px;
}

.hme-prt-column {
    position: relative;
}

.hme-prt-innerslide {
    position: relative;
}

.hme-prt-inner {
    position: relative;
}

.hme-prt-slidecontent {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    background: rgba(255, 255, 255, 0.86);
    opacity: 0;
    padding: 15px;
}

.hme-prt-hei {
    text-align: center;
}

.hme-prt-hei img {
    max-height: 100%;
    width: auto;
    height: auto;
}

.hme-prt-column:hover .hme-prt-slidecontent {
    opacity: 1;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.hme-prt-slidecontent-inner {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    position: relative;
    text-align: center;
}

.hme-prt-slidecontent-inner h3 {
    font-size: 19px;
    margin-bottom: 3px;
    color: #556c11;
    height: 27px;
    overflow: hidden;
}

.hme-prt-slidecontent-inner span {
    font-size: 19px;
    color: #556c11;
}

.hme-prt-slidecontent-inner p {
    color: #556c11;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 23px;
    height: 40px;
    overflow: hidden;
    padding: 0 10px;
}

.outlet {
    position: relative;
}

.outlet_inner {
    padding: 63px 35px 76px 35px;
    background: #fff;
    margin: 25px 0 39px 0;
}

.bread_space {
    margin: 40px 0 0;
}

.breadcrumb {
    margin-bottom: 0;
    padding: 30px 0 0 0;
}

.breadcrumb_inner ul {
    list-style: none;
}

.breadcrumb_left {
    float: left;
    width: 50%;
}

.breadcrumb_left ul {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.breadcrumb_right {
    float: right;
    width: 50%;
    text-align: right;
    position: relative;
}

.breadcrumb_right .chosen_left {
    position: absolute;
    top: 50%;
    left: 0;
    text-align: right;
    right: 170px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.breadcrumb_right .re_select {
    max-width: 170px;
    float: right;
    width: 100%;
}

.breadcrumb_right .re_select {
    max-width: 170px;
}

.breadcrumb_right .re_select .Select-clear-zone {
    display: none;
}

.breadcrumb_inner ul p {
    margin-bottom: 0;
    font-weight: 700;
}

.breadcrumb_inner ul p a {
    display: inline-block;
    color: #5b5b5b;
}

.breadcrumb_inner ul p a:hover {
    color: #000;
}

.breadcrumb_inner ul p i {
    padding: 0 10px;
    display: inline-block
}

.breadcrumb_right .form-group {
    margin-bottom: 0;
}

.breadcrumb_right .chosen_left span {
    display: inline-block;
    text-transform: uppercase;
    font-size: 14px;
    color: #5b5b5b;
    vertical-align: middle;
    font-weight: 700;
    padding-right: 15px;
}

.breadcrumb_right .chosen-container {
    display: inline-block;
    text-align: left;
}

.breadcrumb_right .chosen-container-single .chosen-single {
    width: 170px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #ecdfcc;
}

.breadcrumb_right .chosen-container-single .chosen-single span {
    text-transform: capitalize;
}

.breadcrumb_right .chosen-container-single .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    background-position: -205px -24px;
    width: 14px;
    height: 8px;
}

.breadcrumb_right .chosen-container-active.chosen-with-drop .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    width: 14px;
    height: 8px;
    background-position: -236px -24px;
}

.breadcrumb_right .chosen-container-single .chosen-single div {
    top: 15px;
    right: -17px;
}

.breadcrumb_right .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.bakery {
    position: relative;
}

.bakery_inner h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.bakery_inner p {
    color: #5b5b5b;
}

.bakery_slider {
    position: inherit;
    left: 0;
    right: 0;
    top: 0;
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
}

.bakery_slider .slick-slider {
    padding: 0;
}

.bakery_slider.below_three {
    display: table;
    width: 100%;
}

.bakery_slider.below_three > .bakery_slide {
    display: table-cell;
}

.bakery_slider.below_three > .bakery_slide:first-child {
    border-left: 1px solid #ecdfcc;
}

.bakery_slide p {
    margin-bottom: 0; 
}

.bakery_row .bakery_slide.active a {
    border-color: #e77b1d;
}
.bakery_slide p a:hover {
    color: #fff;
}

.bakery_row .slick-next {
    font-size: 0;
    border: 0;
    outline: 0;
    position: absolute;
    background: #fff;
    right: 0px;
    top: 0;
    width: 48px;
    height: 44px;
    border-left: 1px solid #ecdfcc;
}

.bakery_slider .slick-list {
    padding: 0 1px;
}

.bakery_row .slick-prev {
    font-size: 0;
    border: 0;
    outline: 0;
    position: absolute;
    background: #fff;
    left: 0;
    top: 0;
    width: 48px;
    height: 44px;
    cursor: pointer;
    margin: 0
}

.innerproduct {
    margin: 27px 0 20px 0;
}

.innerproduct:last-of-type {
    margin-bottom: 14px;
}

.innerproduct .container-one > h3 {
    font-size: 35px;
    margin-bottom: 25px;
    outline: none;
    text-transform: capitalize;
    font-family: 'Honey Candy';
    text-transform: uppercase;
    text-align: center;
}

.innerproduct-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.innerproduct-col {
    border: 1px solid #fff;
    cursor: pointer;
}
.voucher-popup-inner{
    background: #fff;
    padding: 45px 55px 0px 55px;
    position: relative;
    z-index: 9;
    margin: 0 49px 0 0;
    width: 100%;
    transition: 0.4s linear all;
}

 
.addcart_row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 5px;
    max-width: 320px;
    margin: 0 auto;
}

.addcart_row.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.addcart_row .qty_bx {
    width: 56%;
    vertical-align: middle; float: left;
}

.special_product {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    display: inline-block;
    width: 26px;
    height: 23px;
}

.special_product::after {
    position: absolute;
    content: "";
    top: 0;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -458px -19px;
    z-index: 1;
    width: 20px;
    height: 20px;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
}

.innerproduct-item {
    position: relative;
}

.innerproduct-item-image {
    background: #fff;
    text-align: center;
}


.innerproduct-item-hei {
    height: 255px;
    line-height: 255px;
    text-align: center;
}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.86);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
    opacity: 1;
}

/*.innerproduct-col:hover .innerproduct-button,
.innerproduct-col.open .innerproduct-button {
    opacity: 1;
    visibility: visible;
    top: 50%;
}*/

.innerproduct-item-text {
    text-align: center; position: relative; padding: 0;
}

.innerproduct-item-text p {
    font-weight: 300;
}

.innerproduct-item-text p a {
    color: #5b5b5b;
}

.innerproduct-item-text h5 {
    font-size: 20px;
    color: #363636;
    margin-bottom: 0;
}

.innerproduct-item-text h5 a {
    color: #363636;
}

.price_none {
    display: inline-block;
    font-size: 13px;
    font-weight: 300;
    color: #999;
    padding-right: 10px;
}

.price_offer .price_none {
    width: 50%;
    text-align: left;
}

.price_offer .price_nor {
    width: 50%;
    text-align: right;
    display: inline-block;
    margin-left: -4px;
}

.inn-product-popup {
    -webkit-box-shadow: 0px 2px 10px 0px rgba(2, 2, 2, 0.15);
    box-shadow: 0px 2px 10px 0px rgba(2, 2, 2, 0.15);
    border: 1px solid #d4d4d4;
    margin: 20px auto 0 auto;
    background: #fff;
}

.inn-product-popup .container {
    padding: 0;
}

.prodet_bansec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.prodet_banleft {
    width: 47%;
}

.prodet_baninfo {
    width: 100%;
    padding:0 0px 20px 0px;
    position: relative
}

.prodet_baninfo .product-tags-list {
    position: relative;
    bottom: inherit;
    float: none;
    margin: 0 0 20px 0
}

.prodet_baninfo .product-tags-list ul {
    padding: 0;
    margin: 0
}

.inn-product-popup-bg {
    position: relative;
}

.inn-product-popup-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 345px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
}

.inn-product-popup-inner {
    position: relative;
    padding-top: 0px;
    line-height: 1;
}

.inner_product_slide {
    outline: 0;
}

.inn-product-popup-inner .slick-next,
.inn-product-popup-inner .slick-prev {
    display: none !important
}

.inn-product-popup-inner .custom-dots {
    padding: 0;
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;
}

.inn-product-popup-inner .custom-dots li {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 1;
}

.inn-product-popup-inner .custom-dots li button {
    padding: 0;
    margin: 0
}

.inn-product-popup-inner .custom-dots li button:before {
    content: "";
    background: #fff;
    width: 12px;
    height: 12px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid #c3c3c3;
}

.inn-product-popup-inner .custom-dots li.slick-active button:before {
    background: #d45d38;
}

.inn-product-popup-inner .custom-dots li button {
    text-indent: -9999px;
    background: none;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
}

.inner_product_slide {
    position: relative;
}

.pthumb_slide {
    margin: 5px;
    border: 1px solid #a2a6ac;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    width: auto !important;
}

.pthumb_slide:hover,
.slick-current .pthumb_slide {
    -webkit-box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    border-color: #e77600;
}

.popup_social {
    position: absolute;
    bottom: 5px;
    z-index: 1;
    right: 0;
    right: 5px;
}

.popup_social span {
    color: #373535;
    font-size: 12px;
    font-weight: 700;
}

.popup_social_icon {
    list-style: none;
    margin-bottom: 0;
    background: #fff;
    padding: 4px 0px 4px 4px;
}

.popup_social_icon li {
    float: left;
}

.popup_social_icon li a {
    color: #2a2a2a;
    padding: 0 10px;
    display: inline-block;
}

.popup_social_icon li a:hover {
    color: #ccc;
}

.popup_social_inner {
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup_social_left {
    padding-right: 10px;
}

.copied {
    position: absolute;
    right: 10px;
    top: -21px;
    background: #000;
    padding: 3px 7px;
    color: #fff;
    font-weight: normal;
    font-size: 11px;
    border-radius: 2px;
    pointer-events: none;
}

.copied:before {
    content: "";
    border: 7px solid #000;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -11px;
    margin-left: -7px;
}

.inn_product_row {
    margin: 0 auto 0 auto;
}

.inn_product_hea p {
    color: #5b5b5b;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 20px;
}

.inn_product_hea_left h3 {
    font-size: 28px;
    margin: 0 0 20px;
    color: #000;
    text-transform: capitalize;
    font-weight: 700;
}

.inn_product_hea_icon {
    padding-right: 45px;
    display: inline-block;
}

.inn_product_hea_icon img {
    margin: 0 2px;
}

.inn_product_hea_star .checked {
    color: #72624f;
}

.inn_product_hea_star .star {
    color: #d7cbb9;
}

.inn_product_review_text a {
    text-decoration: underline;
    color: #5b5b5b;
    font-weight: 700;
}

.inn_product_review_text {
    padding-left: 10px;
}

.inn_product_review {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.prd_chosen_row {
    padding: 35px 40px 30px 40px;
}

.product_chosen_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.product_chosen_col {
    width: 47.5%;
    margin-right: 4.8426150121065374%;
}

.product_chosen_col.product_chosen_col_right {
    margin-right: 0;
}

.product_chosen_col_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}

.product_chosen_col_inner {
    margin-bottom: 38px;
    margin-left: -15px;
    margin-right: -15px;
}

.product_chosen_col.product_chosen_col_right .product_chosen_col_inner {
    margin-bottom: 0;
}

.product_chosen_item_left {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 15px;
}

.form-group-modifier {
    margin-top: 23px;
}

.product_chosen_item_left.product_chosen_item_left_full {
    width: 100%;
}

.product_chosen_item_left:nth-of-type(2n+2) {
    margin-right: 0;
}

.product_chosen_item_left_second {
    float: left;
    width: 47%;
    margin-right: 3.9285714285714285%;
}

.product_chosen_item_left_second:nth-of-type(2n+2) {
    margin-right: 0;
}

.select_full {
    width: 100%;
}

.product_chosen_hea h6 {
    font-size: 15px;
    color: #1c1c1c;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    padding-right: 10px;
}

.product_chosen_hea {
    position: relative;
}

.product_chosen_item_left .chosen-container-single .chosen-single {
    width: 100%;
    height: 47px;
    line-height: 40px;
    border: 1px solid #e6e5e5;
    font-weight: 700;
}

.product_chosen_item_left .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    background-position: -246px -62px;
    width: 28px;
    height: 28px;
}

.product_chosen_item_left .chosen-container-active.chosen-with-drop .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    width: 28px;
    height: 28px;
    background-position: -287px -62px;
}

.product_chosen_item_left .chosen-container-single .chosen-single div {
    top: 10px;
    right: 0px;
}

.product_chosen_item_left .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.pmodifier_ddlist ul {
    padding: 0;
    margin: 7px 0;
    list-style: none;
}

.pmodifier_ddlist li {
    background: #ecdfcc;
    padding: 3px 35px 3px 17px;
    border: 1px solid #dcd8d8;
    border-radius: 20px;
    position: relative;
    font-weight: 700;
    color: #181818;
    margin: 0 0 4px;
}

.pmodifier_close {
    background: url(../images/close-modifier.png) no-repeat center;
    width: 48px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 20px 20px 0;
    margin: auto 0;
}

.pmodifier_close:hover {
    background-image: url(../images/cross1.png);
    background-color: #1c1c1c;
}

.product_chosen_addons {
    width: 100%;
}

.chosen_adn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.chosen_adn_left {
    max-width: 300px;
}

.chosen_adn_right {
    line-height: 0;
}

.chosen_adn_left span {
    font-size: 17px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    z-index: 1;
    padding-right: 10px;
}

.radio.chosen_adn_right label {
    padding-left: 0;
}

.radio .qty_bx label {
    font-weight: 700;
}

.product_chosen_col_right .custom_checkbox {
    width: 100%;
}

.product_chosen_col_right .custom_checkbox span {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    display: block;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    padding-right: 30px;
    padding-left: 0;
}

.product_chosen_col_right .custom_checkbox span:before {
    right: 0;
    left: auto;
    top: -5px;
}

.product_chosen_col_right .chosen_adn {
    margin-bottom: 16px;
}

.text-box {
    width: 100%;
}

.text-box em {
    text-transform: uppercase;
    font-size: 14px;
    color: #181818;
    text-align: right;
    float: right;
    margin-top: 6px;
    font-weight: 700;
}

.text-box textarea {
    width: 100%;
    height: 170px;
    padding: 15px 15px;
    font-size: 16px;
    color: #999898;
    border: 1px solid #e6e5e5;
}

textarea {
    resize: none
}

.chosen_upload {
    float: right;
    text-align: right;
    width: 100%;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    outline: 0;
}

.inputfile + label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
}

.inputfile + label:hover {
    background: #333;
}

.inputfile + label:hover span {
    color: #fff;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.chosen_upload_inner label span {
    color: #72624f;
}

.inputfile-4 + label {
    color: #d3394c;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
    color: #722040;
}

.inputfile-4 + label figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #d3394c;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
    background-color: #722040;
}

.inputfile-4 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
    background-color: 722040;
}

.prd_chosen_sub_row {
    text-align: center;
    margin: 15px 0 0 0;
    padding: 30px;
    border-top: 1px solid #e4e4e4;
}

.prd_chosen_sub_row > p {
    font-size: 30px;
    color: #000;
}

.prd_chosen_sub_row p > sup {
    font-size: 20px;
}

.prd_chosen_sub_col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 576px;
    margin: 0 auto;
}

.prd_chosen_sub_col .alert {
    margin: 0;
    width: 100%;
}

.prd_chosen_sub_item_left {
    width: 32.11805555555556%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.prd_chosen_sub_item_right {
/*    width: 67.64705882352942%;
*/}

.prd_chosen_sub_item_right button {
       border: 0;
    padding: 12px 15px;
    max-width: 390px;
    width: 100%;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    outline: 0;
    margin: 0;
}

.prd_chosen_sub_item_right button:hover {
    background:#e36410;
}

.prd_chosen_sub_item_right.prd_chosen_item_full {
    width: 100%;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button {
    max-width: 100%;
    margin: 0;
    letter-spacing: 0.5px;
    font-size: 18px;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button:hover {
    background: #333;
}

.prd_chosen_sub_item_left .qty_bx {
    width: 126px !important;
    float: inherit!important;
}

.prd_chosen_sub_item_left .qty_bx .qty_minus {
    background: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd_chosen_sub_item_left .qty_bx .qty_plus {
    background: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd_chosen_sub_item_left .qty_bx input {
    color: #fff !important;
}

.detail-pg-slider .slick-slider {
    padding: 0 0px;
}

.detail-pg-inner {
    padding: 60px 0px 10px;
}

.detail-pg-slider {
    height: 100%
}

.detail-pg-slider .slick-dots {
    padding: 40px 0 0 0;
    margin: 0;
    list-style: none;
    text-align: center
}

.detail-pg-slider .slick-dots button {
    padding: 0;
    margin: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: hidden;
    text-indent: -9999px;
    line-height: 1;
}

.detail-pg-slider .slick-dots li.slick-active button {
    background: #e87b1e;
}

.detail-pg-slider .slick-dots li {
    display: inline-block;
    margin: 10px 5px 0;
}

.detail-pg-slider-comman .innerproduct-col {
    width: auto;
    margin-bottom: 0;
    height: 100%;
}

.detail-pg-slider-comman .slick-slide {
    padding: 0 5px;
    outline: 0;
}

.detail-pg-slider-comman .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 20px;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    display: none !important;
}

.detail-pg-slider-comman .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.detail-pg-slider-comman .slick-next:hover {
    background: #f5f5f5;
}

.detail-pg-slider-comman .slick-next:hover::after {
    background-position: -154px -123px;
}

.detail-pg-slider-comman .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 17px;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    display: none !important;
}

.detail-pg-slider-comman .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.detail-pg-slider-comman .slick-prev:hover {
    background: #f5f5f5;
}

.detail-pg-slider-comman .slick-prev:hover::after {
    background-position: -213px -123px;
}

.detail-pg-slider-comman .slick-prev:hover {
    background-position: -213px -123px;
}

.detail-pg-inner .filter_tabsec .nav-tabs {
    margin-bottom: 35px;
}

.detail-pg-inner .filter_tabsec {
    margin-bottom: 0;
}

.skip {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    background: #dbd9d9;
    max-width: 89px;
    width: 100%;
    display: block;
    text-align: center;
    padding: 7px 15px;
    color: #181818;
}

.skip:hover {
    color: #fff;
    background: #333;
}

.prd-sub-bg {
    background: #fff;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 0;
    border-radius: 10px 0 10px 10px;
    -webkit-border-radius: 10px 0 10px 10px;
    box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    position: relative;
}

.prd-sub {
    max-width: 862px;
    position: relative;
    margin: 0 auto;
}

.prd-sub h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
}

.prd-sub .slick-slide {
    padding: 0 5px;
}

.prd-sub .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.prd-sub .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-next:hover {
    background: #f5f5f5;
}

.prd-sub .slick-next:hover::after {
    background-position: -154px -123px;
}

.prd-sub .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.prd-sub .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-prev:hover {
    background: #f5f5f5;
}

.prd-sub .slick-prev:hover::after {
    background-position: -213px -123px;
}

.prd-sub-slide-content {
    text-align: center;
    margin-top: 10px;
}

.prd-sub-slide-content h5 {
    font-size: 14px;
    font-weight: 700;
    color: #363636;
    margin-bottom: 8px;
}

.prd-sub-slide-content h6 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.prd-sub-slide-qty .qty_bx .qty_minus {
    background: #f4b56b;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_plus {
    background: #e48f2c;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_minus:hover,
.prd-sub-slide-qty .qty_bx .qty_plus:hover {
    background: #333;
}

.prd-sub-slide-qty .qty_bx input {
    background: #fff;
    width: 62px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd-sub-slide-qty {
    background: #ecdfcc;
    padding: 4px 0 0px 0;
    border: 1px solid #f9f9f9;
}

.prd-sub-slide-qty .qty_bx {
    width: 146px;
    height: 40px;
}

.prd-sub-slide-qty .qty_bx span {
    height: 40px;
}

.prd-sub-slide-submit {
    background: #181818;
}

.prd-sub-slide-submit a {
    font-size: 18px;
    padding: 13px 0;
    display: block;
    text-transform: uppercase;
    color: #fff;
    border-top: 1px solid #f9f9f9;
}

.prd-sub-slide-submit a:hover {
    background: #e48f2c;
}

.slick-slide {
    outline: 0;
}

.prd-sub-slide-content .btn {
    width: 100%;
}

.checkout_hea {
    position: relative;
}

.checkout_hea::after,
.check_pg::after {
    display: block;
    content: "";
    clear: both;
}

.checkout_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.checkout_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.checkout_hea_inner {
    margin-top: 8px;
}

.check_pg {
    position: relative;
}

.check_pg_inner {
    background: #fff;
    padding: 75px 0px 29px 0px;
    margin-bottom: 14px;
}

.check-pg-slider .slick-slider {
    padding: 0 75px;
}

.check_pg_inner .innerproduct-col {
    width: auto;
    margin-right: 0;
}

.check_pg_inner .slick-slide img {
    text-align: center;
    margin: auto;
}

.check_pg_inner .slick-slide {
    padding: 0 7.5px;
}

.check_pg_inner .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}

.check_pg_inner .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.check_pg_inner .slick-next:hover {
    background: #f5f5f5;
}

.check_pg_inner .slick-next:hover::after {
    background-position: -154px -123px;
}

.check_pg_inner .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}

.check_pg_inner .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.check_pg_inner .slick-prev:hover {
    background: #f5f5f5;
}

.check_pg_inner .slick-prev:hover::after {
    background-position: -213px -123px;
}

.checkoutpage_form_outer .form-group {
    margin-bottom: 15px;
}

.check_out_fourcommon {
    width: 100%;
    margin: 0 auto;
}

.checkoutpage_form_outer {
    width: 100%;
    margin: 0 auto;
}

.cdd-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;
    border: 2px solid #dddcdc
}

.cdd-details .ttsplitter {
    margin-bottom: 0;
}

.checkout-total {
    width: 100%;
    margin: 20px auto 0px auto
}

.checkout-total::after {
    display: block;
    content: "";
    clear: both
}

.checkout-total .cdd-details-lhs h4,
.checkout-total .cart-header h4 {
    padding: 14px 20px;
    position: relative;
    background: #87311d;
    border-bottom: 0px;
    text-align: center;
    font-size: 24px;
    width: 100%;
    margin-bottom: 0
}

.checkout-total .cart-header {
    padding: 0;
    background: inherit;
    border: 0;
    margin: 0
}

.checkout-total .cdd-details-rhs .cart-header h4 {
    margin: 0;
    line-height: normal
}

.checkout-total .cart-header:after {
    display: none
}

.checkout-total .cdd-details-lhs {
    width: 50%;
    position: relative;
    padding: 0px 20px 30px 30px;
    background: #fff;
}

.checkout-total .cdd-details-rhs {
    width: 50%;
    padding: 0px 30px 30px 20px;
    background: #fff;
    position: relative;
}

.checkout-right-body-section {
    padding: 30px 0 0 0;
}

.checkout-total .controls-single input[type="text"] {
    background: #f7f6f6;
    color: #6f6f6f;
    font-size: 16px;
}

.chk_hea {
    font-size: 19px;
    padding: 10px 20px;
    background: #ebe9e9;
    color: #3d3d3d;
    
    display: block;
    margin: 0 0 10px 0;
}

.chk_hea > span {
    font-family: 'Poppins', sans-serif;
    color: #393939;
    font-size: 17px;
    float: right;
}

.checkout-total .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.checkout-total .checkout-control-group-top a {
    font-size: 16px;
    color: #393939;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 5px 0 0 20px;
    display: inline-block;
    vertical-align: top;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.checkout-total .checkout-control-group-top a:hover {
    color: #d45d38
}

.checkout-total .checkout-control-group-top {
    padding: 30px 0 0px 0;
}

.checkout-control-group-topband {
    border-bottom: 1px solid #939292;
    padding: 0 0 35px 0
}

.checkout-control-group-topband span {
    float: right;
    font-weight: normal;
    font-size: 15px
}

.checkout-total .checkout-control-group-middle {
    margin: 0px 0 0 0;
    padding: 25px 0 0px 0;
    isplay: inline-block;
    width: 100%;
    border-top: 1px solid #333;
}

.checkout-total .checkout-control-group-bottom {
    padding: 0
}

.checkout-total .checkout-control-group-middle .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.checkout-total .checkout-control-group-middle .form-group .react_datepicker {
    width: 48%
}

.checkout-total .checkout-control-group-middle .form-group .input_area {
    width: 49%
}

.checkout-total .checkout-control-group-bottom textarea {
    padding: 20px 30px;
    color: #615f5f;
    height: 160px;
    font-size: 15px;
    width: 100%;
    outline: 0;
    border: 1px solid #e2e2e2;
}

.checkout-total textarea::-webkit-input-placeholder {
    color: #595656;
}

.checkout-total textarea:-moz-placeholder {
    color: #595656;
}

.checkout-total textarea::-moz-placeholder {
    color: #595656;
}

.checkout-total .cart_body {
    padding: 0
}

.checkout-total .form-group.controls-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.checkout-total .form-group.controls-three > div {
    width: 32%;
}

.checkout-total .form-group.controls-three > div input {
    margin-bottom: 0
}

.order-detail-inner {
    padding: 82px 0 0 0
}

.order-detail-inner-icon {
    position: relative;
    max-width: 604px;
}

.order-detail-inner-icon-left {
    position: absolute;
    left: 0;
    top: 2px;
}

.order-detail-scotter {
    position: relative;
    z-index: 2;
}

.order-detail-scotter::after {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -283px -126px;
    width: 41px;
    height: 24px;
    left: 0;
    top: -2px;
    position: absolute;
}

.order-detail-scotter::before {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -347px -126px;
    width: 22px;
    height: 22px;
    right: 0;
    top: -2px;
    position: absolute;
}

.order-detail-inner-icon-left.order-detail-right {
    position: absolute;
    right: 0;
    top: 3px;
    left: inherit;
}

.order-detail-inner-icon-left.order-detail-inner-center {
    position: static;
    left: inherit;
    top: inherit;
    width: 100%;
    text-align: center;
    display: inline-block;
}

.order-detail-inner-icon-left.order-detail-inner-center::after {
    display: block;
    clear: both;
    content: "";
}

.order-detail-inner-icon-left.order-detail-inner-center::before {
    position: absolute;
    content: "";
    top: 0;
    background: url(../images/border.png) no-repeat;
    width: 100%;
    max-width: 497px;
    left: 0;
    right: 0;
    height: 10px;
    margin: auto;
    top: 50%;
}

.order-detail-inner-icon-left.order-detail-inner-center img {
    vertical-align: middle
}

.delivery_total::after {
    display: block;
    content: "";
    clear: both;
}

.delivery_total {
    margin: 10px 0 0px 0;
}

.delivery_total_left {
    float: left;
    text-align: left;
    width: 50%;
}

.delivery_total_left h3 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 700;
    color: #1c1b1b;
    line-height: 22px;
    font-size: 15px
}

.delivery_total_left span {
    display: block;
    color: #000;
    line-height: 20px;
    font-size: 14px;
}

.delivery_total_left.delivery_total_right {
    float: right;
    text-align: right
}

.delivery_total.delivery_total_number {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.delivery_total.delivery_total_number .delivery_total_left h2 {
    font-size: 15px;
    color: #3d3d3d;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 700;
}

.delivery_total.delivery_total_number .delivery_total_left h4 {
    color: #060203;
    font-size: 18px;
    margin-bottom: 0;
    
}

.pickup-order-div:before {
    display: none
}

.pickup-order-div .delivery_total_left {
    width: 100%;
    text-align: center
}

.pickup-order-div .delivery_total_left img {
    width: 25px;
}

.cart-product-desc-inner {
    padding-left: 20px
}

.cart-product-desc-inner span {
    display: block;
    color: #3d3d3d;
    line-height: 19px;
    font-size: 14px
}

.chekout_cart_bar .cart_footer {
    padding: 28px 40px 10px 10px;
    margin: 0
}

.chekout_cart_bar .cart_left {
    padding-left: 0;
}

.chekout_cart_bar .cart_footer .cart_row {
    padding-left: 5px;
    padding-left: 5px;
}

.chk_hea .custom_checkbox {
    vertical-align: top
}

.chk-payment-btn-row {
    margin: 10px 0 0 0
}

.chk-payment-btn-row .button {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 21px;
    padding: 16px 10px
}

.chk-payment-btn-row.add-tocart-cls,
.chk-payment-btn-row.checkout-continue-cls .row-replace,
#PackageEditMdl .chk-payment-btn-row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls,
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls {
    width: 49%
}

.dwn_quotion {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.dwn_quotion a {
    background: #e6e3e3;
    font-size: 16px;
    font-weight: 700;
    display: block;
    padding: 10px 0;
    color: #0d0d0d;
    text-transform: uppercase;
    margin-bottom: 0;
}

.dwn_quotion a:hover {
    background: #333;
    color: #fff;
}

.redeem {
    position: relative;
    margin-bottom: 20px;
}

.redeem::after {
    display: block;
    clear: both;
    content: "";
}

.redeem-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.redeem-col {
    width: 49%;
    background: #ccc;
    padding: 40px 40px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
}
.redeem-col-full{width: 100%}
.redeem-col-full .redeem-item{     max-width: 660px;
    margin: 0 auto;}
.redeem-col.redeem-col-right {
    background: #ccc;
}

.redeem-item-hea {
    padding-left: 10px;
    position: relative;
}

.redeem-item-hea h4 {
    font-size: 27px;
    text-transform: uppercase;
    color: #363636;
    margin-bottom: 0;
    letter-spacing: -.5px;
}

.redeem-item-hea span {
    font-size: 16px;
    color: #2e2e2d;
}

.redeem-item-hea .points {
    position: absolute;
    right: 0;
    background: #1c1c1c;
    padding: 8px 0;
    max-width: 280px;
    color: #ecdfcc;
    display: block;
    width: 100%;
    text-align: center;
    top: 8px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    font-size: 18px;
    
}

.redeem-item-hea .points b {
    color: #fff;
    
}

.redeem-item-hea .points:hover {
    background: #54362c;
    color: #fff;
}

.redeem_apply {
    position: relative;
    margin-top: 5px;
}

.redeem_apply .btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    margin: 0;
    height: 51px;
    border-radius: 0;
}

.redeem_apply .btn:hover {
    background: #000;
    color: #fff;
}

.redeem_apply .form-control {
    height: 51px;
}

.redeem-item-hea-inner {
    width: 36.37770897832817%;
}

.chk-payment {
    background: #fefefe;
    padding: 0px 10px 35px 10px;
    text-align: center;
}

.chk-payment .ttsplitter span {
    background: #fefefe;
    z-index: 1;
}

.chk-payment-col-radio {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.chk-payment-col-radio li {
    /*max-width: 257px;
    padding: 15px 20px 15px 20px;*/
    max-width: 250px;
    padding: 5px 10px 5px 15px;
    margin-right: 10px;
    border: 1px solid #d0cfcb;
    border-radius: 7px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chk-payment .chk-payment-col-radio li label {
    font-size: 16px;
    font-weight: 700;
    padding-left: 42px !important;
    text-transform: uppercase;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    margin-top: -19px;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 1px solid #d0cfcb;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 22px;
    height: 22px;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -399px -125px;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:after {
    width: 28px;
    height: 28px;
    background-position: -455px -122px;
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:before {
    width: 28px;
    height: 28px;
}

.chk-payment-col-radio.promotion-ul-topcls li {
    width: auto;
    margin-right: 0
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    top: 0px;
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    top: 0px;
}

.chosen_radio_inner {
    width: 100%;
}

.expand {
    background: #eaeaea;
    padding: 10px 15px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
}

.expand h3 {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    width: 100%;
}

.expand::after {
    content: "\f107";
    right: 10px;
    display: block;
    top: 3px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}

.expand.active::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.expand_sub h1 {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 10px;
}

.expand_sub h1 span {
    width: 80%;
}

.panel-heading.expand_hea {
    cursor: pointer;
}

.expand_sub h4.panel-title {
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
}

.expand_sub_body {
    width: 100%;
}

.expand_sub h4::after {
    content: "\f107";
    right: 0;
    display: block;
    top: -7px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}

.expand_hea.active h4.panel-title::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.chk-payment-btn {
    margin-bottom: 34px;
}

.chk-payment-btn-row .btn {
    width: 100%;
    border: 0;
}

.chk-payment-btn-row .row {
    margin-right: 0;
    margin-left: 0;
}

.chk-payment-btn-row [class*="col-"] {
    padding: 0;
}

.tnk-you {
    position: relative;
    padding: 110px 0;
}

.tnk-you::after {
    display: block;
    clear: both;
    content: "";
}

.tnk-header {
    max-width: 683px;
    margin: 0 auto;
    text-align: center;
}

.tnk-header .tick {
    padding: 0 15px;
}

.tick h2 {
    font-size: 44px;
    margin: 18px 0 0 0;
}

.tick span {
    font-size: 18px;
    color: #0b0405;
}

.tnk-detail {
    margin-top: 20px;
}

.tnk-detail h2 {
    padding: 13px 10px;
    color: #fff;
    text-transform: uppercase;
    background: #1c1c1c;
    margin-bottom: 0;
    font-size: 24px; font-weight: 600;
}

.tnk-order {
    background: #ea7700;
    padding: 20px 15px;
}

.tnk-order h3 {
    margin-bottom: 5px;
    font-size: 34px;
    text-transform: uppercase;
    color: #fff; font-weight: 600;
}

.tnk-order p {
    font-size: 17px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 0;
}

.tnk-delivery .order-detail-inner {
    padding-top: 0px;
}

.tnk-delivery {
    padding: 35px 30px;
    background: #fff;
}

.tnk-delivery .order-detail-inner-icon-left.order-detail-inner-center::before {
    display: none;
}

.tnk-delivery .order-detail-inner-icon {
    margin: 0 auto;
}

.tnk-delivery .delivery_total {
    margin: 6px 0 8px 0;
}

.tnk-delivery .cart_row {
    padding: 18px 10px 5px 7px;
}

.tnk-delivery .cart_left .help-block {
    text-align: left;
}

.tnk-delivery .cart_info h4 {
    text-align: left;
}

.tnk-delivery .cart_extrainfo {
    text-align: left;
}

.tnk-delivery .cart_footer.tnk_cart_footer {
    padding: 20px 20px 5px 20px;
    margin: 0;
}

.tnk-delivery .tnk_cart_footer .cart_row {
    text-align: left;
    padding: 0px 0px 5px 0px;
}

.tnk-chk-order .btn {
    width: 100%;
    border: 0;
}

.delivery_datetime_div {
    padding: 10px 0 10px 0 !important;
    margin: 10px 0 0px 0 !important;
}

.orderitem_body_div {
    background: #f6f6f6;
    padding: 10px;
}

.orderitem_body_div .cart_row .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0
}

.remark_notesec {
    margin: 20px 0 0;
    padding: 0 7px;
}

.remark_notesec h4 {
    margin: 0 0 3px;
    font-weight: 700;
    color: #3d3d3d;
    font-size: 16px;
}

.remark_notesec p {
    margin: 0;
}

.mfp-bg {
    z-index: 10042;
}

.mfp-wrap {
    z-index: 10043;
}

.white-popup {
    max-width: 1026px;
    padding: 0;
    margin: 30px auto;
}

.pouup_in {
    padding: 60px 50px 40px;
}

.alert_popup,
.warining_popup,
.warning_popup,
.pay-conf-popup {
    max-width: 450px;
}

.warning_popup .warning-popup-img {
    width: 75px;
    margin: 0 0 10px 0
}

button.mfp-close,
button.mfp-arrow {
    margin: 0;
    padding: 0
}

.alert_popup .mfp-close {
    display: none;
}

.alert_header {
    background: #181818;
    color: #fff;
    font-size: 18px;
    
    padding: 12px 14px;
    position: relative;
    text-align: center;
}

.alert_body {
    text-align: center;
    padding: 20px;
}

.alt_btns a {
    margin: 0 5px;
}

.alt_btns a.button-left {
    background: #e87b1e;
}

.popup_equalrw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.popup_ttsec {
    width: 42%;
    min-height: 310px;
    padding: 20px 20px;
    min-height: 310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pop_title {
    position: relative;
    color: #fff;
}

.pop_title h2 {
    font-size: 36px;
    margin: 0;
    line-height: 1;
    color: #fff;
    text-transform: inherit
}

.pop_title small {
    font-size: 19px;
    font-weight: normal;
}

.popup_right {
    width: 58%;
    padding: 20px 60px 20px 68px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.innervmid_in {
    width: 100%
}

.delevery_popup .popup_ttsec, .madbar_popup .popup_ttsec {
    background: #e87b1e;
}

.delevery_popup .popup_ttsec {
    padding-left: 38px;
    padding-right: 28px;
}

.delevery_popup .popup_right .form-group .form-control {
    border: 1px solid #dadada;
}

.two-button-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.two-button-row > div .button {
    display: block;
    margin: 0;
    width: 99%;
}

.two-button-row > div {
    width: 49%
}

.two-button-row > div.active .button {
    background: #e87b1e;
}

.two-button-row .delivery_submit {
    background: #e87b1e
}

.go_second a {
    background: #e87b1e
}

.two-button-row .delivery_submit:hover,
.go_second a:hover {
    background: #000;
}
.go_second a.button.button-left {
    background: #000;
}
.error_postal_popup .popup_right .button {
    margin: 0
}

img.pop-scooter-img {
    max-width: 150px;
}

.receipt_popup,
.receipt_cateringpopup {
    max-width: 683px;
}

.receipt_popup .pouup_in,
.receipt_cateringpopup .pouup_in {
    padding: 0;
}

.receipt_popup .tnk-you,
.receipt_cateringpopup .tnk-you {
    padding: 0;
}

.receipt_popup .tnk-detail,
.receipt_cateringpopup .tnk-detail {
    margin-top: 0
}

.receipt_popup .Viewreceipt,
.receipt_cateringpopup .Viewreceipt {
    margin: 30px 0
}

.error_postal_popup .popup_ttsec {
    background: #e87b1e;
}

.error_postal_popup h4 {
    color: #fff;
    font-size: 16px;
}

.poptt_icontop {
    padding-left: 0;
}

.poptt_icontop i {
    position: static;
    margin: 0 auto 17px;
}

.news_postal_popup .popup_ttsec {
    height: 309px;
}

.news_postal_popup .popup_ttsec {
    background: #b79a6c;
    width: 51.46198830409356%;
}

.news_postal_popup .popup_right {
    background: url(../images/news_popupright.png) no-repeat;
    background-size: cover;
    width: 48.53801169590643%;
}

.news_postal_popup .pop_title p {
    font-size: 16px;
    margin: 6px 0 0 0;
}

.news_subscribe {
    position: relative;
    margin-top: 14px;
}

.news_subscribe .btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    border: 1px solid #f9f9f9;
}

.news_subscribe .btn:hover {
    background: #54362c;
    color: #fff;
}

.news_subscribe .form-control {
    height: 51px;
    border: 1px solid #fff;
}

.news_subscribe p {
    font-size: 16px;
    color: #fff;
    margin-top: 12px;
    margin-bottom: 0;
}

.mfp-close-btn-in .news_postal_popup .mfp-close {
    background: rgba(24, 24, 24, 0.5);
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 32px;
    line-height: 30px;
    opacity: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close::after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.order-body {
    text-align: center;
    padding: 64px 40px; border: 8px solid #fff;
}

.order-body h2 {
    color: #fff;
    font-size: 36px;
    margin-bottom: 0;
    text-transform: uppercase;
}

.order-body p {
    color: #fff;
    font-size: 20px;
    margin: 0 0 20px 0;
}

.order_delivery_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0;
    padding: 0 20px;
}

.order_delivery_item li {
    width: 25%;
}

.order_delivery_item li a {
    display: block;
    position: relative;
}

.order_delivery_item h3 {
    color: #fff;
    font-size: 18px;
    margin: 10px 0 0 0;
}

.order_icon {
    display: block;
    text-align: center;
    margin: auto;
}

.header-ordernow-single-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.order_delivery_item .tooltip {
    font-size: 16px;
}

.order_delivery_item .tooltip-inner {
    max-width: 260px;
}

.ordelivery_tooltip {
    position: relative;
}

.order_delivery_mobile_tooltip {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: -270px;
    top: 23px;
    background: #181818;
    padding: 10px;
    border-radius: 3px;
    width: 260px;
    margin: 0 auto 5px;
    z-index: 1;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.order_delivery_item li:hover .order_delivery_mobile_tooltip {
    opacity: 1;
    visibility: visible;
}

.order_delivery_mobile_tooltip:before {
    content: "";
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -7px;
    border: 10px solid #181818;
    border-color: transparent transparent transparent #181818;
    border-width: 7px 10px 7px 10px;
}

.order_delivery_mobile_tooltip p {
    margin: 0;
    color: #fff;
    font-size: 16px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip {
    left: auto;
    right: -270px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip:before {
    border-color: transparent #181818 transparent transparent;
    left: -16px;
    right: auto;
}

.order_delivery_item li.disable .ordericon_link {
    display: none;
}

.order_delivery_item li.disable:hover {
    background: #f9f9f9;
    border-color: #e1e1e2;
}

.order_delivery_item li.disable .order_delivery_mobile_tooltip {
    display: none;
}

.orderpop_address {
    position: relative;
    height: 100%;
    padding-top: 35px;
}

.orderpop_address p {
    font-size: 12px;
    margin: 0;
}

.orderpop_continue_shop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.orderpop_continue_shop .btn {
    font-size: 11px;
    height: 30px;
    padding: 7px 15px;
    margin: 0;
}

.orderpop_change_address {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
}

.orderpop_change_address:hover {
    color: #000;
}

.order_delivery_item li .header-ordernow-single-img img {
    height: 70px
}

.mfp-close-btn-in .order_popup .mfp-close,
.mfp-close-btn-in .awesome_popup .mfp-close,
.mfp-close-btn-in .self_popup .mfp-close,
.mfp-close-btn-in .delevery_popup .mfp-close,
.mfp-close-btn-in .error_postal_popup .mfp-close,
.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close,
.changepw_popup .mfp-close,
.redeem_popsec .mfp-close,
.pay-conf-popup .mfp-close,
.warning_popup .mfp-close {
    background: #e87b1e;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: -15px;
    right: -15px;
    padding: 0;
    margin: 0;
}

.mfp-close-btn-in .order_popup .mfp-close::after,
.mfp-close-btn-in .awesome_popup .mfp-close::after,
.mfp-close-btn-in .self_popup .mfp-close::after,
.mfp-close-btn-in .delevery_popup .mfp-close::after,
.mfp-close-btn-in .error_postal_popup .mfp-close::after,
.mfp-close-btn-in .receipt_popup .mfp-close:after,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:after,
.changepw_popup .mfp-close:after,
.redeem_popsec .mfp-close:after,
.pay-conf-popup .mfp-close:after,
.warning_popup .mfp-close:after, .venue_popup .close-icon-but:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .order_popup .mfp-close:hover,
.mfp-close-btn-in .awesome_popup .mfp-close:hover,
.mfp-close-btn-in .delevery_popup .mfp-close:hover,
.mfp-close-btn-in .error_postal_popup .mfp-close:hover,
.changepw_popup .mfp-close:hover,
.redeem_popsec .mfp-close:hover,
.pay-conf-popup .mfp-close:hover,
.warning_popup .mfp-close:hover,
.venue_popup .close-icon-but:hover{
    background: #000;
}

/* .mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close {
    background: rgba(255, 255, 255, 0.2);
} */

.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close {
    background: #e87b1e;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: 5px;
    right: 5px;
    padding: 0;
    margin: 0;
}

.mfp-close-btn-in .receipt_popup .mfp-close:hover,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
    background: #000!important;
    color: #fff!important;
}


.mfp-close-btn-in .receipt_popup .mfp-close:hover,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
    background: rgba(255, 255, 255, 0.6);
}

.video_popup .order-body {
    padding: 60px 30px 40px 30px;
}

.awesome_popup .popup_ttsec {
    width: 100%;
    background: #5d8d87;
    padding: 30px 20px;
}

.awesome_popup .btn_sec {
    max-width: 500px;
    margin: 10px auto;
}

.awesome_popup .popup_ttsec .button {
    margin-bottom: 0;
    margin-top: 0;
    background: #fff;
    color: #000
}

.awesome_popup .popup_ttsec .button:hover {
    background: #d45d38;
    color: #fff
}

.awesome_del {
    margin-top: 13px;
    text-align: center;
}

.awesome_del h5 {
    font-size: 19px;
    margin-bottom: 10px;
    color: #fff;
    
}

.awesome_del h2 {
    color: #fff;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
}

.awesome_right h4 {
    font-size: 16px;
}

.awesome_popup {
    max-width: 640px;
}

.datetime_selt_lbl{ font-weight: 700; padding-bottom: 10px; color: #383838; font-size: 16px}
.delevery_popup_datetime .btn_sec .button{ display: block; margin: 0; width: 100%; }

.white-popup.self_popup {
    max-width: 560px;
}

.self-back {
    position: absolute;
    top: 13px;
    font-size: 18px;
    color: #fff;
    z-index: 1;
    left: 40px;
    text-transform: uppercase;
}

.self-back:hover {
    color: #000;
}

.self_popup .order-body {
    padding: 0 0 0px 0;
}

.self_popup_hea_row {
    background: #e87b1e;
    padding: 20px 15px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.self_popup_hea_col {
    text-align: left;
}

.order-body .self_popup_hea_row h2 {
    color: #fff;
}

.order-body .self_popup_hea_row p {
    margin-bottom: 0;
    font-size: 20px;
    color: #fff;
}

.self_popup_hea_col_left {
    padding-right: 15px;
}

.self_pop_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 21px 15px;
}

.self_pop_col.self_pop_col_right {
    width: 100%;
    margin: 0 auto;
}

.self_pop_col {
    width: 50%;
}

.self_pop_item_loc {
    position: relative;
}

.self_pop_item_map {
    box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    border: 1px solid #bba075;
    height: 100%;
}

.selfpop_mapouter {
    height: 100% !important;
}

.or-seperator-row {
    position: absolute;
    right: -9px;
    z-index: 1;
    top: 6px;
}

.or-seperator-row .or-seperator {
    position: relative;
    padding: 25px 0px 7px 0;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator-row .or-seperator span {
    padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
    height: 82px;
    width: 1px;
    background: #e5cda6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.self_pop_item_loc p {
    color: #595656;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.self_pop_item_loc .loc_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 170px;
    margin: 0 auto;
    min-width: 288px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 23px;
    border-radius: 10px;
    height: 62px;
}

.loc_icon {
    width: 33px;
    height: 33px;
    background: url(../images/location_btn.png) no-repeat;
    margin-right: 10px;
}

.self_pop_col_right h4 {
    font-size: 16px;
    font-weight: 700;
    color: #595656;
    text-align: left;
    margin-left: 30px;
}

.self_outlet {
    margin-top: 15px;
    height: calc(100% - 105px);
}

.self_outlet h2 {
    color: #000;
    font-size: 18px;
    text-align: left;
    margin: 0px 0 10px 5px;
    text-transform: initial;
}

.self_outlet_inner {
    padding-left: 0;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 14px;
    max-height: 190px;
}

.self_outlet_inner li {
    margin-bottom: 12px;
}

.self_outlet_inner li:last-child {
    margin-bottom: 0;
}
.self_outlet_inner li a {
    background: #f8f8f8;
    padding: 10px 15px;
    display: block;
    font-size: 15px;
    color: #595656;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
}
.self_outlet_inner li a:hover,
.self_outlet_inner li.active a {
        background: #000;
        color: #fff;
}

.self_outlet .btn.cnt_btn {
    width: 100%;
    background: #72624f;
    margin: 0;
}

.self_outlet .btn.cnt_btn:hover {
    background: #333;
}

.self_pop_locbx .form-group .form-control {
    width: 100%
}

.self_outlet .button {
    margin: 0;
    width: 100%;
    display: block
}

.self_pop_locbx {
    margin: 0 0 20px 0
}

.white-popup.login-popup,
.white-popup.signup-popup,
.white-popup.signup-corparateone-popup,
.white-popup.signup-corparatetwo-popup,
.white-popup.guest-popup {
    background: inherit;
    max-width: 1086px;
}

.pop-whole-lhs-inner {
    text-align: center;
    max-width: 318px;
}

.pop-whole {
    position: relative
}

.login-popup .mfp-close,
.signup-popup .mfp-close,
.signup-corparateone-popup .mfp-close,
.signup-corparatetwo-popup .mfp-close,
.guest-popup .mfp-close {
    width: 35px;
    height: 35px;
    line-height: 35px;
    right: 49px;
    top: 0;
    font-size: 0;
    color: #afafaf;
    margin: 0
}

.login-popup .mfp-close:hover,
.signup-popup .mfp-close:hover,
.signup-corparateone-popup .mfp-close:hover,
.signup-corparatetwo-popup .mfp-close:hover,
.guest-popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.login-popup .mfp-close:after,
.signup-popup .mfp-close:after,
.signup-corparateone-popup .mfp-close:after,
.signup-corparatetwo-popup .mfp-close:after,
.guest-popup .mfp-close:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.login-popup .resend_link {
    padding: 10px 0px;
    text-align: right;
}

.login-popup .resend_link a {
    color: #0dad85;
    font-weight: 600;
}

.popup-footer .btn {
    display: block;
    max-width: 271px;
    margin: 0 auto;
    background: #2d4486;
    text-transform: inherit;
    border-radius: 7px;
    border: 0;
}

.popup-footer .btn:hover {
    background: #333;
    color: #fff;
}

.popup-footer .btn i {
    padding-right: 15px;
}

.popup-footer span {
    color: #000;
    font-size: 16px;
    margin: 0 0 3px;
}

.popup-footer .guest_btn {
    background: #000;
    color: #fff;
}

.popup-footer .guest_btn:hover,
.popup-footer .guest_btn:focus {
    background: #e87b1e;
    color: #fff;
}

.pop-whole:after {
    display: table;
    clear: both;
    content: ""
}

.inside-popup-rhs {
    float: right;
    max-width: 514px;
    background: #fff;
    padding: 45px 55px 0px 55px;
    position: relative;
    z-index: 9;
    margin: 0 49px 0 0;
    width: 100%;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all
}

.signup-popup .inside-popup-rhs,
.signup-corparateone-popup .inside-popup-rhs,
.signup-corparatetwo-popup .inside-popup-rhs {
    padding: 45px 55px 30px 55px;
}

.signup-corparatetwo-popup .inside-popup-rhs,
.guest-popup .inside-popup-rhs {
    padding: 60px 55px 40px 55px;
}

.pop-whole-lhs {
    position: absolute;
    background: #ff9335;
    padding: 124px 50px 124px 90px;
    left: 0;
    right: 0;
    z-index: 2;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.forgot-password-popup .pop-whole-lhs {
    padding: 84px 50px 84px 60px;
}

.forgot-password-popup .pop-whole-lhs-inner {
    text-align: center;
    max-width: 328px;
}

.pop-whole-lhs h3 {
    font-size: 32px;
    margin: 0 0 4px 0;
    line-height: 1;
    color: #fff;
}

.pop-whole-lhs p {
    font-size: 16px;
    color: #fff;
    margin: 0
}

.loginsep-popup .mfp-close:before,
.loginsep-popup .mfp-close:after {
    height: 20px;
    background-color: #000
}

.loginsep-popup .mfp-close {
    right: 20px;
    top: -6px
}

.popup-header {
    padding: 15px 0 24px 0;
}

.popup-header h4 {
    font-size: 16px;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
    text-transform: inherit
}

.popup-header h4 img {
    width: 163px;
    margin: 0 0 0 10px
}

.popup-header p {
    font-size: 13px;
    margin: 0px;
}

.popup-body input {
    margin: 0 0 8px;
    font-size: 16px;
    color: #595656;
}

.two-links {
    padding: 10px 0 12px 0;
    text-align: center;
}

.two-links a {
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    color: #272727;
    
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease
}

.two-links a:hover {
    color: #000
}

.spilter {
    width: 2px;
    height: 15px;
    background: #272727;
    display: inline-block;
    content: "";
    vertical-align: top;
    margin: 1px 10px 0;
    position: relative; 
    left: 0;
}

.or-seperator {
    position: relative;
    padding: 15px 0 7px 0;
    line-height: 1;
    margin: 0 auto;
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator span {
    padding: 0 10px;
    background: #fff;
    display: inline-block;
    z-index: 1;
    position: relative
}

.or-seperator:after {
    height: 1px;
    width: 100%;
    content: "";
    display: block;
    background: #0e0e0e;
    position: absolute;
    top: 55%;
    left: 0
}

.login_pop_sub .btn {
    width: 100%;
}

.guest_chk_out {
    margin-left: -55px;
    margin-right: -55px;
}

.guest_chk_out .btn {
    width: 100%;
    border: 0;
    margin-bottom: 0;
    color: #f1e0c6;
}

.guest_chk_out .btn b {
    color: #fff;
}

.guest_chk_out .btn:hover {
    color: #fff;
}

.popup-body h4 {
    text-transform: uppercase;
    margin: 0 0 11px 15px;
    font-weight: 700;
    font-size: 16px;
    color: #0e0e0e;
}

.popup-body h4.your_lgn_spacing {
    margin-top: 19px;
}

.popup-body .custom_checkbox {
    width: 100%;
    margin: 7px 0 9px 0;
}

.popup-body .sign_reg {
    margin-left: 43px;
}

.popup-body .sign_reg a {
    width: 100%;
    color: #595656;
}

.popup-body .sign_reg a:hover {
    background: #333;
    color: #fff;
}

.popup-body .custom_checkbox input {
    border: 1px solid #e2e2e2;
    width: 27px;
}

.popup-body .custom_checkbox span:before {
    top: 11px;
    width: 27px;
    height: 27px;
    border: 2px solid #e7d8c1;
    border-radius: 2px;
}

.popup-body .custom_checkbox input[type=checkbox]:checked + span:before {
    background-position: -30px -186px;
}

.popup-body .sign_reg p {
    margin-bottom: 9px;
    color: #0e0e0e;
    line-height: 18px;
    font-weight: 300;
}

.popup-body .custom_checkbox.custom_checkbox_content span:before {
    top: 0;
}

.popup-body .sign_reg a.sign_reg_uline {
    text-decoration: underline;
    color: #0e0e0e;
}

.popup-body .sign_reg a.sign_reg_uline:hover {
    color: #d45d38;
    background: inherit;
}

.row.pop-two-form {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 20px;
}

.row.pop-two-form [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}

.back_click {
    position: absolute;
    left: 19px;
    top: 13px;
}

.back_click a {
    color: #c3c3c3;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    display: inline-block;
    padding-left: 24px;
}

.back_click a:hover {
    color: #333;
}

.back_click a::after {
    content: "";
    position: absolute;
    left: 0;
    width: 11px;
    height: 19px;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -344px -67px;
    top: 2px;
}

.back_click a:hover::after {
    background-position: -375px -67px;
}

.changepw_popup {
    max-width: 768px;
}

.white-popup.processing {
    max-width: 768px;
}

.process_inner {
    position: relative;
}

.process_col {
    position: relative;
    margin-bottom: 15px;
}

.process_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.process_right {
    padding-left: 60px;
}

.process_right h5 {
    color: #b0aeae;
    margin-bottom: 5px;
}

.process_right p {
    margin-bottom: 0;
    font-size: 18px;
}

.redeem_popsec {
    max-width: 600px;
}

.redeem_bansec {
    position: relative;
    overflow: hidden;
}

.redeem_bansec .innerproduct-item-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
}

.redeem_popsec .pouup_in {
    padding: 40px 50px 40px;
}

.redeem_popsec .title3 {
    font-size: 24px;
}

@-webkit-keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
        transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
}

.location-part-outer {
    padding: 0px !important;
    position: relative;
    overflow: hidden;
}

.location-part iframe {
    width: 100%;
    height: 680px;
}

.location-sidemenu {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 320px;
    height: 100%;
    z-index: 8;
    width: 100%;
}

.location-sidemenu .red-bar {
    background: #db4337;
    padding: 7px 40px;
}

.location-sidemenu .three-lines {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    width: 18px;
    height: 12px;
    background: url(../images/spirite_whole.png) no-repeat scroll -122px -191px transparent;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    left: 15px;
    top: 18px;
}

.location-sidemenu .three-lines:hover {
    background-position: -156px -191px;
}

.location-sidemenu .serch-box form {
    position: relative;
}

.location-sidemenu .serch-box input[type="text"] {
    margin: 0;
    height: 35px;
    border: none;
    padding: 7px 35px 7px 15px;
    background: transparent;
    color: #fff;
    outline: 0;
    font-weight: 700;
}

.location-sidemenu .serch-box input[type="text"]::-webkit-input-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]::-moz-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-ms-input-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-moz-placeholder {
    color: #fff;
}

.loc-cont-part {
    position: absolute;
    width: 100%;
    left: 0;
    background: #fff;
}

.location-sidemenu .serch-box input[type="submit"] {
    position: absolute;
    font-size: 0;
    right: 0;
    top: 0;
    margin: 0;
    width: 35px;
    height: 35px;
    padding: 5px;
    min-width: inherit;
    border: none;
    border-radius: 0;
    background: url(../images/search-icon.png) no-repeat scroll center center transparent;
}

.location-sidemenu .three-dots {
    width: 19px;
    height: 19px;
    background: url(../images/spirite_whole.png) no-repeat scroll -193px -191px transparent;
    display: inline-block;
    position: absolute;
    right: 13px;
    top: 16px;
    cursor: pointer;
}

.outlet-list {
    max-height: 551px;
    overflow-y: auto;
}

.outlet-list > ul {
    padding: 20px 15px;
}

.outlet-list > ul > li {
    margin-bottom: 20px;
}

.outlet-list li {
    list-style: none;
    padding-left: 38px;
}

.outlet-list li span {
    display: block;
    font-weight: 700;
}

.outlet-list li .submenu {
    padding: 0;
}

.outlet-list li .submenu li {
    margin-top: 10px;
    list-style: none;
    padding: 0;
    line-height: normal;
    padding-left: 3px;
}

.outlet-list li .submenu li a {
    font-weight: 700;
    display: block;
    color: #272626;
    position: relative;
    padding-left: 14px;
    font-size: 11px;
}

.outlet-list li .submenu li a:hover {
    color: #ecdfcc
}

.outlet-list li .submenu li a:before {
    position: absolute;
    left: 0;
    top: 0px;
    content: url(../images/location_small.png);
}

.outlet-list .custom_checkbox {
    position: absolute;
    left: 0;
    font-size: 0;
}

.outlet-list .custom_checkbox span:before {
    width: 16px;
    height: 17px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid #e6e6e6;
}

.outlet-list .custom_checkbox input[type=checkbox]:checked + span:before {
    background-position: -216px -189px;
}

.mapinfo_trigger {
    background: red;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 20px;
    top: 20px;
}

.mapinfo_canvas {
    background: #fff;
    width: 100%;
    max-width: 320px;
    position: absolute;
    left: -320px;
    top: -49px;
    bottom: 0;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 9;
}

.mapinfo_canvas.open {
    left: 0;
}

.mapcanvas_ttsec {
    background: #db4337;
    padding: 15.5px 20px 15.5px 49px;
    position: relative;
}

.mapcanvas_close {
    background: url(../images/left-arrow.png) no-repeat center;
    position: absolute;
    width: 49px;
    height: 49px;
    line-height: 49px;
    font-size: 24px;
    text-align: center;
    left: 0;
    top: 0;
}

.outlet-list li .submenu li .mapcanvas_close {
    position: absolute;
    padding: 0;
}

.outlet-list li .submenu li .mapcanvas_close:before {
    display: none;
}

.mapcanvas_ttsec h3 {
    font-size: 14px;
    margin: 0;
    color: #fff;
    font-weight: 700;
}

.mapcanvas_bx {
    padding: 12px 18px;
    color: #000;
}

.mapcanvas_bx h5 {
    color: #888;
    margin: 0;
    font-size: 13px;
}

.mainacc_menusec {
    display: flex;
    flex-wrap: wrap;
}

.mainacc_menulist {
    padding: 0 0;
    list-style: none;
    margin: 0;
}

.mainacc_menulist > li {
   position: relative;
}

.mainacc_menulist > li >a {
   padding: 12px 10px 12px 20px;
    display: block;
    text-transform: capitalize;
    margin-bottom: 0;
    font-weight: 500;
    color: #000;
    background: #f4f3f3;
}

.mainacc_menulist > li.active >a,
.mainacc_menulist > li >a:hover {
    background: #e87b1e;
    color: #fff;
}
.mainacc_menulist > li:hover .myacc_dropmenu{
    opacity: 1; visibility: visible;
}
.mbacc_mslidersec {
    display: none;
}

.mainacc_moblink {
    display: none;
}

.myacc_hea {
    position: relative;
    margin: 0 0 14px;
}

.myacc_hea::after,
.myacc_pg::after {
    display: block;
    content: "";
    clear: both;
}

.myacc_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.myacc_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.myacc_hea_inner {
    margin-top: 8px;
}

.box_in {
    background: #fff;
}


.accmenu_sec {
    background: #000;
    width: 285px;
    display: inline-block;
    position: relative;
    color: #fff;
}

.accprofile {
    padding: 26px 15px 0px;
}

.accprofile_img {
    width: 140px;
    height: 140px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    line-height: 134px;
    border-radius: 50%;
    margin: 0 auto 0;
    overflow: hidden;
}

.accprofile_img img {
    vertical-align: middle;
}

.accprofile_info p {
    font-size: 16px;
    color: #fff;
    margin: 0;
    
}

.accpoints_sec {
    padding: 15px 7px 0px
}

.accpoint_tt span {
    background: #fff;
    padding: 0 4px;
    position: relative;
    display: inline-block;
}

.accearn_points {
    background: rgba(0, 0, 0, 0.5);
    padding: 15px 15px 10px;
}

.accearn_pointtop {
    position: relative;
    padding-left: 52px;
}

.accearn_pointtop i {
    position: absolute;
    left: 0;
    top: 7px;
}

.accearn_pointtop h4 {
    font-size: 15px;
    margin: 0 0 8px;
    color: #fff
}

.accearn_pointtop span {
    font-size: 45px;
    display: inline-block;
    line-height: 0.8;
    color: #e87b1e;
}

.accearn_pointtop sub {
    font-size: 60%;
    bottom: 0;
}

.accearn_points p {
    font-size: 13px;
    margin: 0;
}

.accpoint_ec {
    position: absolute;
    right: 13px;
    bottom: 8px;
}

.accsec_right {
    padding: 40px 40px;
}

.accsec_right h1 {
    font-size: 36px;
    color: #040507;
    margin-bottom: 9px;
}

.accsec_right p {
    color: #000000;
    font-size: 17px;
}

.acc-inform .form-group {
    margin-bottom: 11px;
}

.other-add-body {
    height: 175px;
    border: 1px solid #f1efef;
    border-radius: 7px;
    padding: 16px 14px 0px 14px;
}

.other-add-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
}

.other-add-row li {
    width: 32.1875%;
    margin-right: 1.6666666666666666%;
    position: relative;
    margin-bottom: 10px;
}

.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}

.other-add-row li a {
    background: #f9f9f9;
    padding: 15px 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #5f5e5e;
    font-size: 12px;
    border-radius: 5px;
    cursor: default;
}

.other-add-row span {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 8px;
    height: 8px;
    cursor: pointer;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    line-height: 0;
}

.other-add-row span:hover {
    opacity: 0.5;
}

.setting_menu_list {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.setting_menu_list > ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.setting_menu_list > ul li {
    position: relative;
}

.setting_menu_list > ul > li > a {
    padding: 12.5px 10px 12.5px 20px;
    background: rgba(255, 255, 255, 0.11);
    color: #fff;
    display: block;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(255, 255, 255, 0.11);
    margin-bottom: 0;
}

.setting_menu_list > ul li a:hover {
    background: #e87b1e;
    color: #fff;
}

.setting_menu_list > ul li:last-child a {
    border-bottom: 0;
}

.setting_menu_list > ul li:hover .myacc_dropmenu {
    opacity: 1;
    visibility: visible;
}

.setting_menu_list_icon, .sprite_setting {
    background: url(../images/spirite_whole.png) no-repeat;
    display: inline-block;
    margin-right: 16px;
    position: relative;
}
.sprite_setting.gift {
    width: 17px;
    height: 17px;
    background-position: -392px -284px;
}
.myacc_dropmenu h3 {
    color: #fff;
    font-size:16px;
}
.myacc_dropmenu .view_member {
    padding: 0 !important;
}
.myacc_dropmenu li a:hover {
    background: none !important;
    color: #e87b1e !important;
}
.myacc_dropmenu li {
    padding: 6px 0;
    position: relative;
}
.setting_menu_list_icon{display: none;}

.myacc_dropmenu {
    position: absolute;
    left: 105%;
    top: -20px;
    list-style: none;
    padding-left: 0;
    background: rgba(0, 0, 0, 0.77);
    padding: 20px 25px;
    z-index: 5;
    width: 284px;
    border-radius: 7px;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    opacity: 0;
    visibility: hidden;
}

.myacc_dropmenu::after {
    position: absolute;
    content: "";
    border-right: 14px solid #3b3b3b;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    top: 32px;
    left: -7px;
}

.myacc_dropmenu::before {
    content: "";
    position: absolute;
    left: -13px;
    top: 0;
    background: #ccc;
    width: 20px;
    height: 100%;
    opacity: 0;
}

.myacc_dropmenu li a {
    color: #fff;
}

.myacc_dropmenu .tg-list {
    position: absolute;
    top: 0;
    right: -12px;
    padding-top: 0;
}

.main_tabsec_inner {

}

.main_tabsec_inner> .nav-tabs>li {
    width: 33.17%;
    margin-right: .24154589371980675%;
    position: relative;
}

.main_tabsec_inner> .nav-tabs>li:last-child {
    margin-right: 0;
}

.main_tabsec_inner> .nav-tabs {
    text-align: center;
}

.main_tabsec_inner> .nav-tabs>li> a {
    background: #bfbebe;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 91px 17.5px 15px;
}

.main_tabsec_inner> .nav-tabs>li.active>a {
    border: 0;
    background: #0b0405;
    color: #fff;
}

.main_tabsec_inner> .nav-tabs>li> a > span > b {
    font-weight: normal; position: absolute; left: 0; top:0; padding: 0;
}

.count_num_t {
    background: #a7a7a7;
    width: 70px;
    height: 100%;
    text-align: center;
    font-style: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    color: #0b0405;
    font-size: 20px;
    border-top-left-radius: 3.2em 5em;
    border-bottom-left-radius: 3.2em 5em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.btn.spirite_image {
    height: 56px;
}

.spirite_image {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.spirite_image i {
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
}

.two-wheller {
    width: 39px;
    height: 27px;
    margin-top: -8px;
    background: url(../images/scotter.png) no-repeat;
}

.bag {
    width: 19px;
    height: 29px;
    margin-top: -10px;
    background: url(../images/bag-icon.png) no-repeat;
}

.cap {
    width: 23px;
    height: 21px;
    margin-top: -8px;
    background: url(../images/cap.png) no-repeat;
}

.main_tabsec_inner> .nav-tabs>li.active .two-wheller {
    background: url(../images/scotter-hover.png) no-repeat;
}

.main_tabsec_inner> .nav-tabs>li.active .bag {
    background: url(../images/bag-icon-hover.png) no-repeat;
}

.main_tabsec_inner> .nav-tabs>li.active .cap {
    background: url(../images/cap-hover.png) no-repeat;
}

.filter_tabsec .nav-tabs {
    width: 100%;
    padding: 0;
}

.filter_tabsec .nav-tabs>li {
    width: 50%;
    float: left;
    border-bottom: 5px solid #fff;
    position: relative;
}

.filter_tabsec .nav-tabs>li>a span {
    display: inline;
}

.filter_tabsec .nav-tabs>li>a:after {
    height: 5px;
    bottom: -5px;
    background: #0b0405;
    max-width: 253px;
    margin: auto;
    width: 100%;
}

.tab-pane {
    display: none
}

.tab-pane.active {
    display: block
}

.ord-body::after {
    display: block;
    clear: both;
    content: "";
}

.ord-pagination-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 0 10px 0;
}

.ord-pagination-col::after {
    display: block;
    content: "";
    clear: both;
}

.ord-pagination-col span {
    font-size: 12px;
    text-transform: uppercase;
    
}

.ord-pagination-col p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    
}

.ord-pagination-col .form-group {
    margin-bottom: 0;
    padding-right: 5px;
}

.ord-pagination-col .form-group select {
    background: #d7d4d8;
    border: 0;
    
}

.cur-order-body {
    width: 100%;
    clear: both;
}

.cur-order-body::after {
    display: block;
    clear: both;
    content: "";
}

.cur-order {
    padding: 10px 0;
    border-bottom: 1px solid #efefee;
    position: relative;
}

.click_view {
    position: absolute;
    right: 21px;
    color: #474747;
    padding: 0;
    font-weight: 700;
    font-size: 12px;
}

.cur-order-del {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 450px;
    list-style: none;
    padding-left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.cur-order-del li {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

.cur-order-del li a.btn {
    min-width: 132px;
    height: 35px;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    color: #0b0405;
    line-height: normal;
    margin-bottom: 0;
}

.cur-order-del li a.btn:hover {
    color: #fff;
}

.cur-order-del li span {
    padding-left: 5px;
}

.cur-order-del-icon {
    background: url(../images/spirite_whole.png) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.calender-icon {
    background-position: -215px -258px;
}

.time-icon {
    background-position: -249px -257px;
}

.cur-ord-rec {
    list-style: none;
    float: right;
    max-width: 400px;
    padding-right: 19px;
    margin-bottom: 0;
    padding: 8px 19px 0 0;
}

.cur-ord-rec li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cur-ord-rec li a {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #2e2e2c;
}

.cur-ord-rec li a:hover {
    color: #909090;
}

.cur-ord-rec li span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #2e2e2c;
    margin: 0 20px;
    position: relative;
}

.cur-ord-rec li span::after {
    content: "";
    background: #2e2e2c;
    height: 14px;
    width: 1px;
    display: block;
    position: absolute;
    top: 2px;
}

.order-cat {}

.order-cat-hea {
    background: #fff;
    padding: 8px 35px;
    max-width: 331px;
    margin-bottom: 10px;
}

.order-cat h2 {
    font-size: 20px;
    color: #0b0405;
    margin-bottom: 0;
}

.order-cat-table {
    padding: 0 35px;
}

.order-cat-table h4 {
    color: #474747;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 11px;
}

.order-cat-table table {
    border: 0;
    max-width: 364px;
    margin-bottom: 10px;
}

.order-cat-table table td {
    font-weight: 300;
    font-size: 16px;
    color: #3d3d3d;
    padding: 0 5px;
}

.order-cat-table table td.p_name {
    padding-left: 0;
}

.p_name {
    width: 247px;
}

.p_val {
    width: 80px;
}

.ord-amount {
    padding: 10px 0 0 35px;
    border-top: 1px solid #efefee;
}

.ord-amount .row {
    margin-right: -20px;
}

.ord-amount-item {
    max-width: 364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ord-amount h2 {
    font-size: 27px;
    color: #202020;
}

.ord-rec-sec {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    float: right;
    width: 649px;
}

.ord-rec-sec li {
    color: #aba9a9;
    margin-bottom: 0;
    height: 39px;
    padding: 10px 15px;
    max-width: 184px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    line-height: 23px;
    overflow: hidden;
}

.ord-rec-sec li.completed {
    background: #ecdfcc;
    color: #3d3d3d;
}

.ord-number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-weight: 700;
    margin-right: 6px;
}

.ord-rec-sec li.completed .ord-number {
    color: #fff;
    background: #0b0405;
}

.rewards-tab-section .main_tabsec_inner {
    padding: 6px 72px 50px 72px;
}

.congrats::after {
    display: block;
    content: "";
    clear: both;
}

.congrats-inner {
    background: #0b0405 url(../images/rewardbg.jpg) repeat;
    margin: 0 auto;
    text-align: center;
    padding: 40px 15px;
    border-radius: 8px 8px 0 0; background-size: cover;
}

.congrats-inner h3 {
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 10px;
    font-size: 18px; font-weight: 600; color: #fff;
}

.congrats-inner h2 {
    font-size: 34px;
    color: #e87b1e;
    margin-bottom: 0;
    font-weight: 800; text-transform: uppercase;
}

.rewards-tab-section .ord-amount {
    border-top: inherit;
    padding-top: 0;
}

.rewards-tab-section .order-cat-hea {
    margin-bottom: 7px;
}

.earned_points {
    max-width: 300px;
    padding-left: 0;
    list-style: none;
    float: right;
    padding-right: 24px;
}

.earned_points li span {
    color: #bfbebe;
    text-transform: uppercase;
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
}

.earned_points li h2 {
    color: #b69a6b;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.redeem_popsec {
    max-width: 520px;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    margin: 50px auto;
}

.redeem_popsec .promo-earned-col-center .innerproduct-item-image {
    width: 25%;
    height: 270px;
}

.redeem_popsec .promo-earned-content {
    width: 100%;
    padding: 20px;
    text-align: center;
}
.vouchar-popup .promo-earned-content .btn{ width: 100%; max-width: 180px; margin: 20px auto 0;}
.vouchar-popup .promo-earned-content h3{ font-size: 18px; margin-bottom: 15px }
.vouchar-popup .promo-earned-content .expiry-date{ display: block; padding: 0 0 5px 0}

.promo-popup-image{
    text-align: center;
}

.redeem_popsec .promo-earned-content h4 {
    font-size: 22px;
    max-height: inherit;
    margin: 20px 0px 15px;
}

.redeem_popsec .promo-earned-content h2 {
    font-size: 42px;
    color: #3c3c3c;
}

.redeem_popsec .promo-earned-content p {
    margin: 7px 0 0;
}

.redeem_popsec .abc_code {
    padding: 20px 0 0px;
}

.redeem_popsec .promo-earned-content h2 span sup {
    font-size: 26px;
}

.redeem_pop_image img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.redeem_popsec .pouup_in {
    padding: 30px 55px 30px;
}

.redeem_popsec .pouup_in p {
    font-size: 16px;
    color: #000;
    line-height: 24px;
}

.redeem_popsec .btn {
    background: #e87b1e;
    color: #fff;
    display: block;
    width: 95%;
    border-radius: 8px;
    margin: 0 auto 17px;
}

.redeem_popsec .btn:hover {
    background: #000;
    color: #fff;
}

.redeem_popsec .mfp-close {
    background: #d9d7d7;
    border-radius: 7px;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    top: 7px;
    right: 7px;
}

.redeem_popsec .mfp-close:after {
    background: url(../images/cross-close.png) no-repeat;
    background-size: contain;
    background-position: center;
}

.redeem_popsec .mfp-close:hover {
    background: #b5b5b5;
}

.input_area {
    position: relative;
}

.input_area {
    position: relative;
}

.cal_icon {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 45px;
    pointer-events: none;
}

.cal_icon b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/arrow-down.png) no-repeat center;
}

.pop-whole .kep-login-facebook.metro {
    display: block;
    max-width: 271px;
    margin: 0 auto;
    background: #2d4486;
    text-transform: inherit;
    border-radius: 7px;
    border: 0;
    width: 100%;
    background: #2d4486;
    padding: 14px 15px;
    border-radius: 7px;
    font-size: 17px;
    
}

.pop-whole .kep-login-facebook.metro:hover {
    background: #333;
}

.combo-input-label {
    height: 100%;
    border: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin: 0;
    background: #060203;
    border-radius: 5px;
    
    padding: 11px 2px;
    line-height: 12px;
}

.compo_acc_innerdiv {
    padding: 15px 0px 0px 0px;
}

.gloading_img {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9;
}

.gloading_img:after {
    content: "";
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 1s linear infinite;
    animation: loader-rotate 1s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.load_warpper {
    position: relative;
}

.load_warpper #dvLoading {
    position: absolute;
}

#dvLoading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff url(../images/loading.gif) no-repeat center;
    background-size: 60px;
    -webkit-background-size: 60px;
    bottom: 0;
    right: 0;
}

#inner_loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif) no-repeat center;
    bottom: 0;
    right: 0;
}

.popup_close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #fff;
    opacity: 1;
    padding: 10px;
    border-radius: 5px;
    width: 40px;
    z-index: 1
}

.back_arrow {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 43px;
    height: 49px;
    text-align: center;
    line-height: 49px;
    cursor: pointer;
    z-index: 4;
}

.back_arrow:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 26px;
    height: 26px;
    background-position: -160px -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.react-datepicker-popper {
    z-index: 11 !important;
}

.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 50px;
    right: 15px;
    background: #523429;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    cursor: pointer;
    z-index: 111;
}

.scrollup:hover {
    background: #000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.scrollup:hover .fa {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.scrollup .fa {
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
}

.cms-page {
    margin: 0px 0 30px;
}

.cms-page h3 {
    font-size: 27px;
    color: #040507;
}


.contact_left p a {
    color: inherit;
}

.contact_left p a:hover {
    color: #b69a6b;
}

.contact_left p.open-hours {
    border-top: 1px solid #eaeaea;
    padding-top: 25px;
    margin-top: 26px;
}

.contactus_pg .contact_form h3 {
    margin: 0 0 5px;
}

.contact_form .help-block {
    margin: 0 0 24px;
}

.product_tag {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 5px 0 10px;
}

.product_tag li {
    padding-right: 10px;
}

.product_tag li b {
    margin-right: 10px;
}

.product_tag li a {
    display: inline-block;
    color: #5b5b5b;
    font-weight: 700;
}

.catogry_row_fixed .bakery_slider {
   /* position: fixed;
    top: 96px;*/ position: inherit; top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    margin: auto;
    text-align: center;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    max-width: 1260px;
    padding-top: 0 !important;
}

.tbars .bakery_row_fixed {
    top: 171px;
}

.bakery_row.bakery_row_fixed .slick-next {
    right: 0;
}

.chosen_file {
    position: relative;
    display: inline-block;
}

.chosen_file input[type="file"] {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.chosen_file label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
    margin: 0;
    pointer-events: none;
}

.catering .ttsplitter span {
    background: #fff;
}

.catering .ttsplitter {
    margin: 0 auto 20px;
}

.order_nav {
    position: relative;
}

.order_nav > .nav-tabs:before {
    content: '';
    background: #dddcdc;
    width: 100%;
    height: 50px;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.order_nav > .nav-tabs {
    position: relative;
    border: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1
}

.order_nav > .nav-tabs>li {
    margin: 0;
    text-align: center;
    border-left: 2px solid transparent;
}

.order_nav > .nav-tabs>li.active>a,
.order_nav > .nav-tabs>li.active>a:focus,
.order_nav > .nav-tabs>li.active>a:hover {
    border: 0;
    background: #e87b1e;
    color: #fff;
}

.order_nav > .nav-tabs>li>a {
    background: #fff;
    margin: 0px 1px;
    border-radius: 0;
    border: 0;
    color: #181818;
    font-size: 18px;
    text-transform: uppercase;
    padding: 15px 23px;
    
    display: block
}

.nos {
    background: #473f3c;
    border-radius: 100%;
    padding: 2px 2px;
    margin-right: 7px;
    color: #fff;
    border: 2px solid #0b0405;
    width: 32px;
    height: 32px;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
    line-height: 24px;
}

.order_nav .tb-padding {
    padding: 60px 0;
}

.container_720 {
    margin: 0 auto;
    max-width: 720px;
}

.choose_location {
    position: relative;
    border: 2px solid #cfbfaa;
    border-radius: 5px;
    padding: 50px 40px 30px;
    margin-bottom: 50px;
    background: #d2be9b;
    background: rgba(210, 190, 155, 0.38)
}

.chosen-alert-cnt {
    position: absolute;
    bottom: -27px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.chosen-alert-cnt span {
    background: #fb0a37;
    padding: 6px 15px;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    box-shadow: 0px 3px 4px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 3px 4px rgba(1, 1, 1, 0.17);
}

.choose_location h4 {
    color: #3d3d3d;
    font-size: 19px;
    
    margin: 0 0 5px 0
}

.choose_location .custom_radio span {
    color: #3d3d3d;
    font-size: 18px;
    line-height: 35px;
    padding: 0 0 0 42px;
    
}

.choose_location .custom_radio span:before {
    width: 32px;
    height: 32px;
}

.choose_location [type="radio"]:checked,
[type="radio"]:not(:checked) {
    left: auto;
    opacity: 0;
}

.choose_location .custom_radio input[type=radio]:checked + span:before {
    background: #72624f;
    background-position: -399px -125px;
    z-index: 1;
    border: 1px solid #120b0c;
}

.divide {
    width: 90%;
    background: #c7c3c3;
    height: 2px;
    margin: 10px 0px 10px 40px;
}

.date_list {
    margin-bottom: 30px;
    position: relative;
    padding: 40px 120px 30px;
    border: 2px solid #cfbfaa;
    background: #d2be9b;
    background: rgba(210, 190, 155, 0.38);
    border-radius: 6px;
}

.date_list:after {
    content: "";
    display: table;
    clear: both;
}

.datelist_bracktime h3,
h3.choose-eventtype {
    text-align: center;
    font-size: 26px;
    margin: 0 0 20px 0;
}

.ordering-more {
    text-align: center
}

.ordering-more .button.no {
    background: #626161;
    width: 140px;
    margin: 0
}

.ordering-more .button.yes {
    background: #d45d38;
    width: 140px;
    margin: 0
}

#nobtn_id {
    margin-right: 5px
}

.ordering-more .button.yes:hover,
.ordering-more .button.no:hover {
    background: #000
}

.ordering-more h3 {
    color: #3d3d3d;
    font-size: 20px;
    
    margin: 0 0 10px 0;
}

.date_list h4 {
    color: #3d3d3d;
    font-size: 20px;
    ;
    
    margin: 0 0 10px 0
}

.venue-details-inner h3 {
    display: block;
    font-size: 14px;
    color: #3c3a3a;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 5px;
}

.catering_days_ul:after {
    content: "";
    display: table;
    clear: both;
}

.catering_days_ul {
    list-style: none;
    margin: 0 -2px;
    padding-left: 0;
    padding-bottom: 0;
}

.catering_days_ul li {
    padding: 0 2px;
    width: 20%;
    float: left;
    margin-bottom: 5px;
    height: 70px;
}

.catering_days_ul li dl {
    padding: 12px 5px;
    background: #fff;
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    display: block;
    text-align: center;
    cursor: pointer;
    height: 70px;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.catering_days_ul li dl.active {
    background: #e87b1e;
}

.catering_days_ul li dl.active dt {
    color: #fff;
}

.catering_days_ul li dl.active dd {
    color: #fff;
}

.catering_days_ul li dl:hover {
    background: #e87b1e;
}

.catering_days_ul li dl:hover dt {
    color: #fff;
}

.catering_days_ul li dl:hover dd {
    color: #fff;
}

.catering_days_ul li dl.cl-next-days:hover span {
    color: #000;
}

.catering_days_ul li dl.cl-next-days {
    background: #a1a1a1;
}

.catering_days_ul li dl.cl-next-days span {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    
    text-transform: uppercase;
    display: block
}

.catering_days_ul li dl dt {
    font-size: 14px;
    text-transform: uppercase;
    color: #9d9d9d;
    line-height: 14px;
    
    margin: 0 0 5px 0
}

.catering_days_ul li dl dd {
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    color: #3c3a3a;
    line-height: 1;
    
}

.catering_days_ul .tag {
    color: #fff;
    background: #060606;
    padding: 5px 5px;
    position: absolute;
    left: -10px;
    top: 20px;
    transform: rotate(-18deg);
    -webkit-transform: rotate(-18deg);
    width: 120%;
    line-height: normal;
    text-align: center;
    height: 27px;
    font-size: 12px;
    
    text-transform: uppercase
}

.catering_days_ul li dl.time_inner dd {
    font-size: 18px;
}

.res-datetime-picker p {
    margin: 15px 0px 40px;
    font-style: italic;
    font-size: 12px;
    font-weight: bold;
}

.res-datetime-picker .tag span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    text-transform: uppercase;
}

.time_slot {
    margin-bottom: 10px;
    position: relative;
    padding: 30px 120px 30px;
    border: 2px solid #ecdfcc;
    background: #ffffff;
    text-align: center;
    border-radius: 6px;
}

.time_slot .order-btn {
    padding: 20px 0px 30px;
}

.time_slot .order-btn .no {
    background: #626161;
    height: 55px;
    font-size: 22px;
    padding: 10px 80px;
    border-radius: 4px;
}

.time_slot .order-btn .yes {
    background: #72624f;
    height: 55px;
    font-size: 22px;
    padding: 10px 80px;
    border-radius: 4px;
}

.time_slot h4 {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 17px;
    margin: 0px;
}

.time_slot .add_time .label {
    color: #3d3d3d;
    font-size: 18px;
    margin: 0px;
    padding: 0px 0px;
    width: 240px;
    float: left;
    text-align: right;
    line-height: 38px;
    height: 38px;
}

.add_time {
    margin: 10px 30px 45px 0px;
}

.time_slot .time {
    padding: 0px 0px 20px 0px;
}

.time_slot .time:after {
    content: "";
    display: table;
    clear: both;
}

.time_slot .time label {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 17px;
    padding: 0px 0px 0px;
    text-transform: uppercase;
    line-height: 40px;
    margin: 0px;
}

.time_slot .time .form-control,
.order-select-brktime ul .time-cls .breaktime_listcls {
    height: 38px;
    padding: 6px 0px;
    border: 0
}

.order-select-brktime ul .custom-select-inner {
    padding: 0 10px 0 40px;
    background: #fff;
    width: 180px;
    height: 38px;
}

.order-select-brktime ul .time-cls .form-group:after {
    height: 38px;
    top: 0;
    right: 0
}

.order-break {
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.order-break .label {
    font-size: 19px;
    
    margin: 0 10px 0 0
}

.catering_hall_list {
    padding: 30px 0 10px
}

.catering_hall_list p {
    font-size: 17px;
    margin: 0px;
}

.catering_hall_list .form-group {
    margin: 25px 0 25px;
}

.function-rooms ul {
    margin: 0;
    padding: 20px 0 0 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.function-rooms ul li {
    width: 32%;
    margin: 0 2% 20px 0;
    text-align: center
}

.function-rooms ul li:nth-child(3n) {
    margin-right: 0
}

.view-rooms h4 {
    color: #000;
    
    font-size: 15px;
}

.function-rooms ul li a:hover h4,
.function-rooms ul li.active h4 {
    color: #fff
}

.view-rooms span {
    color: #4e4e4e;
    display: block;
    clear: both;
    position: relative;
}

.function-rooms ul li a:hover span,
.function-rooms ul li.active span {
    color: #fff
}

.function-rooms ul li a {
    height: 100%;
    padding: 5px 5px 15px;
    background: #e0e0e0;
    display: block
}

.function-rooms ul li a:hover,
.function-rooms ul li.active a {
    background: #d45d38
}

a.view-rooms img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 0 0 15px 0;
}

.show {
    display: block;
}

.cedele-location label {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 16px;
    line-height: 35px;
}

.location-list {
    height: 270px;
    margin: 0px -15px 0px 0px;
}

.location-list ul {
    list-style: none;
    margin: 0px 15px 0px 0px;
    padding: 0px;
}

.location-list ul li {
    font-weight: bold;
    color: #595656;
    font-size: 16px;
    background: #f8f8f8;
    border-radius: 7px;
    margin-bottom: 5px;
    height: 50px;
    padding: 0px 20px;
}

.location-list ul li:hover {
    color: #ffffff;
    background: #72624f;
}

.location-list .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #d45d38;
}

.no-padding {
    padding: 0px;
}

.continue a {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 20px
}

.ownvenue-inner-div {
    max-width: 350px;
    margin: 40px auto 0;
}

.errorInfo-div {
    display: none;
    background: #ef1616;
    padding: 5px;
    color: #fff;
}

.event-type-maindiv.two-button-row a {
    border-radius: 4px;
    font-size: 19px;
    padding: 22px 10px;
    background: #4f868f
}

.hall-select-popup {
    background: #000;
    padding: 60px 50px 50px;
    width: auto;
    margin: 20px auto;
    border: 1px solid #869f6e;
    -webkit-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, .23);
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, .23);
    text-align: center;
    color: #fff;
    max-width: 580px;
    position: relative;
}

.hall-select-popup .pop-title {
    font-size: 26px;
    text-transform: uppercase;
    line-height: 1;
    margin: 5px 0 10px;
    color: #fff
}

.hall-select-popup p {
    margin: 0;
    
}

.hall-select-popup .button {
    width: 100%;
    display: block;
    margin: 20px 0 0 0;
    background: #5d8d87;
}

.hall-description {
    padding: 10px 0
}

.hall-select-popup .mfp-close-btn-in .mfp-close {
    color: #fff;
    opacity: 1
}

h3.event-datetime-string {
    font-size: 20px;
    
    margin: 10px 0 0 0;
}

.ccategories_tabsec {
    padding: 26px 0 330px;
}

.number-break {
    padding: 28px 28px 28px 94px;
    margin: 0px 0px 40px;
    position: relative;
    border: 1px solid #000000;
    background: #000000;
    color: #fff
}

.number-break .back_bx {
    position: absolute;
    left: 26px;
}

.back_bx {
    background: #f5f5f5;
    width: 68px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1;
    position: relative;
    border-radius: 5px;
    padding: 54px 10px 12px;
    margin: 0;
    cursor: pointer;
    
}

.back_bx:after {
    position: absolute;
    top: 8px;
    left: 0;
    font-family: FontAwesome;
    font-size: 40px;
    content: "\f104";
    width: 100%;
    line-height: 1;
}

.back_bx:hover {
    background: #000;
    color: #fff
}

.number-break h2 a {
    margin: 0;
    position: absolute;
    bottom: 5px;
    content: "";
    left: 0;
    right: 0;
    color: #d45d38
}

.break-time {
    width: 31%;
    padding: 5px;
    border-right: 1px solid #cfbfaa;
    text-align: center
}

.number-break {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.break-time-removed {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 19px
}

.break-time .title4,
.making-catering .title4 {
    margin-bottom: 10px
}

.making-catering {
    width: 36%;
    float: left;
    padding: 5px 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.17)
}

.making-catering p {
    margin: 0;
}

.making-place {
    
    font-size: 19px;
    color: #fff;
}

.making-place.making-place-padding {
    padding-left: 5px;
}

.change-location a {
    text-decoration: underline;
    font-size: 17px;
    color: #e87b1e;
}

.change-location a:hover {
    color: #fff;
}

.event-time {
    width: 33%;
    padding: 5px 0px 5px 20px;
}

.event-time .title4 {
    margin-bottom: 3px;
}

.event-time .row {
    margin: 0 -4px;
}

.event-time [class*="col-"] {
    padding: 0 4px;
}

.event-time .form-group {
    margin: 0;
}

.event-time .form-control {
    height: 57px;
}

.order-select-brktime ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.order-select-brktime ul .time-cls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.order-select-brktime ul .time-cls .form-group {
    margin: 0;
    position: relative
}

.order-select-brktime ul .time-cls label {
    width: calc( 100% - 200px);
    font-size: 18px;
    
    color: #020202;
    text-transform: uppercase
}

.viewmenu-top-div {
    margin: 10px 0 0 0;
}

.viewmenu-top-div .back_bx {
    padding: 36px 10px 12px;
}

.viewmenu-top-div .back_bx:after {
    font-size: 30px;
    top: 5px;
}

.break-time-removed .event-time .title4,
.break-time-removed .making-catering .title4,
.break-time-removed h3.event-datetime-string {
    color: #fff;
    font-size: 20px;
}

.break-time-removed .making-catering {
    width: 50%
}

.break-time-removed .event-time {
    width: 50%
}

.row-pgk-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.row-pgk-list > li {
    width: 25%;
    border: 4px solid #fff;
    position: relative;
    overflow: hidden
}

.pkselect_tabsec > .nav-tabs {
    border-bottom: 4px solid #4f868f;
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.pkselect_tabsec > .nav-tabs>li {
    margin: 0;
}

.pkselect_tabsec > .nav-tabs>li>a {
    background: #d2d0d0;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #060203;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 13px 38px;
    display: block
}

.pkselect_tabsec > .nav-tabs>li.active>a {
    border-radius: 5px 5px 0 0;
}

.pkselect_tabsec > .nav>li>a:focus {
    background: none;
}

.pkselect_tabsec > .nav-tabs>li.active>a,
.pkselect_tabsec .nav-tabs>li.active>a:focus,
.pkselect_tabsec .nav-tabs>li.active>a:hover {
    border: 0;
    background: #4f868f;
    color: #fff;
}

.pkselect_tabin {
    position: relative;
    padding: 20px 0 0;
}

.pkselected_filtersec {
    margin: 0 0 15px;
}

.pkselected_filtersec label {
    display: inline-block;
    font-size: 18px;
    color: #010101;
    padding-right: 16px;
    vertical-align: middle;
    margin: 0;
    
    text-transform: uppercase
}

.pkselected_filtersec .form-group {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
}

.pkselected_filtersec .form-control {
    height: 48px;
    margin-bottom: 0
}

.pkselected_package label {
    position: relative;
    padding: 15.5px 26px 15.5px 0px;
}

.pkselected_package label i {
    font-size: 45px;
    position: absolute;
    left: 20px;
    top: 11px;
    line-height: 0.83;
}

.pkselected_package .form-group {
    min-width: 360px;
}

.pkselected_package .chosen-container-single .chosen-single {
    height: 56px;
    line-height: 56px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0 0 0 25px;
}

.pkselected_package .chosen-container-single .chosen-single span {
    margin-right: 64px;
}

.pkselected_package .chosen-container-single .chosen-single div {
    width: 64px;
}

.pkselected_break .form-group {
    min-width: 245px;
}

.pkg_gimg,
.pkg_gitem {
    position: relative;
    height: 100%;
}

.pkg_gimg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.pkg_ginfo {
    background: rgba(0, 0, 0, 0.35);
    padding: 45px 15px 45px;
    color: #fff;
    line-height: 1.2;
    font-size: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

.pkg_ginfo.catbox-bottom-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
}

.row-pgk-list > li:hover .pkg_ginfo,
.pkg_gitem.category-active .pkg_ginfo {
    visibility: visible;
    opacity: 1;
}

.pkg_ginfo h4 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 9px;
    color: #fff;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.row-pgk-list > li:hover .pkg_ginfo h4,
.pkg_gitem.category-active .pkg_ginfo h4 {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.pkg_ginfo p {
    margin: 0;
}

.row-pgk-list > li:hover .pkg_ginfo p,
.pkg_gitem.category-active .pkg_ginfo p {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.pkselected_catgrytext {
    text-align: center
}

.pkselected_catgrytext .title4 {
    
    font-size: 21px
}

.pkgselect_dd {
    text-align: left;
    display: none;
}

.pkgselect_dd .chosen-container {
    font-size: 18px;
}

.pkgselect_dd .chosen-container-single .chosen-single {
    background: #ecdfcc;
    border-color: #d0cfcb;
    border-radius: 2px;
    box-shadow: none;
    -webkit-box-shadow: none;
    font-size: 18px;
}

.pkgselect_dd .chosen-container-single .chosen-single span {
    font-weight: 300;
    color: #41403e;
}

.pkgselect_dd .chosen-container-single .chosen-drop {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #f1ece6;
    background: #f6f4f4;
}

.pkgselect_dd .chosen-container .chosen-results li {
    padding: 15px 18px;
    border-top: 1px solid #f1ece6;
}

.pkgselect_dd .chosen-container .chosen-results li:first-child {
    border-top: 0;
}

.pkgselect_dd .chosen-container .chosen-results .highlighted {
    color: #41403e;
}

.pkg_gbtsec .button {
    font-size: 17px;
    width: 100%;
    margin: 0;
    padding: 14px 5px;
    background: #e87b1e;
}

.pkg_gbtsec .button:hover {
    background: #000;
}

.pkg_gitem.highlighted .pkg_ginfo {
    background: rgba(11, 4, 5, 0.82);
}

.pkg_gitem.highlighted .pkg_ginfo .btn {
    display: none;
}

.pkg_gitem.highlighted .pkg_ginfo .pkgselect_dd {
    display: block;
}

.pkg_gitem.highlighted .pkg_ginfo .btn {
    opacity: 0;
    pointer-events: none;
}

.catbox-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.row-pgk-list > li:hover .catbox-bottom,
.pkg_gitem.category-active .catbox-bottom {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.row-pgk-list > li.category-active {
    border-color: #e87b1e;
}

.row-pgk-list > li.category-active .pkg_gbtsec .button {
    background: #e87b1e;
}

.pkg-detail-top-row,
.goto-chkpage-cls,
.viewmenu-btn-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.pkg_gcol {
    width: 25%;
    border: 4px solid #fff;
}

.pkg_gridsec .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.pkg_gbtsec.text-center {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.pkg_gcol .choose-single-btn {
    background: #d45d38;
    display: block;
    padding: 11px 10px;
    color: #fff;
    text-transform: uppercase;
}

.pkg-intuil-list .pkg_ginfo {
    opacity: 1;
    visibility: visible;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    color: #fff
}

.package-overlay-header h3 {
    color: #fff;
    font-size: 22px;
    margin: 0 0 10px 0
}

.package-overlay-header span {
    
}

.package-overlay-body {
    
    font-size: 22px;
}

.package-overlay-body sup {
    font-size: 15px;
    text-transform: uppercase;
    top: -6px;
    position: relative;
}

.pkg_gcol .related-tags {
    z-index: 5;
    position: static
}

.pkg-intuil-list .pkg_ginfo p {
    -webkit-transform: none;
    transform: none;
    margin: 10px 0
}

.package-overlay-header {
    margin: 0 0
}

#our-signature-dish span {
    position: relative;
    display: inline-block;
    margin: 0 0 0 15px;
    top: -2px;
    cursor: pointer;
    background: #000;
    padding: 6px 13px;
    border-radius: 5px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    line-height: 1;
}

#our-signature-dish span:hover {
    background: #d45d38;
}

#our-signature-dish span img {
    height: 17px;
    vertical-align: middle;
}

.upkg_tabsec {
    padding: 26px 0 8px;
}

.pkselect_tabin .bx_out {
    margin: 0 0 5px;
}

.bx_in {
    background: #fff;
    padding: 50px 70px 40px;
    border: 2px solid #d2d0d0;
    border-radius: 6px;
}

.pkselect_tabin .pkg_listsec {
    margin: 0 0 8px;
}

.pkg_listsec .bx_in {
    padding: 10px 0 10px 17px;
}

.pkglist_item {
    color: #989898;
}

.pkglist_itemtable {
    display: table;
    width: 100%;
}

.pkglist_itemtable > div {
    display: table-cell;
    vertical-align: top;
}

.pkglist_img {
    width: 33%;
    padding: 13px 0;
}

.pkglist_info {
    width: 50%;
    padding: 20px 22px 0;
}

.pkglist_infott h3 {
    margin: 0 0 7px;
    color: #010101;
    font-size: 18px;
    height: 22px;
    overflow: hidden;
}

.pkglist_infott p {
    font-size: 16px;
}

.pkglist_pricebx {
    width: 17%;
    border-left: 1px solid #e4e4e4;
    padding: 0 10px;
}

.pkglist_itemtable .pkglist_pricebx {
    vertical-align: middle;
}

.pkglist_pricebx > span {
    font-size: 18px;
}

.pkglist_pricebx > b {
    font-size: 22px;
    font-weight: 700;
    color: #1a1a1a;
    margin: 0 0 4px;
}

.pkglist_pricebx > div > span {
    font-size: 18px;
}

.pkglist_pricebx > div > b {
    font-size: 22px;
    font-weight: 700;
    color: #1a1a1a;
    margin: 0 0 4px;
}

.package_checkbx .bx_in {}

.package_formtt {
    font-size: 18px;
    color: #020202;
    margin: 0 0 20px 0px;
}

.package_formtt1 {
    margin: 0 0 15px;
    position: relative;
}

.package_formtt span {
    display: block;
    margin: 0 0 5px;
    text-transform: uppercase;
}

.package_formtt small {
    display: block;
    color: #333;
    font-size: 13px;
    font-style: italic;
}

.package_checkrow {
    margin-bottom: 40px;
}

.package_checkitem {
    border: 2px solid #f5f5f5;
    padding: 32px 38px;
}

.package_checkitem .package_formtt {
    border-bottom: 1px solid #f5f5f5;
    padding: 0 0 10px;
}

.package_formchecklist {
    list-style: none;
    padding: 0;
    margin: 0;
}

.package_formchecklist li {
    margin: 0 0 11px;
}

.package_formchecklist li.modfr_valuecls span {
    text-transform: capitalize;
}

.package_formcheck_inline {
    padding-left: 29px;
}

.package_formcheck_inline:after {
    content: "";
    display: block;
    clear: both;
}

.package_formcheck_inline li {
    width: 50%;
    float: left;
    padding: 1px 15px 0 0;
    margin: 0 0 1px;
    height: 40px;
    overflow: hidden;
}

.package_formbx .form-group.last {
    border: 0;
}

.package_formchecklist .custom_checkbox span {
    font-size: 18px;
    font-weight: 700;
    color: #363636;
    padding: 5px 0 0 43px;
}

.package_addonbx .custom_checkbox span b {
    font-weight: 700 !important;
    height: 22px;
    overflow: hidden;
}

.package_formchecklist .custom_checkbox span:before {
    border-width: 2px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border-color: #b1b1b1;
}

.package_formchecklist .custom_checkbox input[type=checkbox]:checked + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    text-align: center;
    background: #e87b1e;
    border-color: #e87b1e;
}

.package_formchecklist .custom_checkbox span b {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;
    display: block;
}

.pkgerror_out {
    position: relative;
    border: 2px solid #ff1313;
}

.pkgerror_out .pkgerror_txt {
    background: #ff1313;
    padding: 5px 10px;
    position: absolute;
    bottom: -27px;
    left: 0;
    right: 0;
    font-size: 13px;
    color: #fff;
    max-width: 220px;
    text-align: center;
    margin: 0 auto;
    line-height: normal;
    z-index: 11;
}

.pkgerror_out.up .pkgerror_txt {
    top: -30px;
    bottom: auto;
}

.pkgaddon_checklist .pkgerror_out.custom_checkbox {
    border-color: #ecdfcc;
}

.pkgaddon_checklist {
    margin: 0 -3.5px;
}

.pkgaddon_checklist li {
    width: 25%;
    float: left;
    margin: 0 0 7px;
    padding: 0 3.5px;
}

.pkgaddon_checklist .custom_checkbox {
    border: 3px solid #f5f5f5;
    display: block;
    border-radius: 6px;
}

.pkgaddon_checklist li.active .custom_checkbox {
    background: #809b66;
    border-color: #ecdfcc;
}

.package_addonbx .custom_checkbox input {
    height: calc(100% + 6px);
    height: -webkit-calc(100% + 6px);
    width: calc(100% + 6px);
    width: -webkit-calc(100% + 6px);
}

.pkgaddon_checklist .custom_checkbox span {
    padding: 18.5px 15px 18.5px 72px;
}

.pkgaddon_checklist .custom_checkbox span:before {
    border-color: #e87b1e;
    color: #e87b1e;
    font-size: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 21px;
    border-radius: 50%;
    text-align: center;
    line-height: 27px;
    content: "\f067";
    font-family: FontAwesome;
    font-weight: 100;
}

.pkgaddon_checklist .custom_checkbox input[type=checkbox]:checked + span {
    color: #fff;
}

.pkgaddon_checklist .custom_checkbox input[type=checkbox]:checked + span:before {
    content: "\f00c";
    background: #334845;
    border-color: #bdbfbf;
    font-size: 17px;
}

.pkgsetup_listrw {
    padding: 0 0 12px;
    margin-bottom: 11px;
    border-bottom: 1px solid #dfdddd;
}

.pkgsetup_listrw:last-child {
    border: 0;
    margin: 0;
    padding: 0;
}

.package_controltt .custom_radio span {
    font-size: 18px;
    font-weight: 700;
    color: #363636;
    padding: 5px 0 0 43px;
}

.package_controltt .custom_radio span:before {
    width: 30px;
    height: 30px;
    border-width: 2px;
}

.package_controltt .custom_radio input[type=radio]:checked + span:before {
    border-color: #637550;
    border-width: 2px;
    background: #869f6e;
}

.pkgsetup_list .package_formchecklist {
    padding-left: 41px;
    margin: 14px 0 0;
}

.pkgsetup_list .package_formchecklist li {
    margin: 0 0 16px;
}

.pkgsetup_list .package_formchecklist .custom_checkbox span {
    padding: 0 0 0 30px;
    font-size: 16px;
    font-weight: 300;
}

.pkgsetup_list .package_formchecklist .custom_checkbox span:before {
    width: 23px;
    height: 23px;
}

.pkgsetup_list .package_formchecklist .custom_checkbox input[type=checkbox]:checked + span:before {
    line-height: 20px;
}

.package_form_helptext {
    font-style: italic;
    font-size: 16px;
}

.package_descbx textarea {
    height: 115px;
    padding-top: 14px;
}

.upkg_tabsec .chk-payment-btn {
    margin: 10px 0 0;
}

.btn_download {
    font-size: 18px;
    color: #fff;
    padding: 8px 20px 7px;
    height: 38px;
    border: 0;
    border-radius: 30px;
    background: #d45d38;
    -webkit-box-shadow: 0 2px 0px 0px #0b0405;
    box-shadow: 0 2px 0px 0px #0b0405;
    margin: 6px 0px 12px;
}

.btn_download:hover {
    background: #ecdfcc;
    color: #0b0405;
    -webkit-box-shadow: 0 2px 0px 0px #b1b1b1;
    box-shadow: 0 2px 0px 0px #b1b1b1;
}

.product_modifiers_listinner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.product_modifiers_listinner .modifiers_main_list {
    width: 48%;
    margin: 0 0 20px 0
}

.package_checkitem {
    border-radius: 8px
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a {
    width: 49%
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a:first-of-type,
#PackageEditMdl .chk-payment-btn .col-md-cls:first-of-type a,
.goto-chkpage-cls a:first-of-type,
.viewmenu-btn-row a:first-child {
    background: #e87b1e
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a:hover,
#PackageEditMdl .chk-payment-btn .chk-payment-btn-row a:hover,
.goto-chkpage-cls a:first-of-type:hover,
.viewmenu-btn-row a:first-child:hover {
    background: #000
}

.pkg_total_box .pkg_total_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    
    font-size: 20px
}

.pkg_total_box .pkg_total_row.grand_total {
    font-size: 28px;
}

.pkg_total_box .cc-addon-subtotal-lhs {
    width: 140px;
}

.pkg_total_box .cc-addon-subtotal-rhs {
    width: 180px;
}

.pkselected_break .form-group .custom-select-inner select {
    border: 0;
    height: 46px;
    position: relative;
    top: 1px;
    right: 1px;
}

.pkselected_break .form-group .custom-select-inner {
    background: #fff;
    padding-left: 40px;
}

.checkout_time .custom-select-inner {
    background: #fff;
    padding-left: 40px;
}

.checkout_time .custom-select-inner select {
    padding: 6px 0;
    border: 0;
    height: 46px;
    position: relative;
    top: 1px;
    right: 1px;
}

.goto-chkpage-cls a,
.viewmenu-btn-row a {
    width: 49%;
    margin: 0
}

.goto-chkpage-cls,
.viewmenu-btn-row {
    margin: 10px 0 0 0;
}

.addon_popup {
    max-width: 846px;
    border: 1px solid #5d8d87;
}

.addon_popup .pouup_in {
    padding: 45px 9px 30px;
}

.addon_poptop {
    padding: 0 49px;
    margin: 0 0 30px;
}

.addon_listrw {
    width: 100%;
    margin: 0 0 15px;
    padding: 0 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.addon_linfo {
    width: 55%;
    color: #020202;
}

.addon_linfo h4 {
    font-size: 15.5px;
    color: #060606;
    margin: 0 0 5px;
}

.addon_linfo p {
    font-size: 15.5px;
    line-height: 1.2;
    margin: 0;
}

.addon_lright {
    width: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cross_multi {
    font-size: 16px;
    color: #fff;
    margin: 0 9px 0 0;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 10px 3px;
    width: 42px;
    z-index: 1;
    text-align: center;
}

.cross_multi:before {
    content: "";
    background: #5d8d87;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 3px;
    -webkit-transform: skewX(-10deg);
    transform: skewX(-10deg);
    -webkit-transform: -webkit-skewX(-10deg);
    transform: -webkit-skewX(-10deg);
}

.addon_price {
    font-size: 19px;
    color: #000000;
    margin: 0 20px 0 0;
    display: inline-block;
    vertical-align: middle;
    
}

.addon_lright .btn {
    border-radius: 4px;
    font-size: 17px;
    margin-bottom: 0;
    vertical-align: middle;
}

.addon_lright .qty_bx {
    width: 109px;
    height: 33px;
    margin: 0 5px 0 0;
    vertical-align: middle;
}

.addon_lright .qty_bx span {
    background: #fff;
    width: 35px;
    height: 33px;
    line-height: 31px;
    letter-spacing: -1px;
    font-size: 20px;
}

.addon_lright .qty_bx input {
    font-size: 16px;
}

.addon_lright .btn_grey {
    background: #4c4c4c;
    color: #fff;
}

.addon_lright .btn_grey:hover {
    background: #1c1c1c;
}

.addon_popbot .btn {
    min-width: 140px;
    font-size: 17px;
    margin: 0;
}

.addon_popbot .btn_grey {
    background: #5a5a5a;
    color: #fff;
}

.addon_popbot .btn_grey:hover {
    background: #1c1c1c;
}

.addon_lright .button {
    margin: 0;
    font-size: 15px;
    padding: 14px 20px 13px;
}

.addon_poptop_header {
    border-bottom: 2px solid #efefef;
    margin: 0 0 40px 0;
}

.addon_poptop_header h3 {
    margin: 0 0 15px 0;
    font-size: 24px;
}

.addon_poptop_header h3 small {
    display: block;
    color: #333;
    font-size: 12px;
    font-style: italic;
    padding-top: 10px;
}

.addon_popbot .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.addon_popbot .row-replace .button {
    margin: 0 0 0 10px
}

.addon_popbot {
    border-top: 1px solid #e4e4e4;
    padding: 30px 0 0 0;
    margin: 0 49px;
}

.addon_popbot .cal-md-cls:first-of-type .common-mfd-closefun {
    background: #e87b1e
}

.addon_popbot .row-replace .button:hover {
    background: #000
}

.catering_checout .checkout-total {
    width: 100%;
}

.catering_checout .checkout-total .fc-half {
    width: 45%;
}

.catering_checout .checkout-total .controls-single input[type="text"] {
    background: #ffffff;
}

.catering_checout .checkout-control-group-bottom {
    margin: 30px 0 0 0
}

.catering_checout .checkout-control-group-middle .col-md-cls {
    padding: 0
}

.catering_checout .checkout-control-group-middle .form-group .form-control {
    margin: 0
}

.order_details .panel-group .panel+.panel {
    margin-top: 0px;
}

.order_details .panel-group {
    padding: 30px 0px 0px;
    margin-bottom: 0px;
}

.order_details .panel-default {
    border-color: transparent;
}

.order_details .panel {
    background-color: #f6f6f6;
    border-radius: 0px
}

.order_details .panel-default>.panel-heading {
    color: #fff;
    background-color: #000;
    border-color: #000;
    padding: 12px 15px 12px 15px;
    border-radius: 0px;
}

.order_details .panel-title>a {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
}

.order_details .panel-title>a .clck {
    text-align: right;
    font-weight: 300;
    font-style: italic;
    float: right;
    font-size: 14px;
    margin-right: 30px;
    margin-top: 2px;
}

.order_details .panel-title>a:hover {
    color: #fff;
}

.order_details .panel-title {
    position: relative;
}

.order_details .panel-title:after {
    content: '';
    position: absolute;
    right: -9px;
    background: #fff;
    height: 2px;
    top: 0;
    width: 18px;
    -webkit-transform: rotate(90deg) translate(10px, 10px);
    transform: rotate(90deg) translate(10px, 10px);
}

.order_details .panel-title:before {
    content: '';
    position: absolute;
    right: 7px;
    background: #fff;
    height: 2px;
    top: 0;
    width: 18px;
    -webkit-transform: rotate(0deg) translate(6px, 10px);
    transform: rotate(0deg) translate(6px, 10px);
}

.order_details .act .panel-title:after {
    opacity: 0;
}

.order_details .panel-collapse {
    height: 670px;
}

.checkout_time {
    padding: 0px 0px 20px;
}

.checkout_time label {
    color: #000000;
    line-height: 40px;
    padding: 4px 0px;
    font-size: 17px;
    text-transform: uppercase;
    
}

.order_details .edit {
    background: #809b66;
    border-radius: 3px;
    margin-right: 5px;
    display: inline-block;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    position: absolute;
}

.order_details .edit .fa-pencil-square-o {
    font-size: 18px;
    color: #fff
}

.package {
    padding: 0px 0px 5px 0px;
}

.package .title {
    
    color: #000000;
    font-size: 20px;
    padding-left: 30px;
}

.package a {
    line-height: 1.6;
}

.package .catqty_bx .qty_minus {
    background: #fff;
}

.package .catqty_bx .qty_plus {
    background: #fff;
}

.package .package_cnt {
    padding: 0px 0px 25px;
}

.package .package-list {
    padding: 0px 0px 15px 40px;
    color: #000;
}

.package .package-list .one {
    font-weight: 700;
    font-size: 16px;
    margin: 0px;
}

.package .package-list .two {
    font-weight: 300;
    font-size: 16px;
    margin: 0px;
}

.addon {
    padding: 20px 0px 15px 40px;
}

.addon h4 {
    margin: 0px 0px 10px 0px;
    color: #000;
    font-size: 18px;
    
}

.addon_list h5 {
    margin: 10px 0px 10px 0px;
    line-height: 1.6;
}

.addon_list p {
    margin: 0px 0px 5px 40px;
    font-size: 16px;
    color: #000;
}

.addon_list h5 .title {
    color: #000;
    font-size: 17px;
    padding-left: 35px;
    display: block;
    
}

.amt {
    text-align: right;
    margin-top: 0px;
}

.amt .remove {
    background: #333;
    border-radius: 3px;
    margin-left: 10px;
    width: 21px;
    height: 21px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    color: #fff
}

.amt span {
    
    color: #000;
}

.buffet_setup {
    padding: 20px 0px 30px;
}

.buffet_setup h5 {
    margin: 10px 0px 5px 0px;
    line-height: 1.6;
}

.buffet_setup h5 .title {
    color: #000000;
    font-size: 20px;
    padding-left: 35px;
    
}

.buffet_setup p {
    margin: 0px 0px 7px 40px;
    font-size: 16px;
}

.total_amt {
    background: #1c1c1c;
    padding: 35px 70px 30px 50px;
}

.total_amt .subtotal {
    font-size: 16px;
    color: #fff;
    
}

.total_amt .total {
    font-size: 25px;
    color: #fff;
    
    text-transform: uppercase;
}

.total_amt .rt {
    text-align: right;
}

.brline {
    background: #f5f5f5;
    height: 2px;
    margin-left: 40px;
}

.download-quote {
    padding: 20px 0px 0px;
    text-align: center;
}

.download-quote .btn {
    font-size: 18px;
    color: #fff;
    padding: 7px 20px;
    border: 0;
    background: #d45d38;
}

.catering_thak_you .tnk-header {
    text-align: unset;
}

.catering_thak_you .tnk-detail {
    text-align: center;
}

.catering_thak_you .tnk-header .tick {
    text-align: center;
}

.catering_thak_you .order_details .qtybxs {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
    line-height: 38px;
    text-align: right;
}

.catering_thak_you .package .package-list {
    padding: 0px 0px 15px 0px;
}

.catering_thak_you .package .title {
    border-bottom: 1px solid #000;
}

.catering_thak_you .brline {
    margin-left: 0px;
}

.promotion_popup {
    font-size: 16px;
    max-width: 650px;
}

.promo_poptt {
    margin: 0 0 30px;
}

.promo_poptt .title1 {
    margin-bottom: 8px;
}

.popoff_bar {
    padding: 10px 0 15px;
    border: 1px solid #ddd;
    border-right: 0;
    border-left: 0;
    margin: 0 0 35px;
}

.popoff_bar span {
    display: block;
    font-size: 36px;
    font-weight: 700;
    color: #000;
}

.popoff_bar b {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
}

.popwel_formsec {}

.popwel_formsec h4 {
    margin: 0 0 10px;
}

.popwel_inputbx {
    position: relative
}

.popwel_inputbx .form-control {
    height: 51px;
    font-size: 16px;
}

.popwel_inputbx .btn {
    position: absolute;
    right: 0;
    top: 0;
    border-color: #ecdfcc;
}

.choose_location.pkgerror_out .pkgerror_txt {
    max-width: 290px;
}

.sub_tab_mobtrigger {
    display: none;
}

.rating-option {
    position: relative;
    display: inline-block;
}

.rating-option input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.rating-option span {
    padding: 5px 0 0 40px;
}

.rating-option span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 2px solid #aba8a3;
    left: 0;
    top: 3px;
    pointer-events: none;
    line-height: 21px;
    border-radius: 50%;
}

.rating-option input[type=radio]:checked + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    text-align: center;
    background: #72624f;
    border-color: #ecdfcc;
}

.contact_left p {
    margin: 0 0 10px;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}

#pay-conf-popup .process_inner {
    text-align: center;
}

#pay-conf-popup .process_inner .btn-block {
    display: inherit;
    width: auto;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.header_middle .container {
    position: relative;
}

.htico_search {
    margin-left: 15px !important;
}

.htico_search > a {
    position: relative;
    display: block;
}

.header_bot {
    display: inline-block;
}

.home-banner-full {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.header-top {
    background: #000;
    text-align: center;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-top p {
    color: #fff;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0px;
}

.logo {
    width: 100%;
    z-index: 99;
    max-width: 220px
}

.slick-slide {
    position: relative;
}

.home-ordernow-main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.featured-products-section {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.highlight-products{padding: 80px 0 0 0;}
.fp-title{ max-width: 960px; margin: 0 auto; padding-bottom: 40px;}

.fp-title h3 {
    color: #000;
    margin-bottom: 15px;
    font-size: 50px;
    letter-spacing: 0;
    font-family: 'Honey Candy';
    font-weight: normal;
    text-transform: uppercase;
}

.fp-title p {
    font-size: 15px;
    color: #000;
    margin: 0;
}

.price-tag h6 {
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    color: #fff;
    background-color: #4f9a97;
    width: 150px;
    height: 35px;
    line-height: 35px;
    text-transform: uppercase;
    float: left;
}

.featured-products-single img {
    position: relative;
}

.price-tag {
    position: absolute;
    top: 0px;
}

.related-tags ul,
.product-tags-list ul {
    list-style: none;
    padding: 0 0 0 5px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.related-tags ul li,
.product-tags-list ul li {
    
    font-size: 13px;
    padding: 3px 10px;
    background-color: #000;
    border: 1px solid #fff;
    text-transform: uppercase;
    border-radius: 30px;
    color: #fff;
}

.product-tags-list ul li {
    margin-right: 5px;
}

.related-tags {
    float: left;
    position: absolute;
    bottom: 15px;
}

.product-tags-list {
    float: left;
    position: absolute;
    bottom: 15px;
}

.review-tags {
    float: right;
    position: absolute;
    bottom: 4px;
    right: 0px;
}

.related-tags ul li,
.product-tags-list ul li {
    display: inline-block;
    margin-top: 5px;
    margin-right: 5px
}

.related-review-tag {
    position: relative;
}

.fa-star-o {
    color: #000;
}

.wppc-checked {
    color: red;
}

.wppc-rating-full {
    background-image: url("../images/star-bg.png");
    background-repeat: no-repeat;
    padding: 5px 10px;
    margin-top: 0px;
    margin-left: 44px;
}
.products-single-li {
    position: relative;
    z-index: 2;
    text-align: center;
    border: 2px solid #f3f3f3;
    height: 100%;
}
.featured-products-section .products-list-ulmain li {
    text-align: center;
    background: #fff;
    transition: all .4s linear;
    display: flex;
    flex-direction: column;
    border: 2px solid #f3f3f3;
    height: 100%;
}

.product-title h3,
.product-price h3, .innerproduct-item-text h4 {
    
    font-size: 18px;
    color: #0a0b10;
    margin: 0 0 10px 0;
}
.product-title h3, .innerproduct-item-text h4{
    font-weight: 600
}

.product-short-description {
    clear: both;
}

.product-short-description p {
    font-size: 15px;
    color: #949292;
    text-align: left;
    padding-top: 4px;
    margin-bottom: 0px;
    font-family: 'Poppins', sans-serif;
}

.featured-products-single {
    max-width: 350px;
    position: relative;
}

.product-title-discription {
    padding: 20px;
    background: #fff;
}

.product-title-price {
    position: relative;
    overflow: hidden;
}

.product-title-price::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    border: 1px dashed #dbdddd;
    width: 100%;
}

.featured-products-section {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.serving-passionately-main {
    position: relative;
    overflow: hidden;
    padding: 30px;
    border-radius: 20px;
    background: rgba(210, 190, 155, 0.38);
    border: 2px solid #f0f2ec
}

.serving-passionately-left-img {
  
    width: 50%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

.serving-passionately-left,
.serving-passionately-right {
    width: 50%;
}

.serving-passionately-left img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.serving-passionately-right {
    text-align: center;
    padding: 40px
}

.serving-passionately-right p {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: #121212;
    line-height: 25px;
}

.serving-passionately-right a,
.product-view-more a {
    font-size: 19px;
    margin: 5px auto 0;
}

footer {
    padding: 5px 0 0 0;
    position: relative;
    margin: 40px 0 0 0;
}

.fs-30{
    font-size:30px!important;
    color:#fff!important
}


.home-banner {
    position: relative;
    z-index: 2;
}

.home-banner .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 38px;
    height: 30px;
    text-indent: -9999px;
    z-index: 9;
    border: none;
    padding: 0;
    margin-bottom: 0
}

.featured-products-main .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 38px !important;
    height: 30px !important;
    text-indent: -9999px;
    z-index: 9;
    border: none;
}

.bx-wrapper {
    border: none !important;
    margin-bottom: 0px !important;
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.product-view-more {
    margin-top: 40px;
}
.featured-products-full {
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.slick-slide img {
    width: 100%;
}

.slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.slick-track:before,
.slick-track:after {
    display: none !important
}

.slick-track .slick-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: inherit;
}

.slick-track .slick-slide > div {
    height: 100%;
}

.slick-track .slick-slide .featured-products-single {
    height: 100%;
    background-color: #fff;
}

.featured-products-ordernow > .button {
    margin: 0 auto;
}

.featured-products-image {
    position: relative;
    overflow: hidden
}

.featured-products-ordernow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    visibility: hidden;
    z-index: 2;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s
}

.featured-products-single:hover .featured-products-ordernow {
    visibility: visible;
    opacity: 1
}

.featured-products-single .featured-products-image:after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    content: "";
    z-index: 1;
    width: 0%;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.featured-products-single:hover .featured-products-image:after {
    width: 100%;
}

.featured-products-single:hover .product-title-discription {
    background: #17191d
}

.slick-track .slick-slide .featured-products-single:hover {
    background: #17191d
}

.featured-products-single:hover .product-title h3,
.featured-products-single:hover .product-price h3 {
    color: #fff
}

.featured-products-single:hover .product-title-price::after {
    border-color: rgba(255, 255, 255, 0.3)
}

.bakery_slide {
    text-align: center;
    border: none;
    border-left: 0;
    border-radius: 5px;
    position: relative;
    width: 49%;
    margin: 10px 0.5%;
    overflow: hidden;
}

.bakery_slide img {
    object-fit: cover;
    object-position: center;
}
.bakery_slide span {
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
    z-index: 2;
    font-size: 35px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 30px;
        font-family: 'Honey Candy';
        text-transform: uppercase; 
}
.bakery_slider .slick-track .slick-slide > div {
    height: 100%;
    line-height: 1px;
}
.bakery_slide p a {
        padding: 0;
    display: block;
    font-size: 26px;
    text-transform: capitalize;
    font-weight: normal;
    color: #fff;
    position: relative;
    z-index: 1;
    border: 4px solid rgba(0,0,0,0);
    border-radius: 15px;
    overflow: hidden;
}
.bakery_slide p a:before{
    content: '';
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 40%);
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 15px;
}

.bakery_row .slick-next {
    font-size: 0;
    border: 0;
    outline: 0;
    position: absolute;
    background: #464646;
    right: 0px;
    top: 0;
    width: 48px;
    height: 44px;
    border-left: none;
    cursor: pointer;
    margin: 0
}

.bakery_row .slick-prev {
    font-size: 0;
    border: 0;
    outline: 0;
    position: absolute;
    background: #464646;
    left: 0;
    top: 0;
    width: 48px;
    height: 44px;
}

.bakery_row .slick-prev::after {
    content: "";
    background: url(../images/previous-icon.png) no-repeat;
    width: 38px;
    height: 30px;
    background-position: 2px 3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 11px;
    right: 0;
    margin: auto;
    background-size: 75%;
}

.bakery_row .slick-next::after {
    content: "";
    background: url(../images/next-icon.png) no-repeat;
    width: 38px;
    height: 30px;
    background-position: 4px 3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background-size: 75%;
}

.bakery_row {
    position: relative; margin: 0px 0 10px;
}

.innerproduct-col {
    width: 23%;
    margin-right: 0.6%;
    margin-bottom: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin: 0 0.7% 18px;
    box-shadow: 0 0 4px #cacaca;
    height: auto;
}
.product-list-main-div.innerproduct-col{ height: auto; }

.addcart_row {
    /* -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 5px;
    max-width: 320px;
    margin: 0 auto; */
    padding: 0 5px;
    margin: 0 auto;
    -webkit-align-items: center;
    align-items: center;
    max-width: 320px;
}

.addcart_row .qty_bx {
    width: 56%;
    vertical-align: middle;
    float: left;
}

.addcart_row .btn {
    margin: 0 0 0 2%;
    width: 42%;
    padding-left: 8px;
    padding-right: 8px;
    float: left;
    font-size: 14px;
    color: #fff;
    height: 38px;
    line-height: 38px;
    padding: 0px;
    border: none;
    cursor: pointer;
}

.addcart_row .btn:hover,
.addcart_row .btn:focus {
    background: #000;
}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
    opacity: 1;
}

.bakery-review .fa-star {
    color: #000;
}

.bakery-review .wppc-checked {
    color: #ed8a19;
}

.innerproduct-item-text p {
    line-height: 20px;
    font-size: 14px;
    color: #949292;
    /* padding-top: 10px; */
    padding-bottom: 10px;
    margin-top: 5px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.innerproduct-item-text h5 {
    font-size: 20px;
    
    color: #000;
    margin: 0 0 12px;
}

.singup-fb-icon i {
    font-size: 22px;
    vertical-align: middle;
    padding-right: 7px !important;
}

.signup-popup .popup-footer .btn {
    
    font-size: 14px;
    max-width: 225px;
    height: 45px;
    line-height: 45px;
    padding: 0px;
    border-radius: 0px;
}
.inside-popup-rhs .signup-popup-head .guest_btn{    
display: block;
max-width: 271px;
margin: 0 auto;
border-radius: 7px;
padding: 16px 22px;
height: 51px;
    font-size: 18px;
font-family: 'Poppins', sans-serif;
line-height: 1;    
}

.signup-popup .popup-body h4 {
    
    font-size: 14px;
}

.signup-popup .focus-out label,
.signup-popup .popup-body input {
    font-size: 14px;
    color: #595656;
}

.signup-popup .popup-body .sign_reg p {
    font-size: 12px;
    color: #0e0e0e;
    line-height: 20px;
}

.signup-popup .popup-body .custom_checkbox span:before {
    width: 20px;
    height: 20px;
    border: 2px solid #B1B1B1;
    margin-top: 5px;
}

.signup-popup .popup-body .custom_checkbox input[type=checkbox]:checked + span:before {
    background: none;
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    background: #809b66;
    border: 2px solid #809b66;
}

.signup-popup .popup-body .sign_reg {
    margin-left: 35px;
}

.signup-popup .popup-body .custom_checkbox {
    margin-top: 10px;
}

.signup-popup .popup-body .custom-pdpa-div,
.signup-popup .popup-body .custom-pdpa-div .custom_checkbox {
    margin-bottom: 0
}

.signup-popup .popup-body .custom-pdpa-div .sign_reg p {
    margin-top: 5px
}

.signup-popup .form-group .btn_minwid {
    font-size: 16px;
    text-transform: uppercase; font-weight: 600;
}

.signup-popup .pop-whole-lhs h3 {
    font-size: 45px;
    margin-bottom: 10px;
}

.signup-popup .pop-whole-lhs p {
    font-size: 13px;
}

.checkout-heading {
    background: #000;
    color: #fff;
    font-size: 22px; font-weight: 600;
    padding: 15px 10px;
    margin: 0 -30px;
    position: relative;
}

.productlist-main-div {
    min-height: 170px;
}

header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 991;
}

/*.common-top-div {
    padding-top: 97px !important
}*/


.header_middle {
    
}
header.small_sticky {
    background: #f0e3ca;
        box-shadow: 0 2px 16px 0 rgb(0 0 0 / 18%);
}
.header-bottom {
    display: flex; align-items: center;
    flex-wrap: wrap;
   padding: 25px 0;
    position: relative;
}

ul.hmenu_list li a.active {
    color: #fff;
}

ul.hmenu_list > li > a {
    font-family: "Poppins",sans-serif;
    font-size: 15px;
    text-transform: uppercase;
    padding: 20px 1px;
    display: block;
    transition: all .1s linear;
    line-height: 1;
    font-weight: 500; color: #000;
}

.hsign_sec > span {
    font-size: 16px;
    color: #000;
    vertical-align: text-bottom;
    position: relative;
    top: -1px;
    margin: 0 5px;
}

.hsign_sec a {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    padding: 10px;
}

.hsign_sec a:hover {
    color: #e87b1e;
}

.haction_sec {
    width: 45%;
    /* width: 50%; */
    text-align: center;
    position: relative;
}

.hmenu_actions {
    position: absolute;
    padding: 0;
    width: auto;display: flex;
    align-items: center; right: 20px; top: 50%;
    transform: translateY(-50%);
}

.htico_search> a > i {
    background: url(../images/search-icon.png) no-repeat center;
    width: 23px;
    height: 28px;
    display: block;
}
.htico_wishlist> a > i {
    background: url(../images/whishlist-icon.png) no-repeat center;
    width: 27px;
    height: 28px;
    display: block;
}
.hcartdd_trigger img {
    width: 26px;
    height: 28px;
    display: block;
}


header.small_sticky:before {
    height: 100%;
}
.hcartdd_trigger{padding-right:5px}
.hcart_round {
    background: #de5036;
    min-width: 16px;
    min-height: 16px;
    font-size: 8px;
    border-radius: 100%;
    position: absolute;
    right: 0px;
    top: -7px;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 12px;
}

.home-ordernow-single h3 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 0px;
}

.home-ordernow-single {
    text-align: center;
    position: relative;
    width: 25%;
    cursor: pointer;
}

img.blue-round {
    display: block;
    -webkit-transition: 0.5s linear all;
    transition: 0.5s linear all;
}

img.green-round {
    display: none;
    -webkit-transition: 0.5s linear all;
    transition: 0.5s linear all;
}

.home-ordernow-single:hover img.green-round,
.home-ordernow-single.active img.green-round,
.order_delivery_item li a:hover img.green-round,
.ordericon_link.active img.green-round {
    display: block;
    -webkit-transition-delay: 5s;
    transition-delay: 5s;
    -webkit-transform: rotate(380deg);
    transform: rotate(380deg);
}

.home-ordernow-single:hover img.blue-round,
.home-ordernow-single.active img.blue-round,
.order_delivery_item li a:hover img.blue-round,
.ordericon_link.active img.blue-round {
    display: none;
}

.home-ordernow-single-img .button {
    margin: 5px 0 0 0;
    font-size: 16px;
    padding: 12px 24px 12px;
}

.home-ordernow-single-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.home-ordernow-single-img img {
    max-height: 83px;
}

.home-ordernow-section {
    padding-bottom: 0!important;
    position: relative;
    border-radius: 0px 0px 20px 20px;
    z-index: 5;
    padding-top: 0;
    margin-bottom: 0;
}

.home-banner .slick-track .slick-slide > div {
   line-height: 0;
width: 100%;
}
.home-banner .slick-track {
    display: block !important;
}
.home-banner .slick-initialized .slick-slide {
    display: block !important; min-height: 501px;
}
.home-banner .slick-track .slick-slide > div >div {
    position: relative;
    overflow: hidden;
}
.home-banner .slick-initialized .slick-slide > div>div:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 8;
    width: 30%;
    height: 0;
    border-top: 2450px solid #f5f5f5;
    border-right: 500px solid transparent;
}
.home-badge-icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: -131px;
}

.home-ordernow-main {
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 60px;
}

.serving-passionately-right h3 {
    font-size: 52px;
    color: #000;
    line-height: 1;
    margin: 0 0 10px 0;
}

.featured-products-full .slick-dots {
    display: none !important
}

.checkout-terms-and-condition {
    height: 136px;
    overflow-y: auto;
    margin: 0 0 15px 0;
    font-size: 16px;
    border: 1px solid #d0cfcb;
    padding: 15px 10px
}

a.change-edt {
    color: #666;
    border-bottom: 1px solid #999;
    margin: 5px 0 0 0;
    display: inline-block;
    font-size: 16px;
}

a.change-edt:hover {
    color: #d45d38;
    border-color: #d45d38;
}

.page404-main-div {
    text-align: center;
}

.page404-main-div .gohome {
    display: inline-block;
    line-height: 1;
    font-weight: bold;
    background: #000;
    padding: 15px 50px;
    border-radius: 4px;
    text-align: center;
    background: #fdb23e;
    margin: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    color: #FFF;
}


.innerproduct-button .disabled {
    background: #d5d1d2 !important;
    color: #d45d38;
}

.bakery_row .slick-slide {
    background-color: transparent;
    display: block !important;
    width: 49% !important;
    margin: 10px 0.5%;
}



.bakery_row .slick-slide .bakery_slide.active a {
    color: #fff
}
.bakery_row .slick-slide .bakery_slide p a:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(0 0 0 / 30%);
    overflow: hidden;
}
.innerproducts-list-maindiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; justify-content: center;
}


.prodetailinner-main-div {
    min-height: 105px;
}

.product-detail-empty {
    padding: 52px 0px 35px 0px;
    text-align: center;
    color: #e87b1e;
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.custom-select-bxcls {
    position: relative
}

.custom-select-bxcls:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    height: 48px;
    pointer-events: none;
}

.custom-select-bxcls:after {
    height: 48px;
}

.custom-select-bxcls select {
    margin: 0 !important;
    width: 100%;
    height: 50px;
    font-size: 17px;
    padding-left: 10px;
}

.disable-addtocart {
    pointer-events: none;
    cursor: default;
}

.disable-addtocart:after {
    background: hsla(0, 0%, 100%, 0.8) none repeat scroll 0 0;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    content: '';
}

.modfr-alert-danger {
    display: none;
    background: #ffdfdf;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    color: #c10606;
}

.modfir_addcart_cls .qty_bx input {
    color: #000;
}

.mdf_common_rows {
    position: relative;
}

.compo_pro_acc {
    background: #F9F9F9;
}

.prd_chosen_row.compo_top_div {
    background: #FFF;
}

.compoMdfr_item_left {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.compoMdfr_item_left .product_chosen_addons {
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.compoMdfr_item_left .product_chosen_hea {
    margin: 0px;
}

.compoMdfr_item_left .form-group {
    width: 70%;
    float: left;
    margin: 0;
}

.compoMdfr_item_left .product_chosen_hea h6 {
    font-size: 14px;
}

.compopro_acc_head {
    padding: 12px 20px 12px 20px;
    cursor: pointer;
}

.compopro_acc_head::before,
.compopro_acc_head::after {
    background: #fff;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transition: -webkit-transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s, -webkit-transform 0.25s ease-in-out 0s;
    -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}

.compopro_acc_head::before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.compo_acc_active .compopro_acc_head::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.compopro_acc_head::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.compo_acc_active .compopro_acc_head::after {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}

.text-uppercase {
    text-transform: uppercase
}

.hcart_dropdown .qty_bx {
    width: 120px;
    float: left;
}

.cart_footer p,
.cart_footer span {
    margin: 0;
    font-size: 19px;
    color: #fff; font-weight: 600;
}

.cart_footer {
    padding: 20px 40px 20px 20px;
    background: #1c1c1c;
    margin-left: 20px;
    margin-right: 20px;
}

.cart_row.grant-total-cls p,
.cart_row.grant-total-cls span {
    font-size: 32px;
}

.cart_row.grant-total-cls sup {
    font-size: 80%;
    position: relative;
    top: -3px;
}

.cart_footer .cart_row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 5px
}

.cart-header-first h4 {
    
    color: #1c1b1b;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 5px 0
}

.cart-header-first p {
    color: #000000;
    font-size: 16px;
    margin: 0
}

.cart-header-second h5 {
    
    color: #1c1b1b;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0
}

.cart-header-second p {
    
    color: #1c1b1b;
    font-size: 28px;
}

.cart_footer .cart_remove {
    top: 2px;
    right: -30px;
}

.cart_footer .cart_remove:hover {
    background-color: #d45d38;
}

.product-details {
    background: #f5f5f5;
    padding: 15px 30px 15px 15px
}

.cart_body {
    padding: 20px 20px;
    padding-bottom: 0px;
}

.cartaction_bottom .btn {
    margin: auto;
    width: 100%;
    text-align: center;
    display: block;
    background: #e87b1e; font-weight: 600;
}

.cartaction_bottom .btn:hover {
    background: #000
}

.product-details:hover {
    background: #e6e6e6;
}

.cart-header-first .row-replace,
.cart-header-second .row-replace,
.hcart_tt .row-replace,
.product-details .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; 
    /* align-items: center;  */
}

.cart-header-first .row-replace .col-sm-cls,
.cart-header-second .row-replace .col-sm-cls {
    width: 50%
}

.cart_left {
    padding: 0 5px 0 0
}

.cart_right {
    padding: 0 0 0 5px
}

.cart_body .hcart_tt .row-replace {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.cart_body .hcart_tt {
    padding: 10px 10px 10px 15px;
}

.cart_body .hcart_tt h3 {
    
    text-transform: uppercase
}

.cart_body .hclear_cart {
    
}

.cart_body .hclear_cart:hover {
    color: #e87b1e
}

.product-details .row-replace .cart_right {
    width: 220px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: center
}

.product-details .cart_info h4 {
    
}

.product-details .row-replace .cart_left {
    width: calc(100% - 220px);
    width: -webkit-calc(100% - 220px); display: flex;
    align-items: center;
}

.compo_minmax_maindiv .combo-input-label {
    display: block;
}

.compo_minmax_maindiv .max-min-bar {
    padding-bottom: 10px;
    font-size: 18px
}

.compo_minmax_maindiv .error_combo_div {
    color: red;
    padding-bottom: 10px;
    display: none;
}

.compo_minmax_maindiv .combo_pro_price {
    color: #d35d38;
}

.alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;
}

.custom_close span {
    color: red;
}

.alert-success:before,
.single-danger:before {
    top: 10px;
    left: 8px
}

.jquery-success-msg {
    margin-left: 20px;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.btn-medium {
    min-width: 190px;
}

.cart_row.cart-header-second {
    margin: 15px 0 0 0;
}

.cart_row.cart-header-second h3 {
    
}

.cart-direction {
    margin: 0 0 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

.cart-direction:after {
    background-image: -webkit-gradient( linear, left top, right top, color-stop(50%, #949292), color-stop(0%, transparent));
    background-image: linear-gradient( to right, #949292 50%, transparent 0%);
    background-size: 8px 4px;
    background-repeat: repeat-x;
    background-position: 0% bottom;
    -webkit-animation-name: border-dance;
    animation-name: border-dance;
    -webkit-animation-duration: 25s;
    animation-duration: 25s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    content: "";
    position: absolute;
    left: 50px;
    display: block;
    height: 2px;
    right: 30px;
    margin: auto;
}

@-webkit-keyframes border-dance {
    from {
        background-position: 0% bottom;
    }
    to {
        background-position: 100% bottom;
    }
}

@keyframes border-dance {
    from {
        background-position: 0% bottom;
    }
    to {
        background-position: 100% bottom;
    }
}

.innersection_wrap {
    padding: 60px 0 0 0;
}

.tnk-chk-order .button {
    display: block;
    margin: 0;
    width: 100%;
    padding: 20px 20px 20px;
    font-size: 22px;
}

.tnk-chk-order .button:hover {
    background: #000;
}

.thank-order-detaildiv {
    max-width: 780px;
    margin: 0 auto;
    -webkit-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
}

.mainacc_toptext {
    text-align: center;
    padding: 0 0 20px 0;
    width: 100%
}

.mainacc_toptext h2 {
    margin: 10px 0 10px 0;
    font-size: 50px;
    letter-spacing: 0;
    font-family: 'Honey Candy';
    font-weight: normal;
    text-transform: uppercase;
}

.mainacc_toptext p {
    font-size: 22px;
    margin: 0;
    font-style: italic
}

#CartListMdl .total_amt {
    overflow: hidden;
    padding: 25px;
}

#CartListMdl .total_amt .subtotal {
    font-size: 17px
}

#CartListMdl .order_details .panel-group {
    margin: 0;
    padding: 0
}

#CartListMdl .package .title {
    padding-left: 0px
}

#CartListMdl .package .package_cnt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0px 0px 5px;
    border-bottom: 1px solid #cecece;
    margin: 0 0 15px 0;
}

#CartListMdl .package .package_cnt .col-sm-cls {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#CartListMdl .package .package_cnt .col-sm-cls .hr-flt-cls {
    font-size: 19px;
    margin: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#CartListMdl .addon {
    padding: 10px 0px 0px 10px;
}

#CartListMdl .package .package-list {
    padding: 0px 0px 5px 10px;
    color: #000;
}

#CartListMdl .buffet_setup {
    padding: 5px 0px 5px;
}

#CartListMdl .buffet_setup .row-replace,
#CartListMdl .addon .row-replace.addon_list,
#CartListMdl .total_amt .subtotal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#CartListMdl .buffet_setup h5 .title {
    padding-left: 10px
}

#CartListMdl .buffet_setup p {
    margin: 0px 0px 7px 25px;
}

#CartListMdl .brline {
    display: none
}

#CartListMdl .addon_list p {
    margin: 0px 0px 5px 20px;
}

#CartListMdl .addon_list h5 .title {
    padding-left: 20px;
}

#CartListMdl .order_details .panel-collapse {
    height: 600px;
}

#CartListMdl .Cart-list-mdlheader h2,
#PackageEditMdl .Cart-list-mdlheader h2 {
    text-align: center;
    font-size: 32px;
    margin: 5px 0 20px 0;
}

#CartListMdl button.close,
#PackageEditMdl button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
}

#CartListMdl button.close:before,
#CartListMdl button.close:after,
#PackageEditMdl button.close:before,
#PackageEditMdl button.close:after {
    position: absolute;
    left: 9px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #000;
    top: 0px;
}

#CartListMdl button.close:before,
#PackageEditMdl button.close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#CartListMdl button.close:after,
#PackageEditMdl button.close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#PackageEditMdl .bx_in {
    padding: 20px 20px;
}

#PackageEditMdl .package_checkitem {
    padding: 20px
}

#PackageEditMdl .chk-payment-btn {
    margin-bottom: 0
}

#PackageEditMdl .pkselected_filtersec .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#PackageEditMdl .pkselect_tabin {
    padding: 0;
}

#PackageEditMdl .pkselected_package label {
    position: relative;
    padding: 5px 0 0 0px;
}

.myacc_order_details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /* justify-content: center; */
}

.myacc_order_details .current_order {
    width: 48.5%;
    margin-bottom: 20px;
    background: #f9f9f9;
    padding: 0 0 120px 0;
    position: relative
}
.myacc_order_details .no-recrds-found{
    justify-content: center;
        text-align: center;
    margin: 0;
    width: 100%;
}
.myacc_head_sec {
    background: #000000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 15px;
    overflow: hidden
}

.head_right .head-group {
    z-index: 2;
    position: relative
}

.head_left h4 {
    color: #fff;
    margin: 0;
    font-size: 16px
}

.head_right h4 {
    color: #fff;
    margin: 0;
    
    text-transform: uppercase;
    font-size: 14px
}

.head_right::before {
    content: "";
    display: block;
    background: #535353;
    width: 42%;
    position: absolute;
    right: -30px;
    height: 100%;
    top: 0;
    -webkit-transform: skew(-39deg);
    transform: skew(-39deg);
    z-index: 1;
}

.order_details_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.order_btns,
.order_amt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.order_btns .button {
    margin: 0;
    width: 50%;
    display: block;
    padding: 16px 10px 16px; border-radius: 0;
}

.order_btns .print_invoice {
    background: #535353
}

.order_btns .view_recipt {
    background: #e87b1e;
}
.order_btns .view_recipt.button_full_view{width: 100%}
.order_btns .button:hover {
    background: #353535
}

.order_amt {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #000;
    padding: 10px 15px
}

.order_amt h3 {
    margin: 0;
    color: #fff;
    font-size: 20px
}

.order_amt-right sup {
    font-size: 75%;
    position: relative;
    top: -5px;
    margin: 0 2px 0 0;
}

.order_details_body {
    padding: 15px
}

.order_no_deatails {
    margin: 0 0 20px 0;
    text-align: center
}

.order_no_deatails span {
    display: block;
}

.delivery_total_left h3 {
    
}

.delivery_total_left img {
    margin: 0 0 10px 0
}

.delivery_total {
    position: relative;
}

.delivery_total_text::before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    height: 3px;
    margin: auto;
}

.web_order_typecls {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 35px 0
}

.web_order_typecls li {
    width: 32.5%
}

.web_order_typecls li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 17px 10px;
    background: #fff;
    color: #000
}

.web_order_typecls li.active a,
.web_order_typecls li a:hover {
    background: #e87b1e
}

.web_order_typecls li a h5 {
    margin: 0;
    color: #000;
    text-transform: uppercase
}

.sprite_lst_icon {
    margin: 0 10px 0 0
}


.myacc_order_details .reserv-orderlist {
    padding: 0;
    border-bottom: 2px solid #5d8d87
}

.myacc_order_details .reserv-orderlist .order_no_deatails {
    margin: 15px 0px 0;
    border-top: 1px solid #e4e4e4;
    padding: 20px 0 0 0;
}

.myacc_order_details .reserv-orderlist .order_no_deatails h3 {
    margin: 0;
    color: #000;
    font-size: 19px;
    text-transform: uppercase;
}

.myacc_order_details .reserv-orderlist .order_no_deatails p {
    margin: 0;
    color: #e87b1e;
    font-size: 18px;
    font-weight: 600;
}

.reserv-loader-div {
    display: inline-block
}

.reserv-orderlist .delivery_total.delivery_total_number .delivery_total_left h4 {
    font-size: 19px;
}

.reserv-orderlist .delivery_total.delivery_total_number .delivery_total_left h2 {
    margin-bottom: 5px
}

.reserv-orderlist .delivery_total.delivery_total_number {
    margin: 0;
    padding: 0
}

.web_order_typecls li:hover .count_num_t,
.web_order_typecls li.active .count_num_t {
    background: #ff8f30;
}

.ord-pagination-col select {
    width: auto;
    height: auto;
    padding: 1px 0 1px 1px;
    border: 0;
    margin: 0 5px;
    background: none;
}

.delivery_submit_div .button {
    width: 100%;
}

.mainacc_menulist #masterCount {
    background: #000;
    height: 23px;
    width: 25px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    margin-left: 5px;
    font-size: 12px;
    line-height: 25px;
    vertical-align: top;
    margin-top: 0px;
    padding: 0px 0px;
}

.reward-list-body .delivery_total.delivery_total_number {
    padding: 0;
    margin: 0
}

.reward-list-body .delivery_total_left {
    display: inline-block;
    width: auto;
    float: none;
    margin: 0 15px 0 0;
}

.reward-list-body .delivery_total.delivery_total_number .delivery_total_left h4 {
    font-size: 15px
}

.reward-list-body .order_amt_div h3 {
    margin: 0;
    font-size: 18px;
    padding: 10px 0 0 0;
}

.reward-list-body .order_amt_div h3 sup {
    font-size: 75%
}

.reward-list-body .order_amt-right h3 {
    color: #e87b1e;
}

.reward-list-body .checkoutDate,
.reward-list-body .checkoutTime {
    position: relative;
    padding: 0 0 0 22px;
    color: #474747 !important
}

.reward-list-body .checkoutDate:before {
    background: url(../images/calenderorder.svg) no-repeat;
}

.reward-list-body .checkoutTime:before {
    background: url(../images/clock-round.svg) no-repeat;
}

.reward-list-body .checkoutDate:before,
.reward-list-body .checkoutTime:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    content: "";
}

.reward-list-body .myacc_order_details .current_order {
    padding: 0 0 100px 0
}

.promo-form {
    padding: 30px 15px;
    background: #f6f6f6;
    margin: 20px;
    text-align: center;
}

.promo_wrap .both-order-tab {
    padding: 10px 40px 0 40px;
}

.promo-form .myuacc-promo {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
}

.promo-form input[type="text"] {
    margin: 0;
    height: 47px;
    padding: 5px 150px 5px 20px;
    background: #fff;
    font-size: 14px;
    color: #615f5f;
}

.promo-form .applyBtn {
    margin: 0;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 18px;
    background: #e87b1e;
    padding: 13px 20px;
    height: 47px;
    min-width: 146px; border-radius: 0;
}

.promo-form h3 {
    color: #000;
    font-size: 32px;
    margin: 0 0 15px;
    text-transform: uppercase;
    line-height: 1.3
}

.promo-form h3 span {
    font-size: 19px;
    display: block;
}

.promo-earned-col-image {
    position: relative; width: 50%; padding: 10px
}

.promo-earned-col-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 206px;
    max-height: 206px;
    object-position: center;
}

.promo-earned-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    width: 50%;
    justify-content: center;
    text-align: center;
    padding: 15px 10px;
    position: relative;
    z-index: 1;
}

.description_div p{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #000;
    margin-bottom: 0!important;
    text-align: center;
}

.promo-bot-left span {
    display: block;
}

.promo-bot-left p {
    margin: 0;
    color: #333;
    text-transform: inherit;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.2;
}

.promo-bot-left p span {
    display: block;
    font-size: 14px;
    color: #000
}

.promation_btn {
    line-height: 0
}

.promation_btn .button {
         margin: 0;
    padding: 10px 15px;
    font-size: 13px;
}

.promo-earned-col .tag {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    background: #e87b1e;
    margin: 0;
    padding: 8px 12px 5px;
    position: absolute;
    left: -43px;
    top: 30px;
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    width: 220px;
    line-height: normal;
    text-align: center;
    line-height: 1;
}

.promo-earned-col .tag span {
    display: inline-block;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mypromo-main-div .myacc_order_details {
   justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
}

.mypromo-main-div .myacc_order_details .promo-earned-col {
    width: 48%;
    overflow: hidden;
    margin: 0 0 20px 0;
    border: 1px solid #ececec;
    border-radius: 10px;
}

.mypromo-main-div .myacc_order_details li:nth-child(3n) {
    margin-right: 0
}

.pickup-cart-div .cart-direction:after {
    display: none
}

.pickup-cart-div .cart-direction img {
    width: 50px;
    margin: 0 auto
}

.pickup-cart-div .cart-header-first .row {
    display: block;
}

.pickup-cart-div .cart-header-first .cart_left {
    padding: 0 0 10px 0
}

.mobile-order-nowbtn {
    display: none;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
}

.mobile_accountdel_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.mobile_accountdel_col {
    width: 50%;
    background: #e36410;
    position: relative
}

.mobile_accountdel_col:last-of-type {
    background: #000000;
}

.mobile_account_item {
    position: relative;
    text-align: center;
}
.hcategory_sec{ display: none;}
.hcategory_sec{ background: #fff;  -webkit-box-shadow:0px 2px 5px 0px rgba(0, 0, 0, 0.2); box-shadow:0px 2px 5px 0px rgba(0, 0, 0, 0.2); height: 48px;    top: 116px;  z-index: 99; position: sticky;}


.mobile_account_item > a {
    display: inline-block;
    padding: 14px 10px 10px 10px;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
}

.mobile_account_item_login > a {
    display: inline-block;
    padding: 14px 10px 10px 10px;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
}
.mobile_mainacc_menulist {
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    z-index: 1;
    display: none;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
}

.mobile_mainacc_menulist > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.mobile_mainacc_menulist li a {
    display: block;
    padding: 12px 15px 12px 55px;
    background: #e9e2d8;
    font-size: 20px;
    position: relative;
    color: #000;
    text-transform: uppercase;
    
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile_mainacc_menulist li a:hover {
    color: #d45d38;
}

.mobile_mainacc_menulist .setting_menu_list_icon {
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.mobile_mainacc_menulist .setting_menu_list_icon.password_icon {
    background-position: -295px -255px;
    top: -4px !important
}

.mobile_mainacc_menulist .setting_menu_list_icon.logout_icon {
    background-position: -430px -255px;
}

.mobile_account_delivery_item {
    list-style: none;
    padding-left: 0;
    left: 0;
    right: 0;
    top: 52px;
    z-index: 9;
    position: absolute;
}

.mobile_account_delivery_item a {
    padding: 12px 15px 12px 15px;
    background: #000;
    display: block;
    font-size: 16px;
    color: #fff;
    
    position: relative;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16); font-weight: 700;
}
.mobile_account_item_login {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.1;
}
.mobile_account_delivery_item a:hover,
.mobile_account_delivery_item .active a {
    color: #ffa821;
}

.mobile_account_delivery_item li a i {
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mobile_account_menu {
    background: #000;
    display: none;
}

.mobile_account_menu h3 {
    color: #fff;
    text-align: center;
    
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    padding: 10px 10px;
}

.mobile_account_menu_click {
    padding: 12px 55px 12px 50px;
    display: block;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    text-transform: capitalize;
    position: relative;
    text-align: center;
}

.mobile_account_menu_click:hover {
    color: #e36410;
}

.mobile_account_menu_click i.arrow_myaccount {
    background: url(../images/aroow_myaccount.png) no-repeat center;
    background-size: 24px auto;
    float: right;
    width: 55px;
    height: 49px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mobile_account_menu_click i.arrow_myaccount.active {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    top: 35%;
}

span.myaccount_separate {
    width: 2px;
    height: 20px;
    display: inline-block;
    background: rgb(255 255 255 / 28%);
    position: relative;
    top: 2px;
}

.load_more_div {
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    padding: 0px 0 0 0
}

.load_more_div .load_more_data {
    margin: 0 auto;
    min-width: 160px
}

.make-pay-div h4 {
    font-size: 20px;
}

.pay-cart-header {
    padding: 18px 0px 5px 0px !important;
}

.inner_banner {
    height: 400px;
}

.inner_banner img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.white_bgbx {
    background: #fff;
    padding: 30px 25px;
}

.brand_note {
    margin: 0 0 70px;
}

.about_lirow {
    margin: 0 auto 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 900px;
    position: relative;
}

.about_lirow:last-of-type {
    margin-bottom: 20px
}

.about_liimg {
    width: 50%;
}

.about_liimg img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.about_liinfo {
    width: 50%;
    padding: 50px 50px;
    background: #5d8d87;
}

.diamond_divider {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.diamond_divider span {
    width: 0;
    height: 0;
    border: 40px solid transparent;
    border-bottom-color: #000;
    position: relative;
    top: -22px;
}

.diamond_divider span:after {
    content: '';
    position: absolute;
    left: -40px;
    top: 60px;
    width: 0;
    height: 0;
    border: 40px solid transparent;
    border-top-color: #000;
}

.award_gallery {
    margin: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.award_galcol {
    width: 32%;
    position: relative;
    margin: 0 0 30px;
    z-index: 1
}

.award_galimg {
    height: 360px;
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 20px;
    border-style: solid;
    border-width: 15px;
    border-top-color: #333333;
    border-right-color: black;
    border-bottom-color: #333333;
    border-left-color: black;
    -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    z-index: 1
}

.award_galimg img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.award_label {
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    background: #869f6e;
    text-align: center;
    margin: 0;
    padding: 10px 12px 5px;
    position: absolute;
    left: -43px;
    top: 25px;
    transform: rotate(-42deg);
    -webkit-transform: rotate(-45deg);
    width: 180px;
    line-height: normal;
    z-index: 1;
    display: none
}

.award_label span {
    display: inline-block;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.award_galinfo {
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    left: 35px;
    right: 35px;
    top: 80px;
    bottom: 35px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    padding: 15px;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    text-align: center;
    z-index: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.award_galcol:hover .award_galinfo {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.hook {
    width: 6px;
    height: 6px;
    background-color: #5c5c5d;
    margin: 0 auto 40px auto;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 0 1px #000000;
    box-shadow: 0 1px 0 1px #000000;
    position: relative;
}

.hook:before {
    content: '';
    width: 100px;
    height: 100px;
    border: 2px solid #a5a1a1;
    position: absolute;
    left: 50%;
    top: 20px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;
    margin-left: -50px;
}

.award_galinfo h3 {
    font-size: 18px;
    color: #fff;
}

.award_galinfo h3 a,
.test-popup-link {
    color: #fff
}

.test-popup-link:hover,
.award_galinfo h3 a:hover {
    color: #809b66;
}

.award_plus {
    display: block;
    font-size: 40px;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.award_galcol:hover .award_plus {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
}

.test-popup-link:hover .award_plus {
    border-color: #809b66;
}

.change-pickup-location {
    margin-bottom: 20px !important;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}

.contact_wrap {}

.locate-map {
    margin: 0 0 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background: url("../images/home-bg-new.jpg") repeat
}

.locate-mapleft {
    width: 360px;
}

.locate-list {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}

.locate-lirow {
    padding: 15px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}

.locate-lirow:last-child {
    border-bottom: 0;
}

.locate-lirow:hover {
    background: #5d8d87;
    color: #fff;
}

.locate-lirow h4 {
    font-size: 18px;
    margin: 0 0 4px;
}

.locate-lirow:hover h4 {
    color: #fff
}

.locate-lirow p {
    margin: 0;
}

.locate-lirow.active {
    background: #5d8d87;
    color: #fff;
}

.locate-lirow.active h4 {
    color: #fff
}

.locate-mapright {
    width: calc( 100% - 360px);
}

.locate-mapright iframe {
    vertical-align: top;
    width: 100%;
}

.contact_col {
    float: left;
    width: 50%;
    padding: 0 15px;
}

.contact_form .form-control {
    width: 100%;
    display: block;
}

.contact_form textarea.form-control {
    margin: 0;
    height: 100px;
}

.contact_form .btn_sec .btn {
    width: 100%;
    margin: 0;
}

.our-policy {
    margin: 30px 0 0 0;
}

.our-policy h6 {
    margin: 0 0 10px 0
}

.service-makkan {
    margin: 0 auto 30px;
    text-align: center;
}

.service-makkan img {
    margin-top: -110px;
    max-height: 182px;
}

.err_alt {
    border: 1px solid #ff1313 !important;
}

.err_tarms_chk span {
    color: #ff1313;
}

ul.outletul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.outletul > li {
    width: 49%;
    margin: 0 2% 30px 0;
    background: #5d8d87;
    position: relative
}

.outletul > li:nth-child(even) {
    margin-right: 0
}

.ourrest_img {
    width: 100%;
}

.ourrest_img img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.ourrest_info {
    width: 100%;
    padding: 30px 20px 70px 20px;
    color: #fff
}

.ourrest_info h4 {
    margin: 0 0 15px;
    color: #fff;
    font-size: 22px
}

.ourrest_info p {
    margin: 0 0 8px;
}

.ourrest_infolinks {
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ourrest_infolinks li {
    display: block;
    width: 50%;
}

.ourrest_infolinks a {
    padding: 14px 5px;
    display: block;
    color: #fff;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    
    height: 100%
}

.media-links-a a {
    background: #869f6e
}

.media-links-b a {
    background: #666
}

.ourrest_infolinks a:hover {
    background: #5d8d87;
}

.ourrest_infolinks a i {
    display: inline-block;
    margin: 0 10px 0 0;
}

.news_list {
    margin: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.news_list > .news_lirow {
    width: 32%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    margin: 0 0 40px;
    background: #fff;
}

span.date {
    color: #9c9c9c;
    font-size: 16px;
    display: block;
    margin: 0 0 10px 0;
}

.news_liimg {
    width: 100%;
}

.news_liimg img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.news_liinfo {
    width: 100%;
    padding: 20px 0 0 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.news_liinfo h4 {
    margin: 0 0 5px;
    font-size: 19px;
    line-height: 1.3;
}

.news_liinfo h4 a {
    color: #000;
    display: block
}

.news_liinfo h4 a:hover {
    color: #5d8d87
}

.news_liinfo p {
    margin: 0 0 20px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.news_readmore {}

.cdd-details-lhs .checkout-heading:after {
    content: "";
    width: 2px;
    top: 5px;
    bottom: 5px;
    right: 0;
    margin: auto;
    position: absolute;
    background: #fff;
    height: 60%;
    display: block;
    z-index: 1;
}

.mfp-bg {
    background: rgba(0, 0, 0, 0.8);
}

#scrollbutton {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: auto;
    height: auto;
    z-index: 999;
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton.show {
    display: inline-block;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton a {
    background: #353535;
    display: inline-block;
    position: relative;
    color: #fff;
    text-decoration: none;
    line-height: 0;
    padding: 14px 18px;
    overflow: hidden;
    border-radius: 4px;
    font-size: 20px;
}

#scrollbutton a:hover {
    background: #e87b1e;
}

#scrollbutton a i.ars {
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars {
    -webkit-transform: translateY(-25px) rotateY(360deg);
    transform: translateY(-25px) rotateY(360deg);
    opacity: 0;
    -webkit-transition: all 0.3s linear 0.2s;
    transition: all 0.3s linear 0.2s;
}

#scrollbutton a i.ars1,
#scrollbutton a i.ars2 {
    position: absolute;
    bottom: 0%;
    left: 50%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
    transform: translate(-50%, -50%) rotateZ(360deg);
    opacity: 0;
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a i.ars2 {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

#scrollbutton a:hover i.ars1,
#scrollbutton a:hover i.ars2 {
    bottom: 18%;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars2 {
    bottom: 9%;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.4s;
    transition: all 0.2s linear 0.4s;
}

.reservation-full {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.res-pm p{
        justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
}

.reservation-counter,
.choose-outlet,
.choose-datetime-full,
.special-instructions {
background-color: rgba(210,190,155,.38);
    border-radius: 10px;
    clear: both;
    overflow: hidden;
    margin-bottom: 7px;
    padding-top: 23px;
    padding-bottom: 23px;
}

.reservation-full h2 {
    font-size: 40px;
}

.reservation-counter h3,
.choose-outlet h3,
.choose-date h3,
.choose-time h3,
.special-instructions h3 {
    font-size: 26px;
    margin-bottom: 10px;
}

.reservation-counter-left h4 {
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 10px;
    text-transform: uppercase;
    margin-right: 10px;
}

.reservation-counter-left,
.reservation-counter-right {
    float: left;
}

.reservation-counter-full {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.choose-date,
.choose-time {
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
}

.special-instructions textarea {
    max-width: 100%;
    height: 130px;
    resize: none;
    margin-bottom: 0px;
    padding: 20px;
}

.choose-outlet .custom_select {
max-width: 350px;
    margin: 0 auto!important;
}

.choose-outlet .custom_select select {
    margin-bottom: 0px;
}

.choose-date .catering_days_ul li dl dt,
.choose-time .catering_days_ul li dl dt {
    font-size: 12px;
}

.choose-date .catering_days_ul li dl.active,
.choose-time .catering_days_ul li dl.active {
    border: 2px solid #809b66;
}

.choose-date p,
.choose-time p {
    font-size: 12px;
    text-align: left;
    margin-bottom: 0px;
}

.choose-time {
    margin-top: 30px;
}

.reservation-summary {
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border: 2px solid #cfbfaa;
    padding: 25px;
    overflow: hidden;
}

.reservation-summary ul {
    padding-left: 0px;
    list-style-type: none;
    padding-bottom: 0px;
}

.reservation-summary ul li span {
    font-size: 15px;
        font-weight: bold;
    text-transform: uppercase;
    overflow: hidden;
    width: 50%;
    float: left;
    text-align: left;
}

.reservation-summary ul li span:first-child {
    position: relative;
    padding-right: 10px;
}

.reservation-summary ul li span:last-child {
    padding-left: 10px;
}

.reservation-summary ul li span:first-child::after {
    content: ":";
    position: absolute;
    top: 0px;
    right: 0px;
}

.reservation-summary ul li {
    margin-bottom: 15px;
    overflow: hidden;
}

.reservation-summary ul li:last-child {
    margin-bottom: 0px;
}

.reservation-summary ul li span.summary-email {
    text-transform: lowercase;
}

.reservation-summary ul li span.summary-childseat {
    font-style: italic;
    text-transform: lowercase;
    color: #4e4e4e;
    font-size: 14px;
}

.reservation-summary-full {
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(210, 190, 155, 0.38);
    border-radius: 10px;
    padding-top: 25px;
    padding-bottom: 50px;
    overflow: hidden;
}

.reservation-summary-full h3 {
    font-size: 26px;
    margin-bottom: 20px;
}

.reservation-summary-button {
/*    max-width: 860px;
display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: center;
    margin-top: 8px;*/
}

.reservation-summary-button .go-back,
.reservation-summary-button .continue {
    /*width: 49.5%;*/
    /*float: left;*/
}

.reservation-summary-button .go-back {
    /*background-color: #000;*/
}

.reservation-summary-button .continue {
    /*background-color: #809b66;*/
}

.reservation-summary-button .go-back a,
.reservation-summary-button .continue a {
/*    margin-top: 0px;
    margin-bottom: 0px;
    background: none;*/
        margin-left: 20px;
}

.reservation-summary-button .go-back a:hover {
    background: #000;
}

.reservation-summary-button .continue a:hover {
    background: #000;
}

.go-back a {
/*    width: 100%;
    display: block;
    margin: 0;
    font-size: 20px;*/
}

.choose-date .catering_days_ul li dl:hover,
.choose-time .catering_days_ul li dl:hover {
    border: 2px solid #a23c2b;
}

.choose-date .catering_days_ul li dl.cl-next-days,
.choose-time .catering_days_ul li dl.cl-next-days {
    border: none;
}

.choose-outlet select {
    font-size: 15px;
    color: #595656;
    padding-left: 15px;
    padding-right: 15px;
}

.reservation_order_details {
}

.reservation_outlet,
.reservation_user,
.reservation_Date,
.reservation_Time {
    border-bottom: 1px solid #e4e4e4;
    padding: 0 0 15px 0;
    margin: 0 0 18px 0
}

.reservation_order_details h4 {
    color: #818181;
}

.reservation_order_details p {
    color: #000;
    
    font-size: 19px
}

a.add-address-more {
    display: inline-block;
    position: relative;
    font-size: 24px;
    margin: 0 0 0 5px;
}

.address_linfo {
    margin-bottom: 15px;
}

.address_linfo .custom_radio span {
    color: #555555;
}

.address-list-cls {
    max-height: 240px;
    overflow-y: auto;
}

.phone-show-mbl {
    display: none;
}

.phone-show-mbl a {
    color: #fff
}

.disclaimer {
    margin: 30px 0 0 0;
}

.disclaimer h4 {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 12px;
}

.disclaimer ul {
    padding-left: 20px
}

.horder_tt {
    padding: 12px 10px 12px 20px;
}

.oreder-row-inv {
    margin: 0;
}

.oreder-row-inv .cart_img {
    padding: 0;
}

.oreder_itm_row {
    padding: 18px 5px 5px 5px !important;
}

.order-items-maindiv {
    background: #f5f5f5;
}

.oreder-row-inv .cart_left {
    text-align: left;
}

.month-select-div {
    margin: 0 auto 20px auto;
}

.tarms-error {
    display: none;
    color: #f03011;
}

.catering-product-rowlist span.min_pax_spn {
    display: block;
    
    font-size: 18px;
}

.catering-product-rowlist .price_nor {
    font-size: 20px;
    
    display: block;
    padding: 10px 0 0 0;
}

.catering-product-rowlist .price_nor sup {
    font-size: 80%;
    font-family: 'Poppins', sans-serif;
}

.dotted-line-top {
    border-top: 1px dotted #ccc;
    padding: 20px 0 0 0;
    margin: 10px 0 0 0;
}

.custom-tarms-div .sign_reg {
    padding-left: 30px
}

.custom-tarms-div span:before {
    width: 20px;
    height: 20px;
    border: 2px solid #d45d38;
    margin-top: 2px;
    border-radius: 0;
}

.custom-tarms-div .custom_checkbox input[type=checkbox]:checked + span:before {
    background: none;
    content: "\F00C";
    font-family: FontAwesome;
    text-rendering: auto;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    background: #d45d38;
    border: 2px solid #d45d38;
}

.error_div_cls,.error_div_cls_limit {
    color: red;
    display: none
}

.single_outlet_info {
    padding-top: 15px;
    text-align: center;
}

.single_outlet_info p {
    margin: 0;
    font-size: 16px;
}

.single_outlet_info .mainacc_toptext p {
    font-size: 20px;
    font-style: normal;
}

.isDisabled {
    cursor: default;
    text-decoration: none;
}

.disableDivCls {
    display: none;
}

.event-type-single {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.rmv-border-bottom {
    border-bottom: 0px !important;
}

.no-stock-product .innerproduct-item::after {
    opacity: 1 !important;
}

.no-stock-product .innerproduct-button {
    opacity: 1 !important;
    visibility: visible !important;
    top: 50% !important;
}

.no-stock-product .innerproduct-button .btn {
    cursor: not-allowed;
    pointer-events: all !important;
}

h3.choose-eventtype {
    position: relative;
}

a.go-bck-icon {
    position: absolute;
    left: 0;
    top: 0;
}

a.go-bck-icon:before {
    content: "\f0a8";
    font-family: Fontawesome;
    font-size: 30px;
}

.hall-select-popup .mfp-close {
    color: #fff
}

.pkg-detail-top-row .pkselected_catgrytext {
    text-align: left;
}

.pkselected_filtersec .pck_selected_ctry {
    
    font-size: 21px;
    padding: 0;
}

.pkg-detail-top-row .pkselected_package:first-of-type {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.guest_btn {
    text-align: center;
}

.pkglist_itemtable .pkglist_img img {
    max-height: 316px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.controls.single-link {
    text-align: center;
}

.controls.single-link a {
    font-size: 14px;
    display: inline-block;
    color: #272727;
    
}

.package_info_maindiv .package_info_title {
    color: #d45d38;
    text-align: center;
}

.package_info_maindiv .package_info_subtitle {
    color: #bb4d4d;
    text-align: center;
}

.package_info_maindiv .terms_conditions h4 {
    color: #bb4d4d;
    font-size: 16px;
    padding-top: 15px;
}

.back-to-listbtn a {
    width: 100% !important;
}

.package_info_maindiv .text-rgt {
    float: right;
}

.package_info_maindiv .terms_conditions li {
    list-style-type: none;
}

.package_info_maindiv .terms_conditions ul {
    padding-left: 30px;
}

.custom-addon-checkbox .pkgerror_txt {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 25px;
    font-size: 13px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
    width: 100%;
}

.apply-red {
    color: red!important;
}

ul.venue_type_item {
    padding: 20px 0 0 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

ul.venue_type_item li {
    margin: 5px 10px;
}

ul.venue_type_item li a {
    padding: 15px 25px;
    background: #e87b1e;
    color: #fff;
    border-radius: 5px;
    
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: block;
}
ul.venue_type_item li a:hover{ background: #000; }
ul.venue_type_item li.venuetype-active a {
    background: #d45d38;
}

.venue_popup {
    max-width: 780px
}
.pdpa_trmcont_link {
    font-size: 16px;
}
.pdpa_trmcont_link span{
    display: inline-block
}

.delivery_outletpoup .order-body { border: 2px solid #000; }
.delivery_outletpoup .self_popup_hea_row { background: #e87b1e; }
.delivery_outletpoup .outlet-scooter-img { width: 86px; }

.outleterror { color: #d45d38; }
.delete-act-icon {
    position: absolute;
    right: 9px;
    top: 1px;
}
.act-remove-topdiv {
    position: relative;
    padding-right: 20px;
}

.venue_popup .close-icon-but {background: #e87b1e;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: -15px;
    right: -15px;
    padding: 0;
    margin: 0;}
.hide_section_cls, .back_click_mbl{display: none}
.back_click_mbl a:before {
    content: "\f104";
    font-family: FontAwesome;
    margin-right: 5px;
    font-size: 18px;
    font-weight: bold;
}
.custom_center_alertcls {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    max-width: 450px;
    margin: auto !important;
    padding: 30px;
    font-size: 22px;
    border-radius: 10px;

    display: inline-block;
    text-align: center;
}
.custom_center_alertcls.alert-danger{     
    color: #ca322f;
    background-color: #fdd1d1;}
.mfp-custom-close {
    position: absolute;
}
img.view-img-span {
    width: 20px;
    position: relative;
    top: 3px;
    margin-left: 5px;
}
img.close-img-span {
    width: 19px;
    position: relative;
    top: 1px;
    margin-left: 5px;
    display: none;
}
.chk-payment .form-group.custom-tarms-div {
    padding: 0 20px 20px;
    text-align: left;
}
.text-lbl {
    display: block;
    
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
.mk-catering-payment .chk-payment-col-radio li{ margin-right: 0 }
.price_nor dl {
    display: inline-block;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    vertical-align: text-top;
}

.serving-passionately-left .custom-dots{ padding:0; position: absolute; bottom: 12px; left: 0; right: 0; margin: 0; text-align: center;}
.serving-passionately-left .custom-dots li{ display: inline-block; width: 20px; height: 20px; position: relative; z-index: 1;}
.serving-passionately-left .custom-dots li button{ padding: 0; margin: 0;    text-indent: -9999px;
    background: none;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    height: 100%; }
 .serving-passionately-left .custom-dots li button:before{ content: "";  width: 12px; height: 12px; position: absolute; margin: auto; left: 0; right: 0; top: 0; bottom: 0; border-radius: 50%;  }
.serving-passionately-left .custom-dots li button::before{ background: #809b66; border: 2px solid #fff;   }
.serving-passionately-left .custom-dots  li.slick-active button:before{ background: #000; border: 2px solid #fff; }
.package_info_desc a{ color: #000;}
.back-to-pkglist { 
    background: #d45d38;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1;
    position: relative;
    border-radius: 5px;
    padding: 8px 15px;
    margin: 0;
    cursor: pointer;
    letter-spacing: 1px;
    display: inline-block;
    margin-bottom: 5px;
}

.back-list-also {
    -webkit-align-items: flex-start !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.timeslot_info {
    font-size: 13px;
    color: #f20000;
    margin-bottom: 15px;
}
.timeslot_info span {
    display: block;
}
.ordrdatetime_error .error { width: 100%; }
.syd_merge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.syd_date {  width: 48%;}
.syd_time.delivery_submit_cls {  width: 48%;}
.syd_merge .form-group {margin: 0}

.syd_date input{
    padding: 12px 45px;
}

.checkout-control-group-middle .react-datepicker-wrapper {
    display: block;
}
.checkout-control-group-middle .react-datepicker__input-container {
    position: relative;
    display: block;
}
.datetime_selt_sec .react-datepicker-wrapper, .datetime_selt_sec .react-datepicker__input-container {
    display: block;
}


.membership_status {
margin-top: -33px;
position: relative;
}

.membership_status span {
position: absolute;
left: 0;
right: 0;
top: 14px;
text-transform: uppercase;
}
.signup-gender-selct {margin:0px;}
.birthday_info_sp {font-size: 12px; color: #0e0e0e; display: block;}
.birthday_info_sp b {font-weight: normal; font-style: italic;}

/*myaccount - 13 jan 2020*/
.membership-desc { background-color: #d45d38; border: 2px solid #fff; padding: 12px 5px 10px; font-size: 20px; text-transform: uppercase;  margin: 15px auto; max-width: 250px; }
.membership-desc img{ width: 20px; margin-right: 8px; }
.user-id{ margin: 10px 0px 5px 0px; }
/*.user-id span {text-transform: uppercase;  }*/
.user-id {text-transform: uppercase;  }
.membership-progress { margin: 0; padding: 10px 0 0; }
.congrats-inner h5 {
    color: #fff;
    margin: 0 0 20px;
}
.membership-progress p { font-weight: 600; color: #fff; margin: 9px auto 0; max-width: 170px; line-height: 1.2; }
.pg-bar{ display: block; position: relative; width: 100%; height: 15px; border-radius: 25px; background-color: #2a2521; max-width: 175px; margin: 0 auto;}
.pg-bar span{ position: absolute; height: 100%; width: 0%; background-color: #fcb03b; top: 0; left: 0; border-radius: 25px; -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3); box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);}
.member-discount-desc { display: inline-block;background: #d45d38;border-radius: 10px;padding: 3px 10px;font-size: 12px;color: #fff;margin: 5px 0;line-height: 1;text-align: center; }
.member-discount-total {
    color: #e87b1e;
    font-size: 14px;
}
.paymt-mothod-toptext { padding: 15px; }
.online-pay-info { margin: 0px; padding-top: 15px; }
.uob-payment-conf-infotag b{ font-size: 20px; }



/* omise */
.omise-form-error input {
    -webkit-box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    border-color: #ff7076;
    border-top-color: #ff5c61;
}

.pay-omiseconf-popup { max-width: 340px; border-radius: 10px;background: #f5f5f7}
span.omise-pay-img {
    display: block;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    position: absolute;
    top: -39px;
    left: 0;
    right: 0;
}

img.makisan-logo-img {
    width: 100%;
    height: 100%;
    -o-object-fit: none;
    object-fit: none;
}

.omise_pay_header {
    position: relative;
    padding: 50px 30px 25px;
    text-align: center;
    border-bottom: 1px solid #d2d2d3
}
.omise_pay_body {
    padding: 30px;
    position: relative;
        border-top: 2px solid #fff;
}
.omisepop_in .form-control{ padding: 10px; height: 40px; font-size: 14px;border-radius: 4px; padding-left: 36px}
.expire_row_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.expire_row_inner > div {
    width: 32%;
    position: relative;
}
.omisepop_in .alt_btns .button {margin-bottom: 0;
    width: 100%;
    padding: 13px 10px 11px;
    border-radius: 4px;}
.omisepop_in .form_sec i{ position: absolute; left: 10px; color: #888;top:12px;  }
.omisepop_in .form_sec > div{ position: relative }
.omisepop_in .form_sec .uname-box  i{ left: 13px;}
.omisepop_in .form_sec .card-numbr-div i, .omisepop_in .form_sec .expire_left i {font-size: 16px;top:13px;}
.omisepop_in .form_sec .expire_right  i{ font-size: 19px; top: 12px;}
.expire_mdl .form-control{ padding-left: 15px }
.card {
    position: absolute;
    display: block;
    right: 10px;
    width: 28px;
    height: 19px;
    background: url(../images/cards/spritecard.png) no-repeat 0;
    top: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    background-position: 0 0;
    overflow: hidden;
}
.visa + .card {
    background-position: 0 -19px;
}
.master+ .card {
    background-position: 0 -38px;
}
.discover + .card {
    background-position: 0 -57px;
}
.american + .card {
    background-position: 0 -76px;
}
.pay-omiseconf-popup button.mfp-close {
    padding: 0;
    width: 21px;
    height: 21px;
    display: block;
    color: #fff !important;
    border-radius: 50%;
    line-height: 21px;
    background: #a5a5a5;
    border: 1px solid #929292;
    top: 10px;
    font-size: 20px;
    right: 10px;
    text-align: center;
}
.pay-omiseconf-popup button.mfp-close:hover{ background: #000; border-color: #000; }
.omisepay-mode-option {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #ffd025;
    line-height: 1;
    padding:9px 11px 8px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
}
.omise-error-info-div {
    color: #ffffff;
    background-color: #b12f2f;
    border-color: #ebccd1;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
}
.omise-error-icon { font-size: 14px; }
.omise_alert_close {
    position: absolute;
    right: 0px;
    color: #FFF;
    top: 0;
}
.omise-error-msg {
    margin: 0 0 0 11px;
    font-size: 14px;
}
.omise-error-info-div .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
}



.membership-type-innerdiv {
    background: #fff;
    color: #202011;
    padding: 25px 15px;
    border: 3px solid #020101;
    border-radius: 5px;
    margin: 30px 0 0 0;
    text-align: left;
    box-shadow: 0px 3px 10px 0px rgba(1, 1, 1, 0.45);
}

.membership-type-innerdiv h4 {
    text-align: center;
    color: #020101;
    font-size: 25px;
}

.membership-type-title {
    text-align: center;
    position: relative;
    color: #686766;
    
    text-transform: uppercase;
    margin-bottom: 20px;
}

.membership-type-title:before {content: "";position: absolute;left: 0;right: 0;height: 2px;width: 100%;background: #686766;top: 50%;transform: translateY(-50%);}

.membership-type-title span {
    background: #fff;
    display: inline-block;
    padding: 0 10px;
    z-index: 1;
    position: relative;
}

.gldmember-main .membership-type-title:before {
    background: #d18029;

}
.gldmember-main .membership-type-title{ color:#d18029; }
.text-box-incheckout em {
    text-transform: uppercase;
    font-size: 14px;
    color: #181818;
    text-align: right;
    float: right;
    margin-top: -5px;
}
.checkout-terms-topdiv {
    float: left;
    margin-top: 15px
}




.top-bar{ background: #f4f4f4; width: 100%; position: relative; margin: 0 0 10px; overflow: hidden; }
.top-bar p{ margin: 0; padding: 15px; color:#282828; font-size: 16px; text-align: center; }
.top-bar span{ position: absolute;
    left: 0;
    top: 0;
    background: #e2e2e2;
    color: #4f1a0c;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 17px;
    padding: 15px 30px; }
.top-bar span:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 90px solid #e2e2e2;
    border-right: 50px solid transparent;
    position: absolute;
    left: 140px;
    top: 0;
}

.home-banner .banner-info {
    position: absolute;
    left: 60px;
    right: 0;
    z-index: 91;
    max-width: 1280px;
    top: 52%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 auto; text-align: left;
}
.home-banner .banner-info h2 {
    font-size: 45px;
    color: #e67a1e;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    line-height: 60px;
}
.home-banner .banner-info h4{ font-size: 50px;
    color: #e67a1e;
    margin-bottom: 20px;
    font-weight: 800;
    line-height: 60px;  }
.home-banner .banner-info p {
    color: #000;
    font-size: 17px;
    line-height: 1.4; 
    max-width: 400px;
}
.home-banner .banner-info a {
    display: block;
    padding: 10px 20px;
    color: #fff;
    background: #e87b1e;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px; font-size: 16px;
    margin: 0;font-family: 'Poppins', sans-serif;
    font-weight: 700; border-radius: 5px; max-width: 150px;
}

.home-banner .banner-info a:hover {
    color: #fff;
    background: #000;
}
.join-us-section {
    width: 100%;
    background: #e26024;
    margin: 15px 0 0;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center; padding:32px 20px;
}
.join-us-section .lft-sec-prt { width: 30%; color:#fff; }
.lft-sec-prt h4{ margin: 12px 0 0px; font-family: 'Poppins', sans-serif; text-transform: uppercase; font-size: 20px;color:#fff; font-weight: 600}
.lft-sec-prt h3{ margin: 0;  font-size: 50px; color:#fff;font-family: 'Honey Candy';}
.join-us-section .mid-sec-prt {
    width: 30%; position: relative;
}
.join-us-section .mid-sec-prt img{ position: absolute; bottom: -91px;left: 0; right: 0; margin: auto; }
.join-us-section .rgt-sec-prt {
    width: 40%; color: #fff;
}
.join-us-section .rgt-sec-prt p {
    margin: 0 0 8px;
}
.join-us-section .rgt-sec-prt a {
    font-size: 16px;
    margin: 5px;
    font-weight: normal;
    border-radius: 5px;
}

.join-us-section .rgt-sec-prt a:hover {
    color: #000;
    background: #fff;
}

.five-boxes{ width: 100%; display: block; float: left; clear: both; padding: 30px 0 30px; }
.five-boxes:after{ content: ''; display: block; clear: both; }
.two-boxes{ padding: 0; margin-left: -1%; margin-right: -1%; display: flex; flex-wrap: wrap;
    justify-content: center; list-style: none; }
.two-boxes li{ width: 48%; float: left; margin-left: 1%;  margin-right: 1%; margin-bottom: 30px; box-shadow: 0 0 8px rgb(0 0 0 / 20%);}

.two-boxes li.madbar-box { background: url(../images/madbar-box-img.jpg) no-repeat; height: 290px; position: relative;
display: block; background-size: cover; background-position: center top; overflow: hidden; border-radius: 15px; }

.two-boxes li.dineout-box { background: url(../images/dineout-box-img.jpg) no-repeat; height: 290px; position: relative;
display: block; background-size: cover; background-position: center top; overflow: hidden; border-radius: 15px; }

.two-boxes li.gallery-event { background: url(../images/events.jpg) no-repeat; height: 290px; position: relative;
    display: block; background-size: cover; background-position: center top; overflow: hidden; border-radius: 15px; }
    
    .two-boxes li.gallery-food-beverages { background: url(../images/food-beverages.jpg) no-repeat; height: 290px; position: relative;
    display: block; background-size: cover; background-position: center top; overflow: hidden; border-radius: 15px; }


.three-boxes li {
    background: url(../images/noimg-800x800.jpg) no-repeat;
    height: 290px;
    position: relative;
    display: block;
    background-size: cover;
    background-position: top;
    overflow: hidden;
    border-radius: 15px; box-shadow: 0 0 8px rgb(0 0 0 / 20%);
}
.bottom-three-boxes li {
    background: url(../images/noimg-800x800.jpg) no-repeat;
    height: 290px;
    position: relative;
    display: block;
    background-size: cover;
    background-position: top;
    overflow: hidden;
    border-radius: 15px; box-shadow: 0 0 8px rgb(0 0 0 / 20%);
}
.three-boxes li img{width: 100%; height: 100%; object-fit: cover; }




.ml-25{
    margin-left:-25px;
}
.f-white{
    color:#fff!important;
}
.bottom-three-boxes li.events-box { background: url(../images/about.jpg) no-repeat; height: 290px; position: relative;
    display: block; background-size: cover; background-position: right top; overflow: hidden; border-radius: 15px; }
    .bottom-three-boxes li.reservations-box { background: url(../images/gallery.jpg) no-repeat; height: 290px; position: relative;
    display: block; background-size: cover; background-position: center top; overflow: hidden; border-radius: 15px; }
    .bottom-three-boxes li.functions-box { background: url(../images/partner-merchant-partner-store.jpg) no-repeat; height: 290px; position: relative;
    display: block; background-size: cover; background-position: center top; overflow: hidden; border-radius: 15px; }

.two-boxes li div, .three-boxes li div{ position: absolute; bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%; }
.two-boxes li h3, .three-boxes li h3{ margin: 0 0 5px; font-size: 33px; font-weight: 400; }
.two-boxes li p:last-child, .three-boxes li p:last-child{ margin: 0; }
.two-boxes li.white-clr, .three-boxes li.white-clr { color:#fff; }
.two-boxes li.white-clr h3, .three-boxes li.white-clr h3 { color: #fff; }



.two-boxes li div, .bottom-three-boxes li div{ position: absolute; max-width: 300px; }
.two-boxes li h3, .bottom-three-boxes li h3{ margin: 0 0 5px;color:#000; font-size: 40px; font-weight: 400; }
.two-boxes li p:last-child, .bottom-three-boxes li p:last-child{ margin: 0; }
.two-boxes li.white-clr, .bottom-three-boxes li.white-clr { color:#fff; }
.two-boxes li.white-clr h3, .bottom-three-boxes li.white-clr h3 { color: #fff; }
.two-boxes li.left-middle div, .bottom-three-boxes li.left-middle  div{ 
    top: 46%;
    bottom: 0;
    left: 15px;
    transform: translateY(-46%); 
}

.pagesList-main-div .two-boxes li h3, .bottom-three-boxes li h3{ margin: 0 0 5px;color:#000; font-size: 33px; font-weight: 400;}

.w-425{
    width:425px;
}

.pagesList-main-div .two-boxes li.left-middle div, .bottom-three-boxes li.left-middle  div{ 
    top: 77%;
    bottom: 0;
    left: 15px;
    transform: translateY(-46%); 
}

.two-boxes li.left-middle div, .bottom-three-boxes li.left-middle  div{ 
    top: 60%;
    bottom: 0;
    left: 15px;
    transform: translateY(-46%); 
}

.two-boxes li.right-bottom div, .bottom-three-boxes li.right-bottom div {
    right: 15px;
    bottom: 15px;
    text-align: right;
}
.two-boxes li.bottom-center div, .bottom-three-boxes li.bottom-center div {
    bottom: 15px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
.two-boxes li.left-bottom div, .bottom-three-boxes li.left-bottom div {
    left: 15px;
    bottom: 15px;
    text-align: left;
}
.order-btn {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background: #e87b1e;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px;
    font-size: 16px;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    border-radius: 5px;
}
.order-btn:hover {
    color: #fff;
    background: #000;
}

.three-boxes{ padding: 0; margin-left: -1%;
    margin-right: -1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; list-style: none; }
.three-boxes li{ width: 31.33%; float: left; margin-left: 1%; margin-right: 1%; margin-bottom: 30px;}

.three-boxes li .order-btn {
    text-align: center;
    width: 100%;
    background: #e36410;
}

.bottom-three-boxes{ padding: 0; margin-left: -1%;
    margin-right: -1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; list-style: none; }
.bottom-three-boxes li{ width: 31.33%; float: left; margin-left: 1%; margin-right: 1%; margin-bottom: 30px;}

.our-promotions-section { width: 100%; text-align: center; padding: 0 0 60px; }
.our-promotions-section .slick-dots {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none; padding: 20px 0 0;
}
.our-promotions-section .slick-dots li { margin: 0 5px; }
.our-promotions-section .slick-slide img { width: 100%; padding: 10px; }
.our-promotions-section button {
    background: #000;
    width: 11px;
    height: 11px;
    text-indent: -9999px;
    padding: 0;
    list-style: none;
    border-radius: 50%;
    cursor: pointer; margin: 0;
}
.our-promotions-section .slick-track .slick-slide{ justify-content: center;    float: left;
    height: 100%;
    min-height: 1px;
    display: block; }
.our-promotions-section .slick-dots li.slick-active button { background: #e87b1e; }


.social-list { padding: 0; margin: 0; list-style: none; display: flex; flex-wrap: wrap; align-items: center; }
.social-list li { margin: 0; float: left; margin: 0 10px 0 0; font-size: 16px; }
.social-list li a{ color: #000; }
.social-list li a:hover{ color: #e87b1e; }
span.birthday-text.phone-show-dskp{display: -webkit-inline-box;}
.birthday-text a {color: #e87b1e;}
.birthday-text{font-size: 14px; padding-left: 17px;}
.acc-inform .row{    margin-right: -15px;
    margin-left: -15px;}
.menu-section-left {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    background: #181818;
}
.menu-section-left-inner {
    width: 88%;
    display: flex;
}
.menu-section-left-inner .menu-list-intvl {
    width: 25%;
    text-align: center;
}
.menu-section-left-inner .menu-list-intvl a {
    padding: 15px 5px;
    position: relative;
    cursor: pointer;
    color: #fff;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    display: flex;
    height: 100%;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    justify-content: center;
    align-items: center;
}
.menu-section-left-inner .menu-list-intvl a:before, .more-menu-parent:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #e87b1e;
    height: 3px;
    -webkit-transition-property: left,right;
    transition-property: left,right;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.menu-section-left-inner .menu-list-intvl.active a:before, 
.menu-section-left-inner .menu-list-intvl a:hover:before, 
.more-menu-parent.active:before, 
.more-menu-parent:hover:before,
.menu-section-left-inner .menu-list-intvl a.active
 {
    left: 0;
    right: 0;
}
.menu-section-left-inner .menu-list-intvl a:hover, .menu-section-left-inner .menu-list-intvl.active a {
    color: #e87b1e;
}
.more-menu-parent {
    cursor: pointer;
    padding: 15px 27px 15px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-transform: capitalize;
    font-size: 15px;
    line-height: 1;
    height: 100%; font-weight: normal; text-align: center;
}
.more-menu {
    width: 12%;
    position: relative;
}
label.open_more_category {
    width: 16px;
    cursor: pointer;
    color: #fff;
    position: absolute;
    right: 10px;
}

ul.more_categor_info {
    padding: 0;
    list-style: none;
    min-width: 280px;
    max-width: 280px;
    background: #fff;
    position: absolute;
    right: 0;
    overflow-y: auto;
    border-radius: 3px;
    top: calc(100% + 5px);
    border: 1px solid rgba(0,0,0,.1); z-index: 9;
}
ul.more_categor_info li a {
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid #f3f3f3;
    color: #0a0b10;
    text-transform: capitalize;
    font-size: 15px;
    line-height: 1;
    color: #000;
}
ul.more_categor_info li a:hover {
    color: #e87b1e;
}
ul.more_categor_info li.menu-list-intvl.active a {
    background: #e87b1e;
    color: #fff;
}
span.special {
    background: #5796c9;
    color: #fff;
    height: 28px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    max-width: 80px;
    margin: 0 auto 9px;
    line-height: 9px;
    border-radius: 50px;
}
.price_nor {
    font-size: 28px;
    font-weight: 500; font-family: 'Poppins', sans-serif; display: block;
}
.price_nor sup {
    font-size: 16px; font-family: 'Poppins', sans-serif;
    top: -11px;
    font-weight: 500;
}
.old-price {
    font-size: 21px; font-family: 'Poppins', sans-serif; display: block;
    font-weight: 500; color: #b6b6b6; text-decoration: line-through; margin: 0 0 7px;
}
.old-price sup {
    font-size: 16px; font-family: 'Poppins', sans-serif;
    top: -12px;
    font-weight: 500;
}
a{     transition: all 400ms ease-in-out 0s; }


.notification-wrap {padding: 0px 0px 0px;}
/*.notify-row {display: flex; flex-wrap: wrap;}*/
.notify-row .slick-slide > div { width: 100%; padding: 12px 6px 16px;}
.notify-row {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.notify-box {
    padding: 28px 20px;
    width: 32.33%;
    margin: 10px 0.5%;
    background: #fff;
    position: relative;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px #01010138;
    border: 1px solid #fff;
}
.notify-box.active, .notify-box:hover { border: 1px solid #f09509; }
.notify-box .left{width: 50px;}
.notify-box .right{width: calc(100% - 50px); padding-left: 20px;}
.notify-icon .icon{
    background: url(../images/speaker.png) no-repeat; background-size: contain; height: 50px; width: 50px;
    display: inline-block;
}
.notify-box .notify-cnt h5 {
    font-size: 16px; font-weight: 500; margin: 0px 0px 5px 0px; 
}
.notify-box .notify-cnt p { font-weight: 400; margin: 0px 0px 0px 0px; height: 20px;
    overflow: hidden; }
.notify-box:after{
    content: ''; height: 15px; width: 15px; background: #fff; border: 2px solid #e0e0e0;
    border-radius: 100px; position: absolute; top: 10px; right: 10px;
}
.notify-box.active:after, .notify-box:hover:after {background: #f09509; border: 2px solid #f09509;}
.home-banner .slick-arrow, .notify-row .slick-arrow { top: 46.7%; width: 40px; height: 32px;}
.notify-row .slick-slider .slick-prev {left: -30px;}
.notify-row .slick-slider .slick-next {right: -30px;}

.tag_list{ width: 100%; display: block; clear: both; }
.tag_list ul{ padding: 0; margin: 0; }
.tag_list li{ list-style: none; margin: 0 5px 0 0; display: inline-block; }
.tag_list li a{ background: #fff; border: 1px solid #cfcece;
    color: #000;
    height: 28px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    margin: 0 auto 9px;
    line-height: 9px;
    border-radius: 50px; display: inline-block;}
.tag_list li a:hover, .tag_list li.active a  {
    background: #5796c9; border: 1px solid #5796c9;
    color: #fff;
    height: 28px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    display: block;
}

/*order_now popup*/
#dineout-popup {
    max-width: 600px;
    padding: 0;
    margin: 30px auto; background: #1e1d1d;
}
#dineout-popup .mfp-close-btn-in .mfp-close{ color: #fff; }
#dineout-popup button.mfp-close {
    color: #fff;
}
#order-popup {
    max-width: 600px;
    padding: 0;
    margin: 30px auto; background: #1e1d1d;
}
.sprite_lst_icon{width: 48px; height: 40px; background: url(../images/sprite_list.png) no-repeat; display: inline-block; margin-right: 1px;    vertical-align: middle;}
.sprite_deliver{ background-position: 0 0;}
.sprite_takeaway{ width: 30px}
.sprite_adv_order{ width: 30px}
.sprite_reserve{ width: 26px}
.sprite_cater{ width: 30px}
.sprite_cater{ width: 30px}
.sprite_madbar{ width: 30px}
.order_now_popup_list{padding: 0;}
.order_now_popup_list li{margin-bottom: 6px; list-style: none}
.order_now_popup_list li a{padding: 10px 15px; background: #fff; text-align: center; display: block; font-size: 32px; font-weight: 600; color: #1e1d1d; text-transform: uppercase; transition: all 0.3s ease-in;}
.order_now_popup_list li .sprite_deliver{background-position: -752px -2px}
.order_now_popup_list li .sprite_takeaway{background-position: -815px -2px }
.order_now_popup_list li .sprite_adv_order{background-position: -859px -2px}
.order_now_popup_list li .sprite_reserve{background-position: -908px -2px}
.order_now_popup_list li .sprite_cater{background-position: -959px -2px}
.order_now_popup_list li .sprite_madbar{background-position: -758px -50px}
.order_now_popup_list li a:hover , .order_now_popup_list li a.ordernow-active{ background: #ff9335; }



.menu-section-mobile-inner .slick-track, .mobile-menu-section-left .slick-track{
    border-bottom: 4px solid #e7e6e6;
}
.menu-section-mobile-inner .slick-track .slick-slide > div, .mobile-menu-section-left .slick-track .slick-slide > div{display: block; width: 100%;}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{
    padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #1f1f1f;
    text-transform: capitalize;
    font-size: 17px;
    line-height: normal; font-weight: 600;
    display: block;
    height: 100%;text-align: center}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #e87b1e;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link:before{    left: 0; right: 0;}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link{color: #181818}
.menu-section-mobile-inner, .mobile-menu-section-left{display: none}
.menu-section-mobile-inner button.slick-arrow, .mobile-menu-section-left button.slick-arrow, .products_sidebar_inner button.slick-arrow {
    position: absolute;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    margin: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}
.menu-section-mobile-inner button.slick-arrow:before, .mobile-menu-section-left button.slick-arrow:before, .products_sidebar_inner button.slick-arrow:before {
    position: absolute;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -8px;
    background: url(../images/left-arrow.png) no-repeat;
    content: "";
    margin-left: 6px;
}
.menu-section-mobile-inner button.slick-arrow.slick-next:before, .mobile-menu-section-left button.slick-arrow.slick-next:before, .products_sidebar_inner button.slick-arrow.slick-next:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-top: -6px;
}
.menu-section-mobile-inner .slick-prev, .mobile-menu-section-left .slick-prev, .products_sidebar_inner .slick-prev {
    left: 0;
}
.menu-section-mobile-inner .slick-next, .mobile-menu-section-left .slick-next, .products_sidebar_inner .slick-next {
    right: 0;
}
.menu-section-mobile-inner button.slick-arrow {
    color: #000;
    border-radius: 8px;
    cursor: pointer;
    background: #e87b1e;
    border: 0;
    font-size: 16px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    z-index: 2;
 border-radius: 3px;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link {
    color: #e87b1e;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:hover:before, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:hover:before {
    left: 0;
    right: 0;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:hover:before, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:hover:before {
    background: #e87b1e;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:hover {
    color: #e87b1e;
}
.hmenu_actions > li.htico_wishlist {
    display: none;
}







section.product-offer-section {
    margin: 0 0 30px;
}
.slick-slide .menu-list-intvl, .product-offer-section .slick-slide {
    height: 100%;
}
.product-offer-section .slick-track .slick-slide > div {
    display: block;
    width: 100%;
}
.product-offer-section .pro-offer-item{ padding: 15px 5px;}
.pro-offer-box-active{background: #fff; border: 1px solid #fff; box-shadow:0px 1px 16px 0px rgba(0, 0, 0, 0.1); border-radius: 10px; padding: 25px; position: relative;display: flex;  justify-content: space-between; transition: 0.2s linear all; }
.pro-offer-box-active:hover{ border-color: #f09509 }
.pro-offer-icon { width: 53px;}
.pro-offer-txt {
    width: calc( 100% - 73px );
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}

.pro-offer-box-active:before {
    position: absolute;
    top: 8px;
    right: 8px;
    content: '';
    border-radius: 50%;
    width: 17px;
    height: 17px;
    background: #f7f7f7;
    border: 2px solid #e0e0e0;
}

.pro-offer-box-active:hover:before {
    border: 2px solid #f09509;
    background: #f09509;
}

.f-socail-btm ul li a .fa-instagram:before{
    content: "\f16d";
}

.pro-offer-txt h5 {
    margin: 0 0 5px 0;
    text-transform: inherit;
    font-size: 16px;
}
.pro-offer-txt p{margin: 0; color: #37433b;}
.pro-offer-dot-select {
    display: none;
}

.product-lhs .product-offer-section .slick-slider .slick-dots{
    text-align: center;
    padding: 0;
}
.product-lhs .product-offer-section .slick-slider .slick-dots li{
    display: inline-block;
    padding-right: 8px;
}

.product-lhs .product-offer-section .slick-slider .slick-dots li button{
    width: 12px;
    height: 12px;
    line-height: 12px;
    font-size: 0;
    text-align: center;
    border-radius: 50%;
    padding: 0;
    margin: 5px 0 0;
    border: 0;
}

.product-lhs .product-offer-section .slick-slider .slick-dots li.slick-active button{
    background: #49190d;
    color: #fff;
}
.hide-cel-cls{display: none;}
.load-more-btndiv {
    text-align: center;
    padding: 20px 0 0 0;
}
.product-offer-section ul.slick-dots {
    display: none !important;
}


.menu-section-left {
    position: sticky;
    -webkit-position: sticky;
    width: 100%;
    top: 105px;
    z-index: 9;
}


.menu-section-mobile-inner figure.small_sticky {
    position: fixed;
    width: 100%;
    max-width: inherit;
    top: 166px;
    z-index: 9;
    background: #fff;
    left: 0;
    padding: 0 15px;
}




.page-banner {
    padding: 60px 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    background: #000;
}
.page-banner:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 60%);
    position: absolute;
    left: 0;
    top: 0;
}
.page-banner img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    bottom: 0;
    z-index: -1;
}
.page-banner .inner-banner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    height: 100%; position: relative;
    z-index: 9;
}
.page-banner h2 {
    font-size: 36px;
    color: #fff;
    line-height: 1;
    margin: 0 0 10px; font-weight: 600; text-transform: uppercase;
}
.page-banner p {
    font-size: 15px;
    color: #fff;
}
.cms-page {
    margin: 0px 0 30px;
    padding: 30px 0 0;
}
.page-banner .inner-banner-content p:last-child {
    margin: 0;
}
.hobby_list h5 {
    margin: 8px 0 12px;
}
.hobby_list ul {
    padding: 0; margin: 0;
}
.hobby_list ul li {
    list-style: none;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}
.hobby_list ul li a {
    display: inline-block;
    color: #a09e9e;
    font-weight: 600;
    font-size: 15px;
    background: #efedec;
    padding: 8px 30px;
}
.hobby_list ul li a:hover, .hobby_list ul li a.active {
    background: #e87b1e;
    color: #fff;
}

.order_t {
    background-position: -500px 0;
}
.orc_list li a:hover .order_t, .orc_list li.active a .order_t {
    background-position: 0 0;
}
.reserve_icon_t {
    background-position: -657px 0;
    width: 35px;
}
.catering_icon_t {
    background-position: -702px 0;
    width: 35px;
}

.web_order_typecls li img {
    display: none;
}
.orc_list li a:hover .reserve_icon_t, .orc_list li.active a .reserve_icon_t {
    background-position: -157px 0;
}
.orc_list li a:hover .catering_icon_t, .orc_list li.active a .catering_icon_t {
    background-position: -201px 0;
}

.two-col {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 30px;
}
.two-col .two-col-left, .two-col .two-col-right {
    width: 48%;
    margin: 0 1%;
    padding: 20px;
    background: #f3f3f3;
}

.about-slider.owl-carousel {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.about-slider.owl-carousel .item {
    width: 31%;
    margin: 0 1% 20px;
}
.about-slider.owl-carousel .item a.zoom-icon {
    display: none;
}
.setting_menu_list .list_nfcn > div {
    width: 100% !important;
}
.setting_menu_list ul.myacc_dropmenu li a {
    color: #e87b1e;
}
.setting_menu_list ul.myacc_dropmenu li a:hover {
    color: #fff !important;
}
.tnk-chk-order {
    padding: 20px 0 0;
}

/* -=-=-=-=-=-=-=-= Product Details Start -=-=-=-=-=-=-=-= */

.inner_product_slider {
    line-height: 1px;
}
.text-box em {
    text-transform: uppercase;
    font-size: 12px;
    color: #181818;
    text-align: right;
    float: right;
    margin-top: 0;
    font-weight: normal;
}
.prodet_baninfo .nav{
    border-bottom: 4px solid #e9e9e9;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 20px; margin-top: 20px;
}

.tab-content .product_chosen_item_left .compo_minmax_maindiv .chosen_adn .pull-right .qty_bx .qty_minus{
    box-shadow: 1px -1px 8px rgb(0 0 0 / 19%);
    border: 2px solid #000;
}

.tab-content .product_chosen_item_left .compo_minmax_maindiv .chosen_adn .pull-right .qty_bx .qty_plus{
    box-shadow: 1px -1px 8px rgb(0 0 0 / 19%);
    border: 2px solid #000;
}


.prodet_baninfo .nav li a{
    padding: 5px 2px;
    position: relative;
    cursor: pointer;
    color: #0a0b10;
    text-transform: capitalize;
    font-size: 15px;
    line-height: normal;
    display: block;
    height: 100%;
    border-radius: 0;
    border: 0;
    margin-right: 0; font-weight: 700;
}
.prodet_baninfo .nav li a{
    background: transparent;
}
.prodet_baninfo .nav li{
    width: 50%;
}
.prodet_baninfo .nav li a:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #e87b1e;
    height: 4px;
    -webkit-transition-property: left,right;
    transition-property: left,right;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.prodet_baninfo .nav li.active a:before {
    left: 0;
    right: 0;
}
.prodet_baninfo .nav-tabs > li.active > a, .prodet_baninfo  .nav-tabs > li.active > a:focus, .prodet_baninfo  .nav-tabs > li.active > a:hover {
   color: #000;
   cursor: default;
   background-color: #fff;
   border: none;
   border-bottom-color: transparent;
}
.product_chosen_inner .tab-content {
    width: 100%;
}
.tab-content .compo_acc_innerdiv .custom_radio {
    padding: 10px 30px 10px 0;
    display: block;
}
.tab-content .compo_acc_innerdiv .custom_radio input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}
.tab-content .compo_acc_innerdiv .custom_radio span {
    padding: 0;
}
.tab-content .compo_acc_innerdiv .custom_radio span {
    position: relative;
    padding: 0;
    pointer-events: none;
    color: #353535;
    display: block;
}
.tab-content .compo_acc_innerdiv .custom_radio span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #d0cfcb;
    left: 0;
    top: 1px;
    pointer-events: none;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}
.tab-content .compo_acc_innerdiv .custom_radio span:before {
    right: 0;
    left: 100%;
}
.tab-content .compo_acc_innerdiv .custom_radio input[type=radio]:checked+span:before {
    background: url(../images/rado-select.png) no-repeat;
    z-index: 1;
    border: 0;
}
.tab-content .compo_acc_innerdiv .custom_radio span:after{ display: none; }
.tab-content .compo_acc_innerdiv {
    padding: 10px 0px 0px 12px;
}
.product_chosen_col_inner {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}


.prodet_bansec .slick-slider .slick-dots {
    display: flex!important;
    flex-flow: wrap;
    justify-content: flex-start;
    flex-direction: row;
    padding: inherit;
}

.prodet_bansec .slick-slider .slick-dots li:first-child {
    padding: 10px 0;
}

.prodet_bansec .slick-slider .slick-dots li {
    position: relative;
    padding: 10px 0 10px 10px;
    cursor: pointer;
    max-width: 143px;
    list-style: none;
}

.prodet_bansec .slick-slider .slick-dots li img {
    display: block;
    max-width: 100%;
    height: 145px;
    border: 3px solid #49190d;
}
.prodet_bansec .slick-slider .slick-dots li.slick-active img {
    border-color: #e87b1e;
}
.inn-product-popup-bg.prodet_banleft {
    padding: 30px 30px 0;
}
.related-tags ul li, .product-tags-list ul li{ background-color: #5796c9; padding: 3px 15px; font-family: 'Poppins', sans-serif; }

.special-sec span.special {
    max-width: inherit;
    margin: 0 5px 9px;padding: 10px 15px;
    display: inline-block;
    width: auto;
}
.prd_chosen_sub_row .prd_chosen_sub_col {
    /* display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: center;
    align-items: center;
    max-width: 100%;
    padding: 0; justify-content: center; */
}
 

/* .prd_chosen_sub_row .addcart_row{ 
    width: 150px;
    display: inline-block;
    width: 33%;
    padding: 0 0px 0 0px;
    margin: 0;
 } */
 .addcart_row{ 
    padding: 0 5px;
    margin: 0 auto;
    -webkit-align-items: center;
    align-items: center;
    max-width: 320px;
 }
.prd_chosen_sub_row p.prt-sct {
    /* display: block; */
    width: 132px;
     /* margin: 5px 0 15px; */
}
.prd_chosen_row.compo_top_div { padding: 0; }
.product_chosen_col {
    width: 100%;
    margin-right: 0;
}
.tab-content .compo_acc_innerdiv :after {
    display: none;
}
.inn_product_row .prd_chosen_sub_row > p {
    margin: 0;
}

/* -=-=-=-=-=-=-=-= Product Details End -=-=-=-=-=-=-=-= */



/* -=-=-=-=-=-=-=-= Mobile Menu Start -=-=-=-=-=-=-=-= */

.mobile-menu {
    position: fixed;
    padding: 0;
    overflow: hidden;
    background-color: #e36410;
    height: 100vh;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    width: 100vw;
    z-index: 99999;
    max-width: 520px
}

.mobile-menu .menu {
    list-style: none;
    padding: 0;
    margin: 0 0 30px
}

.mobile-menu .menu li {
    list-style: none;
    position: relative
}

.mobile-menu .mobile-menu-body {
    overflow: hidden;
    overflow-y: auto;
    padding: 50px 40px 40px;
    height: 100%
}

.mobile-menu.open {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.mobile-menu.open .menu a {
    -webkit-animation: a .4s .2s backwards;
    animation: a .4s .2s backwards
}

.mobile-menu.open .menu li:nth-of-type(2) a {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.mobile-menu.open .menu li:nth-of-type(3) a {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.mobile-menu.open .menu li:nth-of-type(4) a {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.mobile-menu.open .menu li:nth-of-type(5) a {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}
.mobile-menu.open .menu li:nth-of-type(6) a {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
}
.mobile-menu.open .menu li:nth-of-type(7) a {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}
.mobile-menu.open .menu li:nth-of-type(8) a {
    -webkit-animation-delay: .9s;
    animation-delay: .9s
}
.mobile-menu.open .menu li:nth-of-type(9) a {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}
.mobile-menu.open .menu li:nth-of-type(10) a {
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s
}
.mobile-menu.open .menu li:nth-of-type(11) a {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s
}

.mobile-menu .toggle-submenu-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    display: block;
    height: 60px;
    cursor: pointer
}

.mobile-menu .toggle-submenu-button:before {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg)
}

.mobile-menu .toggle-submenu-button:after,
.mobile-menu .toggle-submenu-button:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 14px;
    z-index: 1;
    content: ""
}

.mobile-menu .toggle-submenu-button:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:after {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn)
}


.mobile-menu .mobile-menu-close {

}

.mobile-menu .mobile-menu-close span {
    cursor: pointer;
    display: block;
    width: 20px;
    height: 20px;
    position: relative
}

.mobile-menu .mobile-menu-close span:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.mobile-menu .mobile-menu-close span:after,
.mobile-menu .mobile-menu-close span:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 20px;
    z-index: 2;
    content: "";
    border-radius: 50px;
}

.mobile-menu .mobile-menu-close span:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mobile-menu .mobile-menu-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0;
    position: fixed;
    top: 0;
    right: 40px;
    left: 40px;
    height: 50px;
    background: #e36410;
    z-index: 2;
    text-align: right;
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
}

.mobile-menu .menu a {
    display: block;
    font-size: 17px;
    color: #fff ;
    -webkit-transform: translateZ(0);
    transform: translateZ(0); font-weight: 600;
    padding: 15px 40px 15px 50px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.mobile-menu .menu li img {
    position: absolute;
    left: 10px;
    top: 10px;
}
.mobile-menu .menu a:hover {
    color: #000; background: #fff;
}

.mobile-menu .menu .sub-menu {
    display: none;
    padding: 0
}

.mobile-menu .menu .sub-menu li {
    padding-right: 0
}

.mobile-menu .menu .sub-menu li:last-child {
    border-bottom: 0
}


.mobile-menu .menu li {
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
    padding-right: 0
}

@-webkit-keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

/* -=-=-=-=-=-=-=-= Mobile Menu End -=-=-=-=-=-=-=-= */

.hcart_scrollarea .cart_info p {
    margin: 0;
    font-size: 12px;
}
.hcart_scrollarea .cart_remove {
    background: #b0b0b0;
    position: absolute;
    top: 0;
    right: 10px;
    width: 14px;
    height: 14px; bottom: 0; margin: auto;
}
.hcart_scrollarea .cart_remove:after{
    background: url(../images/cross1.png) no-repeat center; content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; width: 10px; height: 10px; background-size: contain;
    
}

/* -=-=-=-=-=-=-=-= Events Section Start -=-=-=-=-=-=-=-= */

.events-section{ width: 100%; text-align: center; padding: 40px 0 0; }
.pswp {
    z-index: 9992 !important;
}
ul.events-lists {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.events-lists li {
    padding: 10px;
    width: 25%; cursor: pointer;
}

/* -=-=-=-=-=-=-=-= Events Section End -=-=-=-=-=-=-=-= */

/* -=-=-=-=-=-=-=-= Notifications Page Start -=-=-=-=-=-=-=-= */
.nfcn-list-items {
    width: 100%;
    padding: 40px 0;
}
.nfcn-list-items ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.nfc-bx {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    margin: 18px 0;
    padding: 20px;
    border: 1px solid #e0e0e0;
}
.nfc-bx.done-notify{ background: #f3f3f3; }
.nfc_left {
    width: 80%;
    position: relative;
    padding: 0 20px 0 50px;
}
.nfc_right {
    width: 20%;
    text-align: right;
}
.nfc_left img {
    position: absolute;
    left: 0;
    top: 4px;
}
.nfc_left h4 {
    margin: 0 0 15px;
}
.nfc_left h4 span {
    color: #e87b1e;
}
.nfc-bx h6 {
    margin: 0 0 10px; text-transform: lowercase; font-weight: 600;
}
.nfc-bx .button {
    margin: 10px 0px 10px 0px;
    padding: 12px 18px 12px;
    font-size: 16px;
}

/* -=-=-=-=-=-=-=-= Notifications Page End -=-=-=-=-=-=-=-= */

/* -=-=-=-=-=-=-=-= Participating Merchants Page Start -=-=-=-=-=-=-=-= */

.participating-merchants {
    padding: 40px 0;
}
.participating-merchants img{ margin: 0 0 12px; width: auto !important; height: inherit !important; }
.par_mer_inner .accordion__button {
    background-color: #e87b1e; color: #fff; font-family: 'Poppins', sans-serif;
    font-weight: 600; position: relative; padding: 18px 50px;    transition: all 400ms ease-in-out 0s; }
.par_mer_inner .accordion__button:hover {
    background-color: #000; color: #fff;
}
.par_mer_inner .accordion__item + .accordion__item {
    border-top: 1px solid #ffac65;
}
.accordion__button:before {
    position: absolute;
    left: 20px;
    top: 25px;
}

/* -=-=-=-=-=-=-=-= Participating Merchants Page End -=-=-=-=-=-=-=-= */


.our-promotions-section button.slick-arrow.slick-prev {
    left: -25px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
button.slick-arrow.slick-next {
    right: 5px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); 
}

.ReactModal__Overlay{ z-index: 9994 !important; }
.ril__zoomInButton:hover{ background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+) no-repeat center; }
.ril__zoomOutButton:hover {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=) no-repeat center;
}
.ril__builtinButtonDisabled:hover {
    opacity: 1;
}
.ril__closeButton:hover {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=) no-repeat center;
}
.our-promotions-section .slick-slide img{ cursor: pointer; }


@media screen and (max-width: 3000px) and (min-width: 1600px) {

    .home-banner .slick-initialized .slick-slide > div>div:after { width: 50%; }

}
@media (max-width: 1270px) {
    .top-bar p {  margin: 0 0 0 188px; }
    ul.hmenu_list > li > a{ margin: 0 10px; padding: 14px 0px 14px; }
    .hordertype_sec a{ font-size: 15px; }
}

@media (max-width: 1250px) {
    ul.hmenu_list > li > a{ margin: 0 10px; font-size: 14px; padding: 14px 0px 14px; }
    .hordertype_sec a, .hsign_sec a { font-size: 14px; }
    .hordertype_sec a { padding: 10px 14px; }
}

@media (max-width: 1199px)  {
    .common-top-div.home-main-div { padding-top: 0 !important; }
    .top-bar{ margin: 0; }
    .container.common-top-div { padding-top: 0px !important; }
    .container .home-ordernow-section { padding-top: 0; }
    .lft-sec-prt h3{ font-size: 34px; }

    .home-banner .slick-initialized .slick-slide > div>div:after {
        border-top: 2450px solid #f5f5f5;
        border-right: 660px solid transparent;
    }
    .hcategory_menu{ z-index: 99; }
    .hcategory_submenu >li >a:hover{ color: #e87b1e; }
    .common-top-div.productpage-main-div { padding-top: 0!important; }
    .notify-box{ width: 49%; }
    .hcategory_trigger{ z-index: 991; }
    .innerproduct-col { width: 31.33% !important; margin: 0 1% 15px!important; }
    .hmenu_actions{ padding: 0 0 0 18px; }

    .setting_menu_list { display: block; }
    
    .home-banner .slick-initialized .slick-slide{ min-height: inherit; }
    
    .mainacc_menulist > li >a{ font-size: 16px; }
}
@media (max-width: 1024px) {
.product-offer-section ul.slick-dots {
    display: block !important;
    padding: 12px 0 0;
    margin: 0 0 25px;
    display: flex !important;
    list-style: none;
    justify-content: center;
    width: 100%;
}
    .product-offer-section ul.slick-dots li {
    margin: 0 5px;
}
    .product-offer-section button {
    background: #000;
    width: 11px;
    height: 11px;
    text-indent: -9999px;
    padding: 0;
    list-style: none;
    border-radius: 50%;
    cursor: pointer; margin: 0;
}
.product-offer-section .slick-track .slick-slide{ justify-content: center; }
.product-offer-section .slick-dots li.slick-active button { background: #e87b1e; }
}
@media (max-width: 1000px) {
    .home-banner .slick-initialized .slick-slide > div>div:after {
        border-top: 2450px solid #f5f5f5;
        border-right: 500px solid transparent;
    }
    /*.home-banner .banner-info { max-width: 320px; padding: 0; }*/
    .home-banner .banner-info{ 
        left: 0px!important; 
        padding: 0!important;
        position: relative;
        right: 0!important;
        /*z-index: 91;*/
        /*max-width: 1280px;*/
        top: -1px;
        -webkit-transform: none;
        transform: none!important;
        margin: 0 auto; text-align: none;
}
.banner-info{
    text-align: center;
    top: -1px;
    left: 0px;
    right: 0px;
}

    .home-banner .banner-info h2 {
        font-size: 30px;
        margin-bottom: 20px;
        line-height: 40px;
    }
    .home-banner .banner-info p {
        font-size: 17px;
    }
    .join-us-section .lft-sec-prt {
        width: 100%;
        color: #fff;
        order: 1;
        margin: 0 0 10px;
    }
    .join-us-section .mid-sec-prt {
        width: 100%;
        position: relative;
    }
    .join-us-section .mid-sec-prt img {
        position: inherit;
        bottom: inherit;
        left: 0;
        right: 0;
        margin: -40px auto 20px;
    }
    .lft-sec-prt h3 {
        font-size: 38px;
    }
    .join-us-section .rgt-sec-prt { width: 100%; color: #fff; order: 3; }
    
    .three-boxes li {
    width: 48%; margin: 0 1% 30px; }

.bottom-three-boxes li {
    width: 48%; margin: 0 1% 30px; }



.innerproduct-col { width: 48% !important; margin: 0 1% 15px!important; }
.notify-box {
    width: 48%;
    margin: 10px 1%;
}

    .events-lists li {
        padding: 10px;
        width: 33.333%;
        cursor: pointer;
    }
    .events-lists li img{ width: 100%; }
}
@media (max-width: 991px) {
    .product_chosen_inner ul.nav.nav-tabs.text-center {
        display: none;
    }
    .product_chosen_item_left.product_chosen_item_left_full {
        width: 100%;
        padding: 0;
    }
    
    .accpoints_sec {
        padding: 15px 7px 0px;
    }
    .setting_menu_list {
        position: inherit;
    }
    .accearn_points { padding: 5px 15px 10px; }
    .accearn_pointtop i {
        position: inherit;
        left: 0;
        top: inherit;
        margin: auto;
        display: block;
        text-align: center; padding: 0 0 10px;
    }
    .accpoints_sec {
        padding: 5px 7px 0px;
    }
    .accearn_pointtop {
        position: relative;
        padding-left: 52px;
        text-align: center;
        padding: 0;
    }
}

@media (max-width: 970px) {
    
    /* .prd_chosen_sub_row .addcart_row {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0 auto;
    } */
    .prd_chosen_sub_row .prd_chosen_sub_item_right {
      
    }
    
    .nfc_left { width: 70%; }
    .nfc_right {
        width: 30%;
    }
}

@media (max-width: 850px) {
    .notify-box {
        width: 100%;
        margin: 10px 0;
    }
}
@media (max-width: 768px) {
    .home-banner .slick-initialized .slick-slide > div>div:after{ display: none; }
    
    .two-col {
    width: 100%;
    display: block;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 10px;
}
    .two-col .two-col-left, .two-col .two-col-right {
    width: 100%;
    margin: 0 0 20px;
    padding: 20px;
    background: #f3f3f3;
}
    
}
@media (max-width: 780px) {
    .top-bar span {
        position: inherit;
        left: inherit;
        top: inherit;
       
        width: 100%;
        text-align: center;
        display: block;
    }
    .top-bar span:after {
        content: ''; display: none;
    }
    .top-bar p { margin: 0; }
    .five-boxes{ padding: 25px 0 15px; }
    .two-boxes li, .three-boxes li{ width: 98%; margin-bottom: 20px; }

    .two-boxes li, .bottom-three-boxes li{ width: 98%; margin-bottom: 20px; }
    .our-promotions-section{ padding: 0 0 30px; }
    .hcart_dropdown h3 { font-weight: 600; font-size: 16px; }
    .hcart_dropdown .cart_info {
        display: block;
        vertical-align: top;
        color: #3d3d3d;
        width: 100%;
        margin: 10px 0;
    }
    .cart_row.grant-total-cls p, .cart_row.grant-total-cls span { font-size: 22px; }
    
    .row-pgk-list > li, .pkg_gcol {  width:100%;}    
    .cart_row.cart-header-second h3 { font-weight: 600; font-size: 16px; }
    .hcart_dropdown .cart_info {
        display: block;
        vertical-align: top;
        color: #3d3d3d;
        width: 100%;
        margin: 10px 0;
    }
    .cart_row.grant-total-cls p, .cart_row.grant-total-cls span { font-size: 22px; }
    
    .events-lists li {
        padding: 10px;
        width: 50%;
        cursor: pointer;
    }
}
@media (max-width: 680px) {

    .nfc_left { width: 100%; text-align: center; padding: 0; }
    .nfc_right { width: 100%; text-align: center; padding: 0; }
    .nfc_left img {
        position: inherit;
        left: 0;
        top: 0;
        display: block;
        margin: 0 auto 8px;
    }
    .nfc-bx.done-notify .button { margin: 5px 0px 0px 0px; }
}

@media (max-width: 580px) {
    .menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link 
    { font-size: 14px;  }
    

    .prd_chosen_sub_row .prd_chosen_sub_item_right {
        width: 100%;
        padding: 0;
    }
    
    .events-lists li {
        padding: 10px;
        width: 100%;
        cursor: pointer;
    }

}
@media (max-width: 580px) {
    .prd_chosen_sub_row p.prt-sct{
        text-align: center;
    margin: 0 auto;
    padding-bottom: 15px;
    }
}

.geores-cont{
    width: 100%;
    margin: 10px 0;
}
.promo-earned-col-item{
   text-align: center;
      display: flex;
    background: #fff;
    box-shadow: 0 1px 9px 0 rgb(0 0 0 / 14%);
    height: 100%;
}

.hmenu_actions .hwhtts{
    line-height: 1;
} 

.product_voucher_body .main_tabsec .product_voucher li a{
    background-color: transparent;
}

.product_voucher_body .main_tabsec .product_voucher li a span{
    font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300))) !important;
    font-weight: normal;
    text-transform: capitalize;
}

.product_voucher_body .main_tabsec .product_voucher li a:hover{
    background-color: transparent;
}

.product_voucher_body .main_tabsec .product_voucher li.active > a, 
.product_voucher_body .main_tabsec .product_voucher li > a, 
.product_voucher_body .main_tabsec .product_voucher li.active > a:focus, 
.product_voucher_body .main_tabsec .product_voucher li.active > a:hover{
    background-color: transparent;
    font-weight: 800;
}

.product_voucher_body .main_tabsec .product_voucher li:before{
    background: #000 !important;
}

.product_voucher_body .product_voucher{
    margin-bottom: 30px;
}

.product_voucher_body .main_tabsec_inner{
    padding-top: 0;
}

.filter_tabsec .product_voucher ul li.active a:after{
   display: none;
}

.filter_tabsec .product_voucher.nav-tabs>li>a:after{
    display: none;
}

.main_tabsec_inner.product_tag_sec_main{
    background-color: #fff;
}

.product_voucher_body .main_tabsec .product_voucher li.active > a, 
.product_voucher_body .main_tabsec .product_voucher li > a, 
.product_voucher_body .main_tabsec .product_voucher li.active > a:focus{
    padding-bottom: 0;
    background: #ffff;
    color: #000;
    border-color: #aaa;
    border-radius: 5px 5px 0 0;
    padding: 6px 12px;
}

.product_voucher_body .main_tabsec .product_voucher li{
    width: auto;
    border-bottom: 0;
}

.product_voucher_body .main_tabsec .product_voucher li.active > a{
    border: 1px solid #aaa;
    border-bottom: none;
    bottom: -1px;
    position: relative;
}

.product_voucher_body .main_tabsec .product_tag_sec_main{
    padding: 10px 0 0;
}

.product_voucher_body .main_tabsec .product_voucher li:hover > a{
    background-color: transparent;
}
ul.products-list-ulmain {
    padding: 0;
    list-style: none;
}
.product-price h3 {
  margin: 0;
 font-weight:600    
}
.product-price h3 sup {
  font-size: 70%;
}
.product-spl-price {
  text-align: center;
  padding-bottom: 7px;
}
.product-spl-price h3 {
  font-size: 16px;
  margin-bottom: 0;
  display: inline-flex;
  color: #949292;
  text-decoration: line-through;
  font-weight: normal;
}

.product-spl-price h3 sup{
    
    top: 3.5px;
}
.venue_popup .order-body h2 {
        color: #000;
        margin-bottom: 20px;
}
.venue_popup .order-body p {
    color: #000;
}

.res-cntmaindiv {
    padding: 30px 30px 0;
}
.res-cntmaindiv p{
    font-weight: 600;
}


.home-banner-universal .slick-dots{     
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    text-align: center;
}
.home-banner-universal .slick-dots li button{
    padding: 0;
    margin: 0 5px;
    border-radius: 50%;
    overflow: hidden;
    text-indent: -9999px;
    background: #ec6851;
}

.home-banner-universal .slick-dots{
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
}

.home-banner-universal .slick-dots li button{
    height: 11px;
    width: 11px;
    border: 1px solid #fff;
    background: transparent;
}

.home-banner-universal .slick-dots li.slick-active button{  background: #fff;  }

.home-banner-universal .slick-dots li{display: inline-block}
.home-banner-universal .slick-track .slick-slide{ display: block !important;}

.homebanner-content-inner { max-width: 670px;}
.homebanner-content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    right: 0;
        height: auto !important;
    line-height: normal; color: #fff;
}
.banner-text h4 {
    letter-spacing: 1px;
    font-size: 48px;
    margin-bottom: 10px;
    color: #fff;
    font-family: 'Honey Candy';
    font-weight: normal;
    text-transform: uppercase;
    text-shadow: 0px 0px 5px rgb(0 0 0 / 80%);
}
.banner-text p {
    font-size: 34px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1.2; 
    margin: 0; text-shadow: 0px 0px 5px rgb(0 0 0 / 80%);
}
.home-content{ padding: 60px 0 0px 0;}
.home-content-inner h3 { text-transform: inherit; font-size: 20px; line-height: 1.7; margin: 0;}
.home-content-inner p{ font-size: 19px; line-height: 2; font-weight: 500; }
.home-content-inner ul{padding:20px 0 0 0; margin: 0; list-style: none; display: flex; justify-content: center; flex-wrap: wrap}
.home-content-inner ul li{margin:0 2% 20px 0;position: relative; width: 32%; }
.home-content-inner ul li:nth-child(3n){ margin-right: 0 }
.home-content-inner ul li:nth-child(6n) a img{ width:60px; height: 41px;}
.home-content-inner ul li a{ text-transform: uppercase; padding: 10px 10px; font-weight: 500; font-size: 22px;border: 1px solid #fff;transition: 0.2s linear all; text-align: center; box-shadow: 0 0px 13px 0 rgba(0,0,0, 0.11); display: flex; justify-content: center; align-items: center;height: 100%;min-height: 104px;     line-height: 1; background: #fff; color: #000;}
.home-content-inner ul li a strong { display: block; font-size: 12px; line-height: 1; color: #919191;}
.home-content-inner ul li a:hover, .home-content-inner ul li.active a{ background: #e36410; color: #fff }
.home-content-inner ul li a img{margin-right: 12px;filter: invert(1)}

.home-content-inner ul li a:hover .hci-normal, .home-content-inner ul li.active a .hci-normal{ filter: invert(0)}

.hci-one img{ width: 49px; height: 47px;}
.hci-two img{ width: 47px; height: 52px;}
.hci-three img{ width: 52px; height: 52px;}
.hci-four img{ width: 42px; height: 46px;}
.hci-five img{ width: 49px; height: 33px;}
.hci-six img{ width: 29px; height: 39px;}
.product-list-main-div {
    height: 100%; background: #fff;
}
.featured-products-main .owl-carousel .owl-stage {
    display: flex;
}
 .product-info-div {
    padding: 20px 20px 110px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    background: #fff;
    transition: all .3s linear;
}
.products-ordernow-action {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.products-image-div, .innerproduct-item {
    position: relative;
    padding-top: 76.55%;
}
.innerproduct-item .innerproduct-item-image{height: 100%;}
.products-image-div img, .innerproduct-item-image img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.products-ordernow-action .button {
    margin-bottom: 0;
    margin-top: 0;
}
.products-ordernow-action .product-price{ margin-bottom: 15px; }
.home-bottom-link { display: flex; justify-content:center; padding: 40px 0 0 0;}

.home-bottom-link a{ 
    display: inline-flex;
     background: #e36410; margin: 0;
}
.home-bottom-link a:hover{
    background: #000;
}
 .home-our-journey {
    position: relative;
    z-index: 1; padding: 80px 0;color: #fff;
}

.home-our-journey:before{ 
    background: url(../images/orange-bg.png) no-repeat right; background-size: cover;     content: "";
    left: 0;
    top: 50%;
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 679px;
    transform: translateY(-50%);
}


.hoj-inner{display: flex; justify-content: space-between; flex-wrap: wrap}
.hoj-lhs { width: 47%; display: flex; flex-flow: column wrap; justify-content: center; align-items: flex-start;}
.hoj-rhs{width: 47%;position: relative;}
.hoj-lhs .button { background: #111;  min-width: 170px; margin-bottom: 0;}
.hoj-lhs .button:hover { background: #fff; color: #e26024 }
.hoj-rhs .owl-dots { position: absolute; left: 0; right: 0; bottom: 30px; display: flex; justify-content: center;}
.hoj-rhs .owl-dot { width: 12px; height: 12px; border: 2px solid #fff; border-radius: 50%; margin: 0 3px;}
.hoj-rhs .owl-dot.active{ background: #fff; }
.hoj-lhs h5 { color: #fff; font-size: 14px; letter-spacing: 4.6px; margin-bottom: 15px; text-transform: uppercase;}
.hoj-lhs h2 { color: #fff; font-size: 50px; letter-spacing: 0;  font-family: 'Honey Candy'; font-weight: normal; text-transform: uppercase;}
.our-promotions-section .owl-item{padding:10px 0 30px;}
.our-promotions-section .owl-item > div{ border-radius: 20px; overflow: hidden;box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.12); }
.our-promotions-section h2{ font-size: 44px;
    letter-spacing: 0;
    font-family: 'Honey Candy';
    font-weight: normal;
    text-transform: uppercase;}




footer {
    position: relative;
    background: #000;
    padding: 45px 0 0;
    color: #fff
}

.footer-top {
    padding: 0 0 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-newletter {
    width: 32.03125%
}

.footer-logo {
    width: 26.90625%;
    text-align: center
}

.footer-nav {
    width: 31.6875%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.footer-nav-inner {
    width: 49%
}

.f-newletter-form {
    position: relative
}



.footer-nav h5, .footer-newletter h5 {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 4.69px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.footer-nav ul {
    padding: 0;
    margin: 0;
    list-style: none
}

.footer-nav ul li a {
    color: #fff;
    display: inline-flex;
    margin-bottom: 10px;
    font-size: 14px;
}

.footer-nav ul li a:hover {
    color: #e36410
}

.social-media a img {
    height: 33px;
    width: 33px;  filter: invert(1);
}

.social-media a {
    display: block;
    margin-right: 20px
}

.social-media {
    margin: 30px 0 0;
    display: flex;
    justify-content: center
}

.social-media a:last-child {
    margin-right: 0
}

.social-media img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    transition: all .2s cubic-bezier(.63,.05,.43,1.7)
}

.copyright-section p {
    font-size: 14px;
    color: #838383;
    text-align: right;
    margin-bottom: 0
}

.copyright-section {
    background: #191919;
    padding: 20px 10px;
}
.our-promotions-section .owl-dots { position: absolute; left: 0; right: 0; bottom:0px; display: flex; justify-content: center;}
.our-promotions-section .owl-dot { width: 12px; height: 12px; border: 2px solid #e97d21; border-radius: 50%; margin: 0 3px; background: #fff;}
.our-promotions-section .owl-dot.active{ background: #e97d21; }
.promo-product {
    padding: 80px 0 20px 0;
}
.footer-logo > img {
    max-width: 215px;
}
.footer-newletter svg {
    max-width: 21px;
    max-height: 21px;
    margin-right: 12px;
}

.footer-newletter h5 {
    display: flex;
    align-items: center;
    font-size: 16px;
    letter-spacing: 3px;
}

.footer-newletter p{margin-bottom: 8px;}
.ft-tp-col1{padding-bottom: 35px;}
.footer-newletter a{color: #f2f2f2}
.ft-tp-col3 strong{display: block; font-weight: 600; padding-top: 3px;}
.ft-tp-col1 a{margin-right: 15px;display: inline-block;}
.ft-tp-col3 {
    padding-top: 30px;
}
.mainacc_menuout {
    width: 220px;
}
.mainacc_menucontent {
    width: calc(100% - 220px);
    width: -webkit-calc(100% - 220px);     box-shadow: 0 1px 21px 0 rgba(0,0,0, 0.08);
    background: #fff; border: 3px solid #f4f3f3;
}

.top-info {
    background: url(../images/myaccount-bg.jpg) repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 40px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 6%);
}
.top-info-lhs-txt h5 {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 5px 0;
}

.top-info-lhs-txt h3 {
    font-size: 28px;
    margin: 0 0 5px 0;
    font-weight: bold;
}
.top-info-lhs-txt p {
    margin: 0;
}

.top-info-lhs-txt span {
    display: block;
}

.top-info-lhs-img {
    width: 110px;
    overflow: hidden;
    background: #000;
    height: 110px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

#chooseFile {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    cursor: pointer;
}
.top-info-lhs {
    width: 49%;
    display: flex; flex-wrap: wrap; justify-content: space-between
}
.top-info-rhs {
    width: 49%;
        display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.top-info-lhs-txt {
    font-weight: 500;
    font-size: 14px;
    width: calc( 100% - 125px );
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}
.top-info-points a{
    font-weight: 500;
    border-bottom: 1px solid #000;
    font-size: 14px; color: #000;
}
.top-info-points h2 {
    color: #e77220;
    font-size: 28px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}

.top-info-points p {
    margin: 0 0 5px 0;
}
.top-info-points {
    width: calc( 100% - 120px );
}
.top-info-kakis {
    width: 110px;
}
.point-bar {
    width: 100%;
    height: 6px;
    background: #c9c7c7;
    overflow: hidden;
    border-radius: 10px;
    position: relative; margin: 5px 0;
}
.point-bar span {
    position: absolute;
    width: 40%;
    background: #e97c1f;
    height: 6px;
    left: 0;
    top: 0;
}
.how-to {
    color: #000;
    border-bottom: 1px solid #333;
    font-weight: 500;
    line-height: 1;
    display: inline-flex;
}
.outlet-buttons .button {
    margin: 3px 3px 3px;
    padding: 10px 20px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border:2px solid rgba(0,0,0,0.23);
}

.outlet-buttons {
    line-height: 1;
}
.button.bmenu { background: #e36410;}
.button.mmenu { background: #cb0017;}
.button.followm { background: #425093;}
.button.followm i {margin: 0 10px 0 0;}
.button.lmenu { background: #e5a62c;}
.button.wmenu {background: #36923c;}
.button.cmenu {background: #0f587a;}

.inner-page {
    padding: 60px 0 40px;
}
.promo-folio-content ul, .merchants-info > ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.promo-folio-content > ul > li, .merchants-info > ul > li {
    padding: 10px;
    width: 23.828125%;
    margin: 0 1.5625% 20px 0;
    background: #fff;
    transition: all .4s linear;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 12%);
}
.promo-folio-content > ul > li:nth-child(4n), .merchants-info > ul > li:nth-child(4n) {
    margin-right: 0;
}
.promo-folio-content > ul > li img{  border-radius: 10px; width: 100%; height: 100%; object-fit: cover; }
.promo-folio-header h3{ font-weight: 600;  }

.promo-folio-tab ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.promo-folio-tab ul li {
    margin: 0 10px 10px 0;
}

.promo-folio-tab ul li a {

}
.promo-folio-tab ul li a:hover, .promo-folio-tab ul li.active a{ background: #e36410; }
.promo-folio-tab {
    padding: 0 0 20px 0;
}

.promo-folio-tab-select select {
    width: 270px;
    height: 42px;
    padding: 8px 10px;
    font-size: 14px;
    border-radius: 5px;
}
.old-outlet-content { display: none; }
.new-outlet-content { display: block !important; }
body .css-1g6gooi input{ margin: 0; height: 36px;}
.form-feedback .error{ font-size: 13px; text-align: left}
.controls input {
    margin: 0;
}
.form-feedback .controls{ margin: 0 0 10px 0;}
.myacc_filter{ padding: 10px 15px}
.promo-folio-tab .filter_tabsec .nav-tabs>li>a:after { max-width:50%;}
.promo-folio-tab .filter_tabsec .nav-tabs>li>a{font-size: 16px;}
.promo-folio-tab-select { text-align: right; }
.promo-folio-content > ul > li.no-happenings {
    width: 100%;
    background: none;
    box-shadow: none;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
}
.promo-earned-col-image .innerproduct-item-image {  position: relative;  height: 100% }

.promo-earned-col-image .innerproduct-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    max-height: inherit; max-width: inherit;
}

.promo-earned-info .description_div {
    margin: 0 0 10px 0;
}
.promation_btn {
    clear: both; margin: 6px 0 0 0;
}
.promo-bot-left{margin-bottom: 10px}
.promo-earned-info:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: -45px;
    bottom: 0;
    background: #fff;
    width: 80px;
    -webkit-transform: skewX(-15deg);
    transform: skewX(-15deg);
}
.promo-bot-left > p {
    font-weight: 700;
    font-size: 18px;
}

.promo-bot-left > p span {
    font-weight: normal;
}
.merchants{padding: 50px 0;}
.merchant-thumb{ position: relative; }

.merchant-info {
    text-align: center;
}

.merchant-info .button {
    margin: 0 auto;
}

.merchant-info h4 a {
    color: #000;
}

.merchant-info h4 {
    font-size: 18px;
    margin: 0 0 15px 0;
}
.merchants-info > ul > li{padding: 0;}
.merchant-info{padding: 25px 10px 20px;}
.merchant-logo {
    position: absolute;
    right: 15px;
    bottom: 15px;
    left: 15px;
    text-align: right;
}

.merchant-img {
    position: relative;
}
.merchants-tab .filter_tabsec .nav-tabs>li{ width: 25% }
.merchant-logo img {
    max-width: 50%;
}

.gallery-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}

.gallery-list li {
    width: 33%;
    padding: 10px;
}

.home-order-now .mobile-home-order-now{display: none;}
.home-order-now{ padding: 0 0 80px; }
.home-order-now ul{display: flex; padding: 0; margin: 0; list-style: none; flex-wrap: wrap;justify-content: space-between}
.home-order-now ul li{width: 32%;}

.home-order-now .hon-icon {
    width: 162px;
    text-align: center;
    border: 4px solid #fff;
    height: 162px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center;
    margin: 0 auto;
}
.hon-bg{width: 100%;}
.hon-icon-bg{
    left: 0;
    position: absolute;
    right: 0;
    margin: auto;
    bottom: 60px;
    text-align: center;
    -webkit-transform: translate3d(0,0px,0);
    transform: translate3d(0,0px,0);
    transition: transform 0.35s;
    line-height: 1;
}

.home-order-now ul li a:hover .hon-icon-bg{ 
    -webkit-transform: translate3d(0,-50px,0);
    transform: translate3d(0,-50px,0);
}

.hon-icon-bg span{display: block; color:#fff; text-transform: uppercase; margin: 20px 0 0 0;font-size: 17px;font-weight: 600}

.home-main-div .home-order-now ul li{
    position: relative;
    line-height: 0
}

.home-order-now-li-item{
    position: relative;
    display: block;
    overflow: hidden;
}

.home-order-now-li-item:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba(0,0,0,0.8) 85%);
    content: '';
    opacity: 0;
    transform: translate3d(0,50%,0);
    transition: opacity 0.35s, transform 0.35s;
}

.home-order-now ul li .home-order-now-li-item:hover:before {
    opacity: 1;
    transform: translate3d(0,0,0);
}

.home-order-now ul li .home-order-now-li-item:hover .hon-icon-bg {
    transform: translate3d(0,-50px,0);
}
.desktop-home-order-now .home-order-now-li-item img { width: 100%; }
.catering-main-div .innerproduct-item .innerproduct-item-image { height: auto; }
.catering-main-div .innerproduct-item { padding-top: 0; }
.catering-main-div .innerproduct-item .innerproduct-item-image img { position: inherit; }
.catering-main-div .innerproduct-col { background: #FFF; }
.catering-main-div .innerproduct-item-text { padding: 10px; }
.catering-main-div .innerproduct-item .innerproduct-button { position: relative; z-index: 2; }
.normal-popup { padding: 40px; text-align: center; max-width: 820px; } 






.product-details-lhs {
    width: 50%;
    padding: 15px;
    position: relative;
}

.product-details-rhs {
    width: 50%;
    padding:15px 15px 20px 15px;
}
.product-details-top-info .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product-details-img .slick-arrow {
    display: none;
}

.product-details-img .slick-dots, .cafe-category-rhs .products-image-div .slick-dots{
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}

.product-details-img .slick-dots li button, .cafe-category-rhs .products-image-div .slick-dots li button{
    padding: 0;
    width: 11px;
    height: 11px;
    font-size: 0;
    overflow: hidden;
    margin: 0;
    background: #fff;
    border: 1px solid #000;
}

.product-details-img .slick-dots li, .cafe-category-rhs .products-image-div .slick-dots li{
    display: inline-block;
    margin: 0 5px;
}

.product-details-img .slick-dots li.slick-active button, .cafe-category-rhs .products-image-div .slick-dots li.slick-active button{
    background: #000;
}

.product-details-rhs .compo_pro_acc {
    background: #e36410;
}
.product-details-rhs .product_chosen_hea h6 {
    color: #ffffff;
background: #e36410;
}
.product-details-rhs .product_chosen_item_left{padding: 0}
.product-details-lhs .slick-slide img{width: 100%;}
.product-details-lhs .slick-track .slick-slide > div{width: 100%}
.product-description-tab ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    border-bottom: 3px solid #eaeaea;
}

.product-description-tab {
    padding: 25px 0 15px 0;
}

.product-description-tab ul li {
    width: 50%;
    position: relative;
}
.product-description-tab ul li:after {
    bottom: -3px;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: #000;
    -webkit-transition: width .3s ease 0s,left .3s ease 0s;
    transition: width .3s ease 0s,left .3s ease 0s;
    width: 0;
}
.product-description-tab ul li.active:after, .product-description-tab ul li:hover:after {
    width: 100%;
    left: 0;
}

.product-description-tab ul li a {
    display: block;
    text-align: center;
    font-weight: 700;
    padding: 0px 5px 10px; color: #000;
}
.product-description-tab ul li a:hover{
    color: #000;
}
.prodet_baninfo .inn_product_hea>h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
}
.product-go-back {
    margin: 30px 0 0 0;
}
.product-go-back a {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 15px;
    position: relative;
    padding-left: 34px;
    letter-spacing: 1px; color: #000;
}
.product-go-back a:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    background:url(../images/back-product.png) ;
    width: 24px;
    height: 11px;
}



.prd_chosen_sub_row {
    text-align: center;
    padding: 15px 0 10px;
    border-top: 1px solid #eaeaea;
    margin: 30px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
p.total_price.product_details_price {
    font-size: 34px;
    color: #000;
    margin: 0;
    width: 150px;
    text-align: left;
}
.prd_chosen_sub_col {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: calc(100% - 150px);
    justify-content: space-between;
}
.prd_chosen_sub_item_left {
    display: flex;
    /*width: 180px;*/
    width: 124px;
    margin: 0;
    flex-flow: column;
}
.prd_chosen_sub_item_right.cart_update_div {
    /*width: calc(100% - 190px);*/
/*    width: calc(100% - 134px);
*/}
.prd_chosen_sub_item_left p {
    margin: 0 0 5px; font-size: 14px;
}
.prd_chosen_sub_item_left{display: flex; padding: 0;}
h4.promo-earned-code {
    font-size: 17px;
    font-weight: 600;
}


.banner-text {
    background: rgba(0,0,0,0.65);
    padding: 30px;
    border-radius: 10px;
     color: #fff; display: inline-block;
}

.d-col-member{ background: #e26024; display: flex; flex-wrap: wrap; border-radius: 25px;    width: calc( 100% - 36px );}
.ur-exp{color: #e4e4e4;padding: 15px 0 0 0;}
.ur-exp h4 { color: #fff;  font-size: 22px; margin: 0 0 10px 0;}
.ur-exp p { margin: 0;}
.kaki-down { width: 38%;}
.kaki-member {
    color: #fff;
    display: flex;
    width: 62%;
    flex-wrap: wrap;
    padding: 20px;
    position: relative;
    justify-content: flex-end;
}
.grid-btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px; margin: 0 auto; max-width: 86%;
}

.grid-btn .button {
    margin: 0; font-weight: bold; padding: 18px 10px; 
}
.join-us-two .container{
    justify-content: flex-end;
    display: flex;
}

.hand-img {
    width: 247px;
    position: absolute;
    left: -36px;
}
.hand-text h4 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    margin: 0 0 5px 0;
}
.hand-text h4 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    margin: 0 0 5px 0;
}

.hand-text h3 {
    color: #fff;
    font-family: 'Honey Candy';
    font-weight: normal;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    margin: 0px 0px 5px 0px;
}

.hand-text h3 img {
    margin-right: 7px;
}

.hand-text {
    width: calc( 100% - 270px );
}
.hand-text p{font-size: 18px; font-weight: 500; margin: 0 0 5px 0;}
.grid-btn .button:hover{color: #fff; background: #cb0017;}

.kaki-down {
    width: 38%;
    background: #111111;
    color: #fff;
    border-radius: 25px;
    border: 2px solid #e26024;
    padding: 20px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
}
.kd-top {
    padding: 0 0 0 52px;
    position: relative;
    min-height: 66px;
}

.kd-top img {
    position: absolute;
    left: 0;
    top: 0;
}

.kd-top p {
    font-weight: 500;
    margin: 0;
}

.kd-top h3 {
    color: #fff;
    font-family: 'Honey Candy';
    font-weight: normal;
    text-transform: uppercase;
    font-size: 50px;
    margin: 0;
    line-height: 1;
}

.kd-btm a img {
    max-height: 100%;
    height: 50px;
}
.kd-btm {
    padding: 15px 0 0 0;
    display: flex;
    gap: 6px; justify-content: center;
}
.join-us{display: none;}




.addcart_row{display: none;}

.addcart_row.voucher{
    display: block;
}

.addcart_row.voucher .qty_bx {
    width: 56%;
    vertical-align: middle;
    float: initial;
}





/* -=-=-=-=-=-=-=-= Contact Us Page Start -=-=-=-=-=-=-=-= */

.contactus_wrapper {
    width: 100%;
}
.contactus_wrapper .row {
    display: block;
    width: 100%;
}
.contactus_wrapper h2 {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
}
.contactus_wrapper .row .ct-left {
    width: 75%; /* float: left; */ margin: 0 auto;
}
.contactus_wrapper .row .ct-right {
    width: 38%; float: right;
}
ul.outlets-list-row {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.outlets-list-column {
    padding: 0 20px;
    width: 48%;
    float: left; margin: 0 1% 20px; background: #f2f0eb;
}
.outlet-item {
    padding: 25px 25px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    border: 1px solid #f2f0eb;
    border-radius: 5px;
}
.outlets-list-row h3 {
    font-size: 15px;
}
.outlet-item-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.outlet-item-list ul li {
    position: relative;
    padding: 0 0 0 28px;
}
.outlet-item-list ul li img {
    /* position: initial;
    left: 0;
    top: 3px; */
    position: absolute;
    left: 0;
    top: 3px;
}
.outlet-item-img {
    position: relative!important;
    margin-bottom: 20px;
    left: 0;
    top: 3px;
}
.outlet-item-list ul li p{ margin: 0 0 5px; font-size: 14px; }
.outlet-item-list ul li p img {
    position: inherit;
}
.form-feedback {
    background: #f2f0eb;
    padding: 25px 30px;
}
.rating-fback {
    font-size: 14px;
    padding: 12px 0 0;
}
.rating-fback p {
    margin: 0;
}
.fback-item {
    position: relative;
    padding: 0 140px 0 0;
    text-align: left;
}
.fback-item > div {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 20px;
}
.form-feedback .form-group {
    text-align: center;
    margin: 20px 0;
}
.form-feedback .form-group .btn {
    padding: 12px 40px;
    font-size: 18px;
    margin: 0 auto;
    font-weight: 600;
}

/* -=-=-=-=-=-=-=-= Contact Us Page End -=-=-=-=-=-=-=-= */









.prd_chosen_sub_row .prd_chosen_sub_col{ display: block; }
    .prd_chosen_sub_row .addcart_row {
        display: block;
        width: 100%;
        padding: 10px;
        margin: 0 auto;
    }


    /* -=-=-=-=-=-=-=-= Contact Us Page Start -=-=-=-=-=-=-=-= */

.contactus_wrapper {
    width: 100%;
}
.contactus_wrapper .row {
    display: block;
    width: 100%;
}
.contactus_wrapper h2 {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
}

.contactus_wrapper .row .ct-right {
    width: 38%; float: right;
}
ul.outlets-list-row-outlet {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.outlets-list-column-outlet {
    width: 100%;
    margin: 0 0 20px; background: #fff;border-radius: 15px;
}
.outlet-item-outlet {
    padding: 25px 25px;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.outlets-list-row-outlet h3 {
font-size: 18px;
    font-weight: 600;
    margin: 0 0 20px 0;
}
.outlet-item-list-outlet ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.outlet-item-list-outlet ul li {
    position: relative;
    padding: 0 0 0 32px;
    margin: 0 0 20px 0;
}
.outlet-item-list-outlet ul li img {
    position: absolute;
    left: 0;
    top: 0px;
}
.icon1 {
    width: 20px;
}
img.icon2 {
    width: 18px;
}
img.icon3 {
    width: 20px;
}
.outlet-item-img-outlet img{
    border-radius: 15px
}
.outlet-item-list-outlet ul li p{ margin: 0 0 5px; }
.outlet-item-list-outlet ul li p img {
    position: inherit;
}
.outlet-item-img-outlet {
    width: 50%;
}

.outlet-item-list-outlet {
    width: 50%;
    padding: 0 0 0 30px;
}



/* -=-=-=-=-=-=-=-= Contact Us Page End -=-=-=-=-=-=-=-= */

.item {
    display: none;
}
.item.active {
    display: block;
}

i.bi.bi-chevron-left {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
}
i.bi.bi-chevron-right {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
}
.hall-description  { text-align: left; }

.cate_note{
    color: #ff0606;
}
.carousel-control.right, .carousel-control.left { background: none; }